import React from 'react'

const ModalDecoLeft = () => (
  <svg width="29" height="37" viewBox="0 0 29 37" id='modal-deco-left'>
    <g fill="none" fillRule="evenodd">
      <path fill="#AB3226" d="M25.096 2L30 15.323 22.423 22 18 14.402z" transform="translate(0 -3) rotate(20 24 12)"/>
      <path fill="#F0A93F" d="M1 32.591L16.404 25 18 36.743 11.222 40z" transform="translate(0 -3)"/>
      <path fill="#4EBBC3" d="M11 23L8.875 11 0 12.576 1.835 20.385z" transform="translate(0 -3)"/>
    </g>
  </svg>
)

export default ModalDecoLeft
