import styled from 'styled-components/macro'
import device from '../../lib/devices'

const ImageWrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '20rem',
  })
  
  const Navigation = styled.button({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '22rem',
    fontWeight: 600,
    color: '#232e83',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    opacity: 1,
    [`@media ${device.mobileL}`]: {
      fontSize: '13rem',
    },
    '& svg': {
      margin: '0 5px',
      width: '12rem',
      height: 'auto',
      opacity: 1,
      [`@media ${device.mobileL}`]: {
        width: '8rem',
      },
    }
  })
  
  const Content = styled.div({
    borderBottom: 'solid 3px #232e83',
  })

  export {
    ImageWrapper,
    Navigation,
    Content,
  }