import styled from 'styled-components/macro'
import device from '../../lib/devices'

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  marginTop: '60rem',
  flexGrow: 1,
  [`@media ${device.mobileL}`]: {
    margin: '25rem 18rem',
  }
})

const InnerContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '450rem',
  width: '100%',
  alignItems: 'center',
  '& > svg': {
    width: '100%',
    maxWidth: '400rem',
    [`@media ${device.mobileL}`]: {
      maxWidth: '350rem',
    }
  }
})

const Image = styled.img(({ lang }) => ({
  width: '100%',
  maxWidth: lang === 'en' ? '400rem' : '395rem',
}))

const Content = styled.div(({ direction }) => ({
  lineHeight: 1.4,
  textAlign: 'center',
  color: '#5a5959',
  minHeight: '110rem',
  fontSize: '16rem',
  direction: direction,
  [`@media ${device.mobileL}`]: {
    marginBottom: '30rem',
  }
}))

const StartBtn = styled.button({
  border: 'none',
  padding: '12rem 60rem',
  fontWeight: 'bold',
  color: 'white',
  boxShadow: '0 2rem 11rem 0 rgba(0, 0, 0, 0.15)',
  background: '#232e83',
  cursor: 'pointer',
  outline: 'none',
  minWidth: '300rem',
  [`@media ${device.mobileL}`]: {
    width: '100%',
    maxWidth: '90%',
    fontSize: '13rem',
  }
})

const BtnWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
})

const YourFamilyCrestPng = styled.img({
  maxWidth: '400rem',
  width: '100%',
  margin: '12rem 0',
})

export {
  Container,
  Image,
  Content,
  StartBtn,
  InnerContainer,
  BtnWrapper,
  YourFamilyCrestPng,
}