import { combineReducers } from 'redux'
import { localizeReducer } from 'react-localize-redux'
import userSelections from './userSelections/reducer'
import ui from './ui/reducer'

const rootReducer = combineReducers({
  userSelections,
  ui,
  localize: localizeReducer,
})


export default rootReducer
