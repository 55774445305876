import React from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import SVGInline from 'react-svg-inline'
import { Container } from './Crest.styles'
import CrestPic from '../../assets/crest'
import CrestHebrewPic from '../../assets/crestHebrew' 
import SvgText from '../SvgText/SvgText'

const Crest = (props) => { 
  const { userSelections, activeLanguage } = props
  const currLang = activeLanguage ? activeLanguage.code : 'en'
  const currLangCrest = currLang === 'en' ? CrestPic : CrestHebrewPic

    return (
      <Container userSelections={userSelections}>
        <SvgText />
        <SVGInline svg={currLangCrest} />
      </Container>
)
}

const mapStateToProps = ({ userSelections }) => ({ userSelections })

export default connect(mapStateToProps)(withLocalize(Crest))
