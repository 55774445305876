import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import ReactGA from 'react-ga'
import { LocalizeProvider } from "react-localize-redux";
import './index.css'
import App from './App'
import store from './initializer'
import * as serviceWorker from './serviceWorker'

ReactGA.initialize('UA-8676522-6')

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
window.addEventListener('resize', () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  console.log('set', document.querySelector('#app-container'))
  document.querySelector('#app-container').style.height = `${vh * 100}px`
})
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizeProvider store={store}>
        <App />
      </LocalizeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
