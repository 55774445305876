import styled from 'styled-components/macro'

const Container = styled.div({
  position: 'relative',
  width: '100%',
  height: '100%',
})

const SelectedSvg = styled.svg({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'none'
})

export {
  Container,
  SelectedSvg,
}