import React from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import {
  Container,
  Content,
} from './Error.styles'
import { Btn } from '../../generalStyles/Btn.styles'
import { setIsError } from '../../store/ui/actions'

function Error(props) {
  const { dispatch } = props

  function handleTryAgainClick() {
    dispatch(setIsError(false))
  }

  function overlayClick(e) {
    const { target, currentTarget } = e
    if (currentTarget === target) {
      dispatch(setIsError(false))
    }
  }

  return (
    <Container onClick={overlayClick}>
      <Content>
        <Translate id="error.content" />
      </Content>
      <Btn onClick={handleTryAgainClick}>
        <Translate id="error.button" />
      </Btn>
    </Container>
  )
}

export default connect()(Error)
