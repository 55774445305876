import styled from 'styled-components/macro'
import device from '../../lib/devices'

const Container = styled.button({
  display: 'flex',
  fontSize: '12rem',
  margin: '20rem 0 40rem 0',
  cursor: 'pointer',
  background: 'transparent',
  border: 'none',
  outline: 'none',
  direction: 'ltr',
  [`@media ${device.mobileL}`]: {
    margin: '20rem 0',
  },
  '& svg': {
    width: '13rem',
    height: 'auto',
  }
})

const StartOverText = styled.div({
  fontWeight: 600,
  color: '#232e83',
  marginInlineStart: '5rem',
  borderBottom: '2px solid #232e83',
})

export {
  Container,
  StartOverText,
}