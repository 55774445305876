import styled from 'styled-components/macro'
import device from '../../lib/devices'

const Container = styled.div(({ isCrestBuilderHidden, direction }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  justifyItems: 'center',
  alignItems: 'center',
  gridAutoFlow: 'dense', // for columns reverse when direction is changes
  direction: direction,
  width: '100%',
  height: '90rem',
  boxShadow: '0 2rem 7rem 0 rgba(0, 0, 0, 0.18), 0 2rem 10rem 0 rgba(0, 0, 0, 0.15)',
  backgroundColor: '#fffefb',
  zIndex: 3,
  [`@media ${device.tablet}`]: {
    justifyContent: 'space-between',
    height: '55rem',
    '& > svg': {
      marginInlineEnd: '10rem',
      width: '100%',
      maxWidth: '220rem',
    }
  },
  [isCrestBuilderHidden && `@media ${device.mobileL}`]: {
    display: 'flex',
  }
}))

const LogoWrapper = styled.div({
  cursor: 'pointer',
  [`@media ${device.tablet}`]: {
    marginInlineStart: '10rem',
    '& svg': {
      width: '100%',
      maxWidth: '100rem',
    }
  }
})

      
const TitleImagesWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const InfoLangsWrapper = styled.div({
  display: 'flex',
})

const Info = styled.div({
  fontSize: '20rem',
  color: '#232e83',
  margin: '0 20rem',
  cursor: 'pointer',
})

export {
  Container,
  LogoWrapper,
  InfoLangsWrapper,
  Info,
  TitleImagesWrapper,
}