import React from 'react'
import { Container, SelectedSvg } from '../styles'

const gazelle = () => (
  <Container>
    <svg width="55" height="108" viewBox="0 0 55 108">
      <g fill="none" fillRule="evenodd">
        <g>
          <path fill="#FEFEFE" d="M5.166 11.024c.484-4.396-1.637-5.957-2.973-8.78C.092-2.195.028 6.372.181 7.52.333 8.67 2.025 13.5 3.38 13.752" transform="translate(.6 .4) translate(13.638 11.84)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M5.166 11.024c.484-4.396-1.637-5.957-2.973-8.78C.092-2.195.028 6.372.181 7.52.333 8.67 2.025 13.5 3.38 13.752" transform="translate(.6 .4) translate(13.638 11.84)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M7.152 5.972c0-1.301-3.005-1.527-2.872-4.38C4.415-1.262-.124 4.199.52 7.426c.694 3.486 4.517 4.542 6.868 3.096" transform="translate(.6 .4) translate(0 62.43)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M7.152 5.972c0-1.301-3.005-1.527-2.872-4.38C4.415-1.262-.124 4.199.52 7.426c.694 3.486 4.517 4.542 6.868 3.096" transform="translate(.6 .4) translate(0 62.43)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.991 1.007C2.626.673 14.034-.906 16.573 2.15c2.77 3.333-.96 11.369-.332 12.222.404.549 2.586 1.528 3.232 1.772 2.5.944 4.23-.79 6.742.563 1.806.974-3.957 8.054-4.864 3.62-.286-1.402-5.379-3.04-6.578-3.855-1.124-.763-.404-9.208-2.652-7.861-1.572.94-5.626 3.837-7.462 3.69" transform="translate(.6 .4) translate(19.532 70.3)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M.991 1.007C2.626.673 14.034-.906 16.573 2.15c2.77 3.333-.96 11.369-.332 12.222.404.549 2.586 1.528 3.232 1.772 2.5.944 4.23-.79 6.742.563 1.806.974-3.957 8.054-4.864 3.62-.286-1.402-5.379-3.04-6.578-3.855-1.124-.763-.404-9.208-2.652-7.861-1.572.94-5.626 3.837-7.462 3.69" transform="translate(.6 .4) translate(19.532 70.3)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.103 14.394c3.042-1.309 8.477 2.442 9.373-.69.563-1.972-.614-7.786-.478-12.363.071-2.393 4.557-.795 6.923-.188 1.602.41 5.513-1.686 5.091.798-.303 1.784-.573 2.165-2.169 3.18-1.597 1.013-6.71-3.406-7.114-1.587-.932 4.19.99 7.994 1.574 10.455 1.309 5.52-4.44 6.606-9.67 5.421" transform="translate(.6 .4) translate(32.021 33.2)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M.103 14.394c3.042-1.309 8.477 2.442 9.373-.69.563-1.972-.614-7.786-.478-12.363.071-2.393 4.557-.795 6.923-.188 1.602.41 5.513-1.686 5.091.798-.303 1.784-.573 2.165-2.169 3.18-1.597 1.013-6.71-3.406-7.114-1.587-.932 4.19.99 7.994 1.574 10.455 1.309 5.52-4.44 6.606-9.67 5.421" transform="translate(.6 .4) translate(32.021 33.2)"/>
        </g>
        <path fill="#FEFEFE" d="M25.702 36.982c2.996 1.216 3.808-1.347 6.025-1.298 1.203.026 2.511-.192 3.324-.365 1.692-.363 2.115-5.675-.303-6.009-1.468-.204-4.213-.87-6.496-1.462-1.668-.431-2.063-3.378-4.548-4.566-1.345-.64-2.824-1.956-7.12-1.194-1.664.297-5.404.211-5.404 8.97 0 3.394 7.286 6.876 6.532 14.803-.14 1.473-1.134 9.111-3.551 12.003-2.287 2.734-4.832 4.46-6.501 7.49-3.38 6.135-3.19 14 3.123 15.921 1.243.38 6.562-1.084 6.831 2.941.144 2.162-1.46 4.984-3.474 5.084-4.353.216 2.685 11.288 3.362 12.463.613 1.066-1.264 3.519-.205 4.169.52.319 2.277.194 4.182.24 1.427.035 2.022-2.642.416-3.326-2.403-1.027-.04-1.186-2.177-2.88-1.779-1.407-3.23-7.616-1.588-8.452 2.33-1.186 8.084-2.868 9.165-5.52.917-2.249 1.295-4.732.605-6.996-1.005-3.296-5.337-3.092-6.005-5.95-.902-3.87 5.606-5.586 8.512-8.355 2.905-2.769 4.69-5.78 5.619-9.644.758-3.151.029-5.083-2.184-7.49-3.018-3.285-8.925-2.857-9.535-8.245-.059-.522-.21-2.983 1.395-2.332" transform="translate(.6 .4)"/>
        <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M25.702 36.982c2.996 1.216 3.808-1.347 6.025-1.298 1.203.026 2.511-.192 3.324-.365 1.692-.363 2.115-5.675-.303-6.009-1.468-.204-4.213-.87-6.496-1.462-1.668-.431-2.063-3.378-4.548-4.566-1.345-.64-2.824-1.956-7.12-1.194-1.664.297-5.404.211-5.404 8.97 0 3.394 7.286 6.876 6.532 14.803-.14 1.473-1.134 9.111-3.551 12.003-2.287 2.734-4.832 4.46-6.501 7.49-3.38 6.135-3.19 14 3.123 15.921 1.243.38 6.562-1.084 6.831 2.941.144 2.162-1.46 4.984-3.474 5.084-4.353.216 2.685 11.288 3.362 12.463.613 1.066-1.264 3.519-.205 4.169.52.319 2.277.194 4.182.24 1.427.035 2.022-2.642.416-3.326-2.403-1.027-.04-1.186-2.177-2.88-1.779-1.407-3.23-7.616-1.588-8.452 2.33-1.186 8.084-2.868 9.165-5.52.917-2.249 1.295-4.732.605-6.996-1.005-3.296-5.337-3.092-6.005-5.95-.902-3.87 5.606-5.586 8.512-8.355 2.905-2.769 4.69-5.78 5.619-9.644.758-3.151.029-5.083-2.184-7.49-3.018-3.285-8.925-2.857-9.535-8.245-.059-.522-.21-2.983 1.395-2.332z" transform="translate(.6 .4)"/>
        <g>
          <path fill="#FEFEFE" d="M8.312.08c3.844 1.193 1.426 5.154 2.792 7.949 1.022 2.092 1.704 3.069 4.057 2.792 2.277-.267 6.265-1.839 8.191-2.895 1.172-.641 2.522 2.305 4.674 3.334 1.573.75 2.558.326 3.21 1.38.686 1.11-1.175 2.776-3.587 2.707-1.728-.048-3.54-5.249-4.637-4.94-6.628 1.874-4.915 4.204-8.582 4.8-5.323.864-4.851-.932-6.749-3.356C6.156 9.903 4.233 4.926.455 7.815" transform="translate(.6 .4) translate(21.83 48.815)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M8.312.08c3.844 1.193 1.426 5.154 2.792 7.949 1.022 2.092 1.704 3.069 4.057 2.792 2.277-.267 6.265-1.839 8.191-2.895 1.172-.641 2.522 2.305 4.674 3.334 1.573.75 2.558.326 3.21 1.38.686 1.11-1.175 2.776-3.587 2.707-1.728-.048-3.54-5.249-4.637-4.94-6.628 1.874-4.915 4.204-8.582 4.8-5.323.864-4.851-.932-6.749-3.356C6.156 9.903 4.233 4.926.455 7.815" transform="translate(.6 .4) translate(21.83 48.815)"/>
        </g>
        <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M24.199 30.434c0 .54-.447.978-1 .978-.551 0-1-.439-1-.978 0-.54.449-.978 1-.978.553 0 1 .438 1 .978z" transform="translate(.6 .4)"/>
        <g>
          <path fill="#FEFEFE" d="M.67.697s-.753 2.5 0 3.89" transform="translate(.6 .4) translate(40.213 86.039)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M.67.697s-.753 2.5 0 3.89" transform="translate(.6 .4) translate(40.213 86.039)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M3.47.202s-1.92-.684-3.09.99" transform="translate(.6 .4) translate(17.234 101.653)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M3.47.202s-1.92-.684-3.09.99" transform="translate(.6 .4) translate(17.234 101.653)"/>
        </g>
        <path fill="#46C2D1" d="M5.29 5.66s.179-1.119.44-.587c.979 1.997 3.386 9.745 5.272 9.665 9.366-.392 6.944 3.868 10.237 9.904.539.99-1.425.023-1.87-.347-2.343-1.957.751-9.124-8.538-7.947C7.458 16.776 5.29 5.66 5.29 5.66" transform="translate(.6 .4)"/>
        <path stroke="#46C2D1" strokeWidth="1.129" d="M5.29 5.66s.179-1.119.44-.587c.979 1.997 3.386 9.745 5.272 9.665 9.366-.392 6.944 3.868 10.237 9.904.539.99-1.425.023-1.87-.347-2.343-1.957.751-9.124-8.538-7.947C7.458 16.776 5.29 5.66 5.29 5.66z" transform="translate(.6 .4)"/>
        <path fill="#46C2D1" d="M12.894 1.196s.464-1.037.578-.458c.427 2.174.738 10.266 2.582 10.66 9.159 1.956 5.708 5.472 7.32 12.13.264 1.09-1.384-.334-1.717-.803-1.757-2.476 3.101-8.636-6.188-9.813-3.373-.427-2.575-11.716-2.575-11.716" transform="translate(.6 .4)"/>
        <path stroke="#46C2D1" strokeWidth="1.129" d="M12.894 1.196s.464-1.037.578-.458c.427 2.174.738 10.266 2.582 10.66 9.159 1.956 5.708 5.472 7.32 12.13.264 1.09-1.384-.334-1.717-.803-1.757-2.476 3.101-8.636-6.188-9.813-3.373-.427-2.575-11.716-2.575-11.716z" transform="translate(.6 .4)"/>
        <g>
          <path fill="#FEFEFE" d="M9.897 8.635c.201-5.828-5.41-5.087-7.631-7.598C-1.227-2.91.79 6.315 1.034 7.53c.453 2.267 4.451 5.102 5.917 4.968" transform="translate(.6 .4) translate(6.894 17.338)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M9.897 8.635c.201-5.828-5.41-5.087-7.631-7.598C-1.227-2.91.79 6.315 1.034 7.53c.453 2.267 4.451 5.102 5.917 4.968" transform="translate(.6 .4) translate(6.894 17.338)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.002.791S2.46 6.724 4.935 7.157" transform="translate(.6 .4) translate(9.191 20.834)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M.002.791S2.46 6.724 4.935 7.157" transform="translate(.6 .4) translate(9.191 20.834)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M3.783 1.856S2.642.788 1.5 1.576C.359 2.365.252.106.252.106" transform="translate(.6 .4) translate(32.17 30.952)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M3.783 1.856S2.642.788 1.5 1.576C.359 2.365.252.106.252.106" transform="translate(.6 .4) translate(32.17 30.952)"/>
        </g>
        <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M17.01 24.808s2.18 1.66 5.275 1.76c3.097.098 2.578 1.354 3.34 2.301 1.483 1.845 4.39-.133 10.402 1.419" transform="translate(.6 .4)"/>
        <g>
          <path fill="#FEFEFE" d="M6.534 3.735S.734 2.926.493.168" transform="translate(.6 .4) translate(18.383 33.2)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M6.534 3.735S.734 2.926.493.168" transform="translate(.6 .4) translate(18.383 33.2)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.694.052S1.62 2.494.15 3.642" transform="translate(.6 .4) translate(48.255 34.325)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M.694.052S1.62 2.494.15 3.642" transform="translate(.6 .4) translate(48.255 34.325)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M3.19.08S2.555 2.111.487 1.692" transform="translate(.6 .4) translate(47.106 60.182)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M3.19.08S2.555 2.111.487 1.692" transform="translate(.6 .4) translate(47.106 60.182)"/>
        </g>
      </g>
    </svg>
    <SelectedSvg id="selected" width="56" height="108" viewBox="0 0 56 108">
      <g fill="none" fillRule="evenodd">
        <g>
          <path fill="#FEFEFE" d="M5.166 11.024c.484-4.397-1.637-5.957-2.973-8.78C.09-2.194.028 6.372.18 7.521c.152 1.149 1.844 5.98 3.199 6.232" transform="translate(1 .6) translate(13.638 11.84)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M5.166 11.024c.484-4.397-1.637-5.957-2.973-8.78C.09-2.194.028 6.372.18 7.521c.152 1.149 1.844 5.98 3.199 6.232" transform="translate(1 .6) translate(13.638 11.84)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M7.151 5.972c0-1.301-3.004-1.527-2.87-4.38C4.414-1.261-.125 4.199.52 7.426c.693 3.485 4.516 4.542 6.867 3.096" transform="translate(1 .6) translate(0 62.43)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M7.151 5.972c0-1.301-3.004-1.527-2.87-4.38C4.414-1.261-.125 4.199.52 7.426c.693 3.485 4.516 4.542 6.867 3.096" transform="translate(1 .6) translate(0 62.43)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.991 1.007C2.626.673 14.034-.906 16.573 2.149c2.77 3.334-.96 11.369-.332 12.222.404.55 2.586 1.53 3.232 1.772 2.5.945 4.23-.788 6.742.564 1.807.974-3.957 8.054-4.864 3.62-.286-1.402-5.379-3.04-6.578-3.855-1.124-.763-.403-9.208-2.652-7.861-1.572.94-5.627 3.836-7.463 3.688" transform="translate(1 .6) translate(19.532 70.3)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M.991 1.007C2.626.673 14.034-.906 16.573 2.149c2.77 3.334-.96 11.369-.332 12.222.404.55 2.586 1.53 3.232 1.772 2.5.945 4.23-.788 6.742.564 1.807.974-3.957 8.054-4.864 3.62-.286-1.402-5.379-3.04-6.578-3.855-1.124-.763-.403-9.208-2.652-7.861-1.572.94-5.627 3.836-7.463 3.688" transform="translate(1 .6) translate(19.532 70.3)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.103 14.394c3.042-1.309 8.477 2.442 9.373-.69.563-1.972-.614-7.785-.478-12.363.071-2.394 4.556-.795 6.923-.188 1.602.41 5.513-1.686 5.091.798-.303 1.784-.573 2.165-2.169 3.18-1.597 1.012-6.71-3.406-7.114-1.587-.932 4.189.99 7.994 1.574 10.455 1.309 5.52-4.44 6.606-9.67 5.421" transform="translate(1 .6) translate(32.021 33.2)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M.103 14.394c3.042-1.309 8.477 2.442 9.373-.69.563-1.972-.614-7.785-.478-12.363.071-2.394 4.556-.795 6.923-.188 1.602.41 5.513-1.686 5.091.798-.303 1.784-.573 2.165-2.169 3.18-1.597 1.012-6.71-3.406-7.114-1.587-.932 4.189.99 7.994 1.574 10.455 1.309 5.52-4.44 6.606-9.67 5.421" transform="translate(1 .6) translate(32.021 33.2)"/>
        </g>
        <path fill="#FEFEFE" d="M25.702 36.981c2.997 1.217 3.809-1.347 6.025-1.297 1.203.027 2.512-.191 3.323-.366 1.693-.362 2.116-5.675-.301-6.008-1.47-.204-4.213-.87-6.497-1.462-1.669-.432-2.064-3.378-4.549-4.565-1.343-.642-2.823-1.957-7.118-1.194-1.664.295-5.405.211-5.405 8.969 0 3.394 7.286 6.876 6.532 14.802-.14 1.474-1.134 9.112-3.55 12.003-2.288 2.734-4.833 4.461-6.502 7.49-3.38 6.136-3.189 14.002 3.123 15.923 1.242.378 6.563-1.086 6.83 2.941.145 2.162-1.458 4.983-3.473 5.083-4.353.215 2.685 11.289 3.362 12.463.614 1.065-1.265 3.52-.204 4.17.519.317 2.277.193 4.182.24 1.426.034 2.02-2.642.416-3.328-2.405-1.026-.04-1.186-2.179-2.879-1.777-1.407-3.23-7.616-1.587-8.453 2.33-1.186 8.085-2.867 9.166-5.519.917-2.25 1.293-4.731.604-6.997-1.004-3.295-5.338-3.091-6.004-5.949-.902-3.87 5.605-5.585 8.511-8.354 2.906-2.77 4.691-5.782 5.62-9.645.757-3.15.027-5.082-2.186-7.49-3.018-3.285-8.925-2.858-9.534-8.245-.06-.523-.209-2.984 1.395-2.333" transform="translate(1 .6)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M25.702 36.981c2.997 1.217 3.809-1.347 6.025-1.297 1.203.027 2.512-.191 3.323-.366 1.693-.362 2.116-5.675-.301-6.008-1.47-.204-4.213-.87-6.497-1.462-1.669-.432-2.064-3.378-4.549-4.565-1.343-.642-2.823-1.957-7.118-1.194-1.664.295-5.405.211-5.405 8.969 0 3.394 7.286 6.876 6.532 14.802-.14 1.474-1.134 9.112-3.55 12.003-2.288 2.734-4.833 4.461-6.502 7.49-3.38 6.136-3.189 14.002 3.123 15.923 1.242.378 6.563-1.086 6.83 2.941.145 2.162-1.458 4.983-3.473 5.083-4.353.215 2.685 11.289 3.362 12.463.614 1.065-1.265 3.52-.204 4.17.519.317 2.277.193 4.182.24 1.426.034 2.02-2.642.416-3.328-2.405-1.026-.04-1.186-2.179-2.879-1.777-1.407-3.23-7.616-1.587-8.453 2.33-1.186 8.085-2.867 9.166-5.519.917-2.25 1.293-4.731.604-6.997-1.004-3.295-5.338-3.091-6.004-5.949-.902-3.87 5.605-5.585 8.511-8.354 2.906-2.77 4.691-5.782 5.62-9.645.757-3.15.027-5.082-2.186-7.49-3.018-3.285-8.925-2.858-9.534-8.245-.06-.523-.209-2.984 1.395-2.333z" transform="translate(1 .6)"/>
        <g>
          <path fill="#FEFEFE" d="M8.312.08c3.844 1.194 1.425 5.154 2.792 7.949 1.022 2.092 1.703 3.07 4.056 2.793 2.278-.268 6.266-1.84 8.192-2.895 1.172-.642 2.522 2.304 4.674 3.333 1.573.75 2.558.326 3.21 1.38.687 1.11-1.175 2.776-3.585 2.708-1.728-.049-3.541-5.25-4.638-4.94-6.629 1.873-4.915 4.202-8.583 4.8-5.323.864-4.85-.933-6.75-3.357C6.157 9.903 4.234 4.926.456 7.815" transform="translate(1 .6) translate(21.83 48.815)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M8.312.08c3.844 1.194 1.425 5.154 2.792 7.949 1.022 2.092 1.703 3.07 4.056 2.793 2.278-.268 6.266-1.84 8.192-2.895 1.172-.642 2.522 2.304 4.674 3.333 1.573.75 2.558.326 3.21 1.38.687 1.11-1.175 2.776-3.585 2.708-1.728-.049-3.541-5.25-4.638-4.94-6.629 1.873-4.915 4.202-8.583 4.8-5.323.864-4.85-.933-6.75-3.357C6.157 9.903 4.234 4.926.456 7.815" transform="translate(1 .6) translate(21.83 48.815)"/>
        </g>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M24.199 30.434c0 .54-.447.978-1 .978-.551 0-.998-.439-.998-.978 0-.54.447-.979.998-.979.553 0 1 .439 1 .979z" transform="translate(1 .6)"/>
        <g>
          <path fill="#FEFEFE" d="M.67.697s-.754 2.5 0 3.89" transform="translate(1 .6) translate(40.213 86.039)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M.67.697s-.754 2.5 0 3.89" transform="translate(1 .6) translate(40.213 86.039)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M3.47.202s-1.92-.684-3.09.99" transform="translate(1 .6) translate(17.234 101.653)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M3.47.202s-1.92-.684-3.09.99" transform="translate(1 .6) translate(17.234 101.653)"/>
        </g>
        <path fill="#414141" d="M5.29 5.66s.179-1.118.44-.586c.98 1.995 3.385 9.744 5.272 9.665 9.367-.394 6.944 3.868 10.237 9.904.539.988-1.425.023-1.87-.348-2.343-1.958.75-9.124-8.538-7.946-3.373.427-5.542-10.69-5.542-10.69" transform="translate(1 .6)"/>
        <path stroke="#414141" strokeWidth="1.129" d="M5.29 5.66s.179-1.118.44-.586c.98 1.995 3.385 9.744 5.272 9.665 9.367-.394 6.944 3.868 10.237 9.904.539.988-1.425.023-1.87-.348-2.343-1.958.75-9.124-8.538-7.946-3.373.427-5.542-10.69-5.542-10.69z" transform="translate(1 .6)"/>
        <path fill="#414141" d="M12.894 1.195s.464-1.036.578-.457c.427 2.175.737 10.267 2.582 10.66 9.159 1.956 5.707 5.472 7.32 12.13.264 1.09-1.384-.333-1.717-.802-1.757-2.476 3.1-8.636-6.188-9.814-3.372-.427-2.575-11.717-2.575-11.717" transform="translate(1 .6)"/>
        <path stroke="#414141" strokeWidth="1.129" d="M12.894 1.195s.464-1.036.578-.457c.427 2.175.737 10.267 2.582 10.66 9.159 1.956 5.707 5.472 7.32 12.13.264 1.09-1.384-.333-1.717-.802-1.757-2.476 3.1-8.636-6.188-9.814-3.372-.427-2.575-11.717-2.575-11.717z" transform="translate(1 .6)"/>
        <g>
          <path fill="#FEFEFE" d="M9.897 8.635c.201-5.83-5.41-5.088-7.631-7.598C-1.227-2.91.79 6.315 1.034 7.53c.453 2.266 4.451 5.101 5.917 4.967" transform="translate(1 .6) translate(6.894 17.338)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M9.897 8.635c.201-5.83-5.41-5.088-7.631-7.598C-1.227-2.91.79 6.315 1.034 7.53c.453 2.266 4.451 5.101 5.917 4.967" transform="translate(1 .6) translate(6.894 17.338)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.003.79s2.456 5.934 4.932 6.367" transform="translate(1 .6) translate(9.191 20.834)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M.003.79s2.456 5.934 4.932 6.367" transform="translate(1 .6) translate(9.191 20.834)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M3.783 1.855S2.642.787 1.5 1.576C.359 2.365.252.105.252.105" transform="translate(1 .6) translate(32.17 30.952)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M3.783 1.855S2.642.787 1.5 1.576C.359 2.365.252.105.252.105" transform="translate(1 .6) translate(32.17 30.952)"/>
        </g>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M17.01 24.809s2.179 1.66 5.275 1.759c3.097.098 2.577 1.355 3.34 2.301 1.482 1.844 4.39-.134 10.402 1.419" transform="translate(1 .6)"/>
        <g>
          <path fill="#FEFEFE" d="M6.534 3.735S.734 2.927.493.167" transform="translate(1 .6) translate(18.383 33.2)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M6.534 3.735S.734 2.927.493.167" transform="translate(1 .6) translate(18.383 33.2)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.694.052S1.62 2.494.15 3.642" transform="translate(1 .6) translate(48.255 34.325)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M.694.052S1.62 2.494.15 3.642" transform="translate(1 .6) translate(48.255 34.325)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M3.19.08S2.555 2.11.487 1.692" transform="translate(1 .6) translate(47.106 60.182)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.694" d="M3.19.08S2.555 2.11.487 1.692" transform="translate(1 .6) translate(47.106 60.182)"/>
        </g>
      </g>
    </SelectedSvg>
  </Container>
)

export default gazelle
