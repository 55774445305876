import styled from 'styled-components/macro'

const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'fixed',
  zIndex: 2,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  background: 'rgba(255,255,252, 0.9)',
})

const Content = styled.div({
    color: '#5a5959',
    lineHeight: 1.4,
    fontSize: '16rem',
    margin: '20rem 0',
    fontWeight: 'bold',
})

export {
  Container,
  Content,
}