import React from 'react'
import { Container, SelectedSvg } from '../styles'

const freeProfession = () => (
  <Container>
    <svg width="82" height="64" viewBox="0 0 82 64">
      <path fill="#FBB034" fillRule="evenodd" stroke="#FBB034" strokeWidth="2.304" d="M29.228 1.4c-2.536 0-4.605 2.083-4.605 4.636v4.637H6.205c-2.536 0-4.605 2.082-4.605 4.636v42.655c0 2.553 2.069 4.636 4.605 4.636h69.99c2.536 0 4.605-2.083 4.605-4.636V15.309c0-2.554-2.069-4.636-4.605-4.636H57.777V6.036c0-2.553-2.069-4.636-4.605-4.636H29.228zm0 1.855h23.944c1.547 0 2.763 1.224 2.763 2.781v4.637h-29.47V6.036c0-1.557 1.216-2.781 2.763-2.781zM6.205 12.527h69.99c1.547 0 2.763 1.225 2.763 2.782v6.52C70.335 28.512 56.644 32.927 41.2 32.927c-15.444 0-29.135-4.415-37.758-11.098v-6.52c0-1.557 1.216-2.782 2.763-2.782zm-2.763 11.62c7.569 5.451 18.264 9.19 30.39 10.287v4.057c0 1.525 1.249 2.782 2.763 2.782h9.21c1.514 0 2.762-1.257 2.762-2.782v-4.057C60.694 33.337 71.39 29.6 78.958 24.147v33.817c0 1.557-1.216 2.781-2.763 2.781H6.205c-1.547 0-2.763-1.224-2.763-2.781V24.147zm32.232 10.46c1.817.124 3.655.174 5.526.174 1.87 0 3.709-.05 5.526-.174v3.883c0 .53-.396.928-.921.928h-9.21c-.525 0-.92-.399-.92-.928v-3.883z"/>
    </svg>
    <SelectedSvg id="selected" width="82" height="64" viewBox="0 0 82 64">
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M0 10.8H78V58.8H0z" transform="translate(1.6 1.4)"/>
        <path fill="#414141" stroke="#414141" strokeidth="2.304" d="M27.628 0c-2.536 0-4.605 2.083-4.605 4.636v4.637H4.605C2.069 9.273 0 11.355 0 13.909v42.655C0 59.117 2.069 61.2 4.605 61.2h69.99c2.536 0 4.605-2.083 4.605-4.636V13.909c0-2.554-2.069-4.636-4.605-4.636H56.177V4.636C56.177 2.083 54.108 0 51.572 0H27.628zm0 1.855h23.944c1.547 0 2.763 1.224 2.763 2.781v4.637h-29.47V4.636c0-1.557 1.216-2.781 2.763-2.781zM4.605 11.127h69.99c1.547 0 2.763 1.225 2.763 2.782v6.52C68.735 27.112 55.044 31.527 39.6 31.527c-15.444 0-29.135-4.415-37.758-11.098v-6.52c0-1.557 1.216-2.782 2.763-2.782zm-2.763 11.62c7.569 5.451 18.264 9.19 30.39 10.287v4.057c0 1.525 1.249 2.782 2.763 2.782h9.21c1.514 0 2.762-1.257 2.762-2.782v-4.057C59.094 31.937 69.79 28.2 77.358 22.747v33.817c0 1.557-1.216 2.781-2.763 2.781H4.605c-1.547 0-2.763-1.224-2.763-2.781V22.747zm32.232 10.46c1.817.124 3.655.174 5.526.174 1.87 0 3.709-.05 5.526-.174v3.883c0 .53-.396.928-.921.928h-9.21c-.525 0-.92-.399-.92-.928v-3.883z" transform="translate(1.6 1.4)"/>
      </g>
    </SelectedSvg>
  </Container>
)

export default freeProfession
