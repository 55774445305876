import React, { useEffect, useRef } from 'react'
import ReactGA from 'react-ga'
import {
  Container,
  InnerContainer,
  Title,
  Description,
  Instruction,
  Component,
} from './Step.styles'

function Step(props) {
  const { title = '', description = '', instruction = '', component } = props
  const container = useRef(null)
  const innerContainer = useRef(null)

  useEffect(() => {
    container.current.scrollTop = 0
    innerContainer.current.scrollTop = 0
    const titleElem = document.querySelector('#step-title')
    ReactGA.pageview(`/${titleElem.innerText.toLowerCase().split(' ').join('-')}`)
  }, [title, description, instruction, component])

  return (
    <Container ref={container}>
      <InnerContainer ref={innerContainer}>
        <Title id='step-title'>{title}</Title>
        <Description>{description}</Description>
        <Instruction>{instruction}</Instruction>
        <Component>{component}</Component>
      </InnerContainer>
    </Container>
  )
}

export default Step
