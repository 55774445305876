import React from 'react'
import { Container, SelectedSvg } from '../styles'

const lamb = () => (
  <Container>
    <svg width="55" height="90" viewBox="0 0 55 90">
      <g fill="none" fillRule="evenodd">
        <g>
          <path fill="#FEFEFE" d="M.543 15.528c2.65-1.18 4.22.155 5-2.666.492-1.778.055-7.653.614-9.41.654-2.054 2.238-2.855 4.3-2.309 1.395.37 5.828-1.144 6.12 1.106.216 1.663-.693 5.306-2.083 6.22-1.39.913-5.112-4.968-5.112-3.29 0 1.893.383 10.753.19 12.39-.439 3.708-4.284 2.591-7.137 3.099" transform="translate(.2) translate(33.75 14.207)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M.543 15.528c2.65-1.18 4.22.155 5-2.666.492-1.778.055-7.653.614-9.41.654-2.054 2.238-2.855 4.3-2.309 1.395.37 5.828-1.144 6.12 1.106.216 1.663-.693 5.306-2.083 6.22-1.39.913-5.112-4.968-5.112-3.29 0 1.893.383 10.753.19 12.39-.439 3.708-4.284 2.591-7.137 3.099" transform="translate(.2) translate(33.75 14.207)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.745.014S2.878 2.67.008 5.234" transform="translate(.2) translate(45 15.346)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M.745.014S2.878 2.67.008 5.234" transform="translate(.2) translate(45 15.346)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M6.838 4.737c0 1.159-2.434 4.533-3.806 3.898C.748 7.576.575 6.316 1.128 3.441 1.725.338 5.017-.602 7.04.684" transform="translate(.2) translate(0 48.361)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M6.838 4.737c0 1.159-2.434 4.533-3.806 3.898C.748 7.576.575 6.316 1.128 3.441 1.725.338 5.017-.602 7.04.684" transform="translate(.2) translate(0 48.361)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.2.277c1.601-.338 9.18-.683 11.667 2.411 2.712 3.376-.94 11.514-.326 12.378.396.556 2.533 1.547 3.165 1.794 2.448.957 4.142-.798 6.602.571 1.768.986-5.148 9.754-6.036 5.262-.28-1.42-5.177-2.28-6.352-3.105-1.101-.773-.395-9.324-2.597-7.96-1.538.952-4.325 1.489-6.123 1.34" transform="translate(.2) translate(24.75 51.638)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M.2.277c1.601-.338 9.18-.683 11.667 2.411 2.712 3.376-.94 11.514-.326 12.378.396.556 2.533 1.547 3.165 1.794 2.448.957 4.142-.798 6.602.571 1.768.986-5.148 9.754-6.036 5.262-.28-1.42-5.177-2.28-6.352-3.105-1.101-.773-.395-9.324-2.597-7.96-1.538.952-4.325 1.489-6.123 1.34" transform="translate(.2) translate(24.75 51.638)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M1.92.08S-.552 3.565.186 4.972" transform="translate(.2) translate(39.375 68.715)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M1.92.08S-.552 3.565.186 4.972" transform="translate(.2) translate(39.375 68.715)"/>
        </g>
        <path fill="#FEFEFE" d="M33.761 32.503c-.215-1.298 0-7.473-4.899-7.02-2.098-3.095.32-7.412 1.341-7.218 1.651.313 3.22.897 3.993.937 1.491.079.007-1.358 2.111-1.823 1.658-.367 1.214-4.86-.065-5.556-1.28-.696-9.335-6.083-11.769-7.285-1.315-.65-2.764-1.982-6.969-1.21-1.631.3-5.596.09-5.99 8.95-.065 1.428 6.66 3.675 4.878 11.496-4.476-.735-6.959 8.231-6.585 9.014-1.591 1.067-2.767 2.761-2.767 4.838 0 1.23.238 2.822.869 3.77-.631.95-1.39 2.476-1.39 3.705 0 1.222.48 2.755 1.109 3.697-.323.957-2.248 5.124-2.366 6.617-.306 3.842 3.234 10.246 8.975 9.897.307-.018.609-.03.903-.076.167.453.322.976.54 1.588.567 1.612.887 4.699-.762 6.122-3.25 2.801 2.821 9.877 3.484 11.067.6 1.079-1.239 3.563-.202 4.221.51.323 5.684.21 7.55.258 1.396.035 1.742-4.085.169-4.78-2.354-1.038-3.255.196-5.348-1.519-1.74-1.425-1.344-7.192.265-8.04 2.282-1.2 5.454-2.387 6.513-5.072.898-2.278.594-4.97-.082-7.264-.546-1.853-1.886-2.18-2.671-3.07.368-.776.593-1.636.593-2.556 0-.832-.184-1.618-.49-2.337.399-1.061 1.667-3.977 2.264-4.74 4.9 2.442 7.684-4.9 7.684-8.194 0-2.013 2.737-5.476-.886-8.417" transform="translate(.2)"/>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M33.761 32.503c-.215-1.298 0-7.473-4.899-7.02-2.098-3.095.32-7.412 1.341-7.218 1.651.313 3.22.897 3.993.937 1.491.079.007-1.358 2.111-1.823 1.658-.367 1.214-4.86-.065-5.556-1.28-.696-9.335-6.083-11.769-7.285-1.315-.65-2.764-1.982-6.969-1.21-1.631.3-5.596.09-5.99 8.95-.065 1.428 6.66 3.675 4.878 11.496-4.476-.735-6.959 8.231-6.585 9.014-1.591 1.067-2.767 2.761-2.767 4.838 0 1.23.238 2.822.869 3.77-.631.95-1.39 2.476-1.39 3.705 0 1.222.48 2.755 1.109 3.697-.323.957-2.248 5.124-2.366 6.617-.306 3.842 3.234 10.246 8.975 9.897.307-.018.609-.03.903-.076.167.453.322.976.54 1.588.567 1.612.887 4.699-.762 6.122-3.25 2.801 2.821 9.877 3.484 11.067.6 1.079-1.239 3.563-.202 4.221.51.323 5.684.21 7.55.258 1.396.035 1.742-4.085.169-4.78-2.354-1.038-3.255.196-5.348-1.519-1.74-1.425-1.344-7.192.265-8.04 2.282-1.2 5.454-2.387 6.513-5.072.898-2.278.594-4.97-.082-7.264-.546-1.853-1.886-2.18-2.671-3.07.368-.776.593-1.636.593-2.556 0-.832-.184-1.618-.49-2.337.399-1.061 1.667-3.977 2.264-4.74 4.9 2.442 7.684-4.9 7.684-8.194 0-2.013 2.737-5.476-.886-8.417z" transform="translate(.2)"/>
        <g>
          <path fill="#FEFEFE" d="M9.403.328c3.764 1.21 1.396 5.22 2.733 8.05 1.002 2.12 1.669 3.108 3.973 2.828 2.23-.27 6.134-1.862 8.02-2.931 1.148-.65 2.174 1.236 4.282 2.278 1.54.76 4.293.433 4.93 1.5.673 1.125-3.43 6.201-5.792 6.133-1.692-.05-2.445-5.35-3.55-5.183-6.608.998-4.21 3.619-8.087 4.79-5.063 1.531-6.337-.383-8.196-2.838C6.223 12.982 4.62 7.631.921 10.558" transform="translate(.2) translate(20.25 30.146)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M9.403.328c3.764 1.21 1.396 5.22 2.733 8.05 1.002 2.12 1.669 3.108 3.973 2.828 2.23-.27 6.134-1.862 8.02-2.931 1.148-.65 2.174 1.236 4.282 2.278 1.54.76 4.293.433 4.93 1.5.673 1.125-3.43 6.201-5.792 6.133-1.692-.05-2.445-5.35-3.55-5.183-6.608.998-4.21 3.619-8.087 4.79-5.063 1.531-6.337-.383-8.196-2.838C6.223 12.982 4.62 7.631.921 10.558" transform="translate(.2) translate(20.25 30.146)"/>
        </g>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M26.43 10.199c0 .546-.437.99-.979.99-.54 0-.978-.444-.978-.99s.438-.99.978-.99c.542 0 .98.444.98.99z" transform="translate(.2)"/>
        <g>
          <path fill="#FEFEFE" d="M2.022.272S.42.003.263.716c-.2.916 2.031 1.05 1.637 2.07" transform="translate(.2) translate(32.625 13.069)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M2.022.272S.42.003.263.716c-.2.916 2.031 1.05 1.637 2.07" transform="translate(.2) translate(32.625 13.069)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M4.233.527S3.54 4.31.576 4.71" transform="translate(.2) translate(45 40.392)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M4.233.527S3.54 4.31.576 4.71" transform="translate(.2) translate(45 40.392)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M4.296.588S1.656.898.512 2.594" transform="translate(.2) translate(18 82.515)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M4.296.588S1.656.898.512 2.594" transform="translate(.2) translate(18 82.515)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.352.698c1.085 3.393 7.793-1.121 9.8.14" transform="translate(.2) translate(21.375 17.623)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M.352.698c1.085 3.393 7.793-1.121 9.8.14" transform="translate(.2) translate(21.375 17.623)"/>
        </g>
        <path fill="#FEFEFE" d="M26.128 2.964s.723 4.174-1.808 3.89c-2.532-.285-.278 1.581-3.187.688-2.673-.82-4.928.438-6.555-.31-2.529-1.16-2.534-3.89-.615-4.268 1.92-.38 1.356-1.898 4.263-2.087 2.907-.19 4.047.854 4.273 1.327.227.475 2.81-1.233 3.629.76" transform="translate(.2)"/>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M26.128 2.964s.723 4.174-1.808 3.89c-2.532-.285-.278 1.581-3.187.688-2.673-.82-4.928.438-6.555-.31-2.529-1.16-2.534-3.89-.615-4.268 1.92-.38 1.356-1.898 4.263-2.087 2.907-.19 4.047.854 4.273 1.327.227.475 2.81-1.233 3.629.76z" transform="translate(.2)"/>
        <g>
          <path fill="#FEFEFE" d="M9.956 1.372c-2.69-3.152-6.153.407-8.44 1.132-3.597 1.139 3.162 3.568 3.892 3.741.729.173 4.338-.356 5.177-1.897" transform="translate(.2) translate(7.875 6.1)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M9.956 1.372c-2.69-3.152-6.153.407-8.44 1.132-3.597 1.139 3.162 3.568 3.892 3.741.729.173 4.338-.356 5.177-1.897" transform="translate(.2) translate(7.875 6.1)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.41 1.223s2.434.754 4.263-.53" transform="translate(.2) translate(12.375 8.515)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M.41 1.223s2.434.754 4.263-.53" transform="translate(.2) translate(12.375 8.515)"/>
        </g>
      </g>
    </svg>
    <SelectedSvg id="selected" width="56" height="89" viewBox="0 0 56 89">
      <g fill="none" fillRule="evenodd">
        <g>
          <path fill="#FEFEFE" d="M.542 15.318c2.65-1.163 4.221.153 5.002-2.63.49-1.753.055-7.55.614-9.283.654-2.025 2.237-2.817 4.299-2.278 1.395.365 5.827-1.128 6.12 1.091.216 1.64-.693 5.236-2.084 6.136-1.39.901-5.112-4.9-5.112-3.245 0 1.869.384 10.608.19 12.223-.438 3.658-4.284 2.555-7.135 3.057" transform="translate(.8 .4) translate(33.75 14.015)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.688" d="M.542 15.318c2.65-1.163 4.221.153 5.002-2.63.49-1.753.055-7.55.614-9.283.654-2.025 2.237-2.817 4.299-2.278 1.395.365 5.827-1.128 6.12 1.091.216 1.64-.693 5.236-2.084 6.136-1.39.901-5.112-4.9-5.112-3.245 0 1.869.384 10.608.19 12.223-.438 3.658-4.284 2.555-7.135 3.057" transform="translate(.8 .4) translate(33.75 14.015)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.745.014s2.134 2.619-.737 5.15" transform="translate(.8 .4) translate(45 15.138)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.688" d="M.745.014s2.134 2.619-.737 5.15" transform="translate(.8 .4) translate(45 15.138)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M6.839 4.673c0 1.142-2.435 4.472-3.806 3.844C.748 7.474.576 6.23 1.128 3.395 1.725.333 5.018-.595 7.041.675" transform="translate(.8 .4) translate(0 47.708)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.688" d="M6.839 4.673c0 1.142-2.435 4.472-3.806 3.844C.748 7.474.576 6.23 1.128 3.395 1.725.333 5.018-.595 7.041.675" transform="translate(.8 .4) translate(0 47.708)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.2.26c1.601-.334 9.18-.674 11.667 2.379 2.712 3.33-.94 11.357-.325 12.21.396.549 2.532 1.526 3.164 1.77 2.448.944 4.143-.788 6.602.563 1.768.972-5.148 9.622-6.036 5.191-.28-1.4-5.177-2.25-6.352-3.064-1.1-.76-.394-9.198-2.597-7.852-1.538.939-4.325 1.469-6.122 1.322" transform="translate(.8 .4) translate(24.75 50.954)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.688" d="M.2.26c1.601-.334 9.18-.674 11.667 2.379 2.712 3.33-.94 11.357-.325 12.21.396.549 2.532 1.526 3.164 1.77 2.448.944 4.143-.788 6.602.563 1.768.972-5.148 9.622-6.036 5.191-.28-1.4-5.177-2.25-6.352-3.064-1.1-.76-.394-9.198-2.597-7.852-1.538.939-4.325 1.469-6.122 1.322" transform="translate(.8 .4) translate(24.75 50.954)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M1.92.065S-.552 3.503.186 4.892" transform="translate(.8 .4) translate(39.375 67.8)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.688" d="M1.92.065S-.552 3.503.186 4.892" transform="translate(.8 .4) translate(39.375 67.8)"/>
        </g>
        <path fill="#FEFEFE" d="M33.762 32.064c-.216-1.28 0-7.372-4.9-6.926-2.098-3.053.32-7.312 1.341-7.12 1.65.31 3.221.886 3.992.925 1.493.077.009-1.34 2.112-1.8 1.658-.361 1.214-4.793-.065-5.48-1.28-.686-9.335-6-11.768-7.187-1.316-.64-2.766-1.955-6.97-1.192-1.631.295-5.597.087-5.99 8.827-.065 1.41 6.66 3.627 4.879 11.342-4.477-.725-6.96 8.12-6.586 8.892-1.59 1.053-2.766 2.724-2.766 4.773 0 1.213.237 2.784.868 3.72-.631.936-1.39 2.44-1.39 3.654 0 1.205.481 2.718 1.109 3.646-.323.945-2.248 5.055-2.367 6.528-.305 3.79 3.235 10.107 8.975 9.764.308-.018.61-.028.905-.074.165.445.321.96.537 1.565.57 1.59.89 4.635-.76 6.039-3.25 2.764 2.82 9.744 3.483 10.916.6 1.065-1.238 3.517-.2 4.166.51.318 5.682.207 7.548.255 1.398.033 1.742-4.031.17-4.716-2.353-1.024-3.254.192-5.35-1.5-1.74-1.404-1.341-7.094.266-7.93 2.283-1.184 5.455-2.356 6.514-5.004.898-2.247.593-4.902-.082-7.165-.547-1.829-1.886-2.15-2.67-3.028.367-.767.591-1.615.591-2.522 0-.822-.182-1.596-.488-2.306.397-1.047 1.665-3.923 2.263-4.675 4.9 2.409 7.684-4.835 7.684-8.084 0-1.986 2.737-5.402-.885-8.303" transform="translate(.8 .4)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.688" d="M33.762 32.064c-.216-1.28 0-7.372-4.9-6.926-2.098-3.053.32-7.312 1.341-7.12 1.65.31 3.221.886 3.992.925 1.493.077.009-1.34 2.112-1.8 1.658-.361 1.214-4.793-.065-5.48-1.28-.686-9.335-6-11.768-7.187-1.316-.64-2.766-1.955-6.97-1.192-1.631.295-5.597.087-5.99 8.827-.065 1.41 6.66 3.627 4.879 11.342-4.477-.725-6.96 8.12-6.586 8.892-1.59 1.053-2.766 2.724-2.766 4.773 0 1.213.237 2.784.868 3.72-.631.936-1.39 2.44-1.39 3.654 0 1.205.481 2.718 1.109 3.646-.323.945-2.248 5.055-2.367 6.528-.305 3.79 3.235 10.107 8.975 9.764.308-.018.61-.028.905-.074.165.445.321.96.537 1.565.57 1.59.89 4.635-.76 6.039-3.25 2.764 2.82 9.744 3.483 10.916.6 1.065-1.238 3.517-.2 4.166.51.318 5.682.207 7.548.255 1.398.033 1.742-4.031.17-4.716-2.353-1.024-3.254.192-5.35-1.5-1.74-1.404-1.341-7.094.266-7.93 2.283-1.184 5.455-2.356 6.514-5.004.898-2.247.593-4.902-.082-7.165-.547-1.829-1.886-2.15-2.67-3.028.367-.767.591-1.615.591-2.522 0-.822-.182-1.596-.488-2.306.397-1.047 1.665-3.923 2.263-4.675 4.9 2.409 7.684-4.835 7.684-8.084 0-1.986 2.737-5.402-.885-8.303z" transform="translate(.8 .4)"/>
        <g>
          <path fill="#FEFEFE" d="M9.402.324c3.766 1.194 1.396 5.15 2.734 7.941 1.001 2.09 1.668 3.066 3.972 2.79 2.23-.266 6.135-1.838 8.022-2.892 1.147-.64 2.173 1.22 4.28 2.247 1.54.75 4.293.428 4.932 1.48.672 1.11-3.432 6.118-5.794 6.05-1.692-.049-2.444-5.278-3.55-5.113-6.607.985-4.208 3.57-8.087 4.726-5.062 1.51-6.337-.379-8.195-2.8C6.223 12.807 4.62 7.528.92 10.415" transform="translate(.8 .4) translate(20.25 29.738)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.688" d="M9.402.324c3.766 1.194 1.396 5.15 2.734 7.941 1.001 2.09 1.668 3.066 3.972 2.79 2.23-.266 6.135-1.838 8.022-2.892 1.147-.64 2.173 1.22 4.28 2.247 1.54.75 4.293.428 4.932 1.48.672 1.11-3.432 6.118-5.794 6.05-1.692-.049-2.444-5.278-3.55-5.113-6.607.985-4.208 3.57-8.087 4.726-5.062 1.51-6.337-.379-8.195-2.8C6.223 12.807 4.62 7.528.92 10.415" transform="translate(.8 .4) translate(20.25 29.738)"/>
        </g>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.688" d="M26.43 10.061c0 .54-.438.977-.978.977-.541 0-.98-.438-.98-.977 0-.539.439-.977.98-.977.54 0 .978.438.978.977z" transform="translate(.8 .4)"/>
        <g>
          <path fill="#FEFEFE" d="M2.022.268S.42.003.264.706c-.202.904 2.03 1.036 1.635 2.042" transform="translate(.8 .4) translate(32.625 12.892)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.688" d="M2.022.268S.42.003.264.706c-.202.904 2.03 1.036 1.635 2.042" transform="translate(.8 .4) translate(32.625 12.892)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M4.233.52S3.54 4.252.577 4.645" transform="translate(.8 .4) translate(45 39.846)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.688" d="M4.233.52S3.54 4.252.577 4.645" transform="translate(.8 .4) translate(45 39.846)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M4.296.58S1.656.886.51 2.558" transform="translate(.8 .4) translate(18 81.4)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.688" d="M4.296.58S1.656.886.51 2.558" transform="translate(.8 .4) translate(18 81.4)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.353.689c1.084 3.347 7.791-1.106 9.8.136" transform="translate(.8 .4) translate(21.375 17.385)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.688" d="M.353.689c1.084 3.347 7.791-1.106 9.8.136" transform="translate(.8 .4) translate(21.375 17.385)"/>
        </g>
        <path fill="#FEFEFE" d="M26.127 2.923s.724 4.119-1.808 3.838c-2.53-.28-.278 1.56-3.187.68-2.672-.81-4.926.43-6.554-.306-2.528-1.144-2.535-3.838-.615-4.212 1.919-.374 1.355-1.872 4.262-2.058 2.907-.188 4.048.842 4.274 1.31.227.468 2.81-1.217 3.628.748" transform="translate(.8 .4)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.688" d="M26.127 2.923s.724 4.119-1.808 3.838c-2.53-.28-.278 1.56-3.187.68-2.672-.81-4.926.43-6.554-.306-2.528-1.144-2.535-3.838-.615-4.212 1.919-.374 1.355-1.872 4.262-2.058 2.907-.188 4.048.842 4.274 1.31.227.468 2.81-1.217 3.628.748z" transform="translate(.8 .4)"/>
        <g>
          <path fill="#FEFEFE" d="M9.956 1.34c-2.691-3.11-6.154.402-8.441 1.117-3.597 1.123 3.164 3.52 3.893 3.69.73.171 4.338-.351 5.177-1.872" transform="translate(.8 .4) translate(7.875 6.03)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.688" d="M9.956 1.34c-2.691-3.11-6.154.402-8.441 1.117-3.597 1.123 3.164 3.52 3.893 3.69.73.171 4.338-.351 5.177-1.872" transform="translate(.8 .4) translate(7.875 6.03)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.411 1.207s2.433.743 4.263-.523" transform="translate(.8 .4) translate(12.375 8.4)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.688" d="M.411 1.207s2.433.743 4.263-.523" transform="translate(.8 .4) translate(12.375 8.4)"/>
        </g>
      </g>
    </SelectedSvg>
  </Container>
)

export default lamb
