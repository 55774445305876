import React from 'react'
import { Container, SelectedSvg } from '../styles'

const pomegranate = () => (
  <Container>
    <svg width="39" height="44" viewBox="0 0 39 44">
      <g fill="#BF311A" fillRule="evenodd">
        <path d="M13.656 9.898c-1.575.623-3.09 1.61-4.443 2.965-2.47 2.469-3.706 5.468-3.704 8.342 0 .37.021.739.061 1.104.05.442.45.759.891.708.443-.05.76-.448.71-.89-.037-.303-.053-.611-.053-.922 0-2.409 1.034-5.001 3.233-7.203 1.204-1.206 2.536-2.065 3.899-2.607.413-.163.614-.63.45-1.044-.163-.415-.63-.616-1.044-.453zM18.838 9.133c-.334-.032-.67-.048-1.007-.048-.421 0-.845.025-1.269.075-.442.052-.757.453-.704.893.052.443.45.757.893.707.363-.042.724-.063 1.082-.063.288 0 .573.013.856.04.442.042.837-.283.879-.726.037-.444-.288-.836-.73-.878z" transform="rotate(-26 26.806 23.07)"/>
        <path d="M40.75 9.917c-.105-.503-.491-.904-.988-1.027L36.4 8.049l.797-2.781c.134-.47.004-.973-.342-1.317-.346-.346-.847-.476-1.317-.342l-2.787.795-.84-3.362c-.124-.499-.527-.882-1.028-.987-.503-.105-1.023.088-1.336.497l-3.716 4.83c-2.604-1.278-5.401-1.943-8.195-1.943-4.448-.004-8.883 1.703-12.394 5.22C1.726 12.169.02 16.607.022 21.052c0 4.95 2.085 9.907 5.956 13.776 3.872 3.87 8.829 5.953 13.778 5.956 4.446.004 8.883-1.703 12.393-5.22 3.52-3.51 5.224-7.946 5.22-12.394 0-2.793-.667-5.592-1.942-8.197l4.83-3.716c.404-.317.597-.839.492-1.34zm-7.858 3.611c-.529.407-.68 1.141-.35 1.724 1.415 2.506 2.139 5.228 2.139 7.912-.005 2.342-.548 4.655-1.684 6.796l-.399.707c-.354.587-.757 1.16-1.206 1.715l-.526.617c-.2.222-.405.442-.619.658-.226.225-.457.439-.687.646l-.615.52c-.535.43-1.084.818-1.65 1.16l-.698.399c-.621.33-1.259.612-1.905.845l-.765.253c-.677.204-1.367.353-2.061.453l-.801.09c-.437.04-.875.061-1.313.061-.264 0-.529-.008-.795-.02l-.81-.064c-3.663-.392-7.303-2.113-10.273-5.08-3.401-3.404-5.17-7.688-5.17-11.88.005-3.77 1.414-7.468 4.434-10.496 3.028-3.022 6.723-4.427 10.496-4.433 2.684 0 5.406.725 7.912 2.139.583.329 1.315.18 1.724-.35l2.711-3.526.505 2.016c.088.352.315.654.632.836.314.183.687.229 1.038.128l1.797-.511-.514 1.792c-.1.35-.054.726.128 1.04.182.315.484.544.837.632l2.015.503-3.527 2.718z" transform="rotate(-26 26.806 23.07)"/>
      </g>
    </svg>
    <SelectedSvg id="selected" width="42" height="46" viewBox="0 0 42 46">
      <g fill="none" fillRule="evenodd" stroke="#414141">
        <path fill="#FFF" strokeWidth="2.4" d="M40.75 9.917c-.105-.503-.491-.904-.988-1.027L36.4 8.049l.797-2.781c.134-.47.004-.973-.342-1.317-.346-.346-.847-.476-1.317-.342l-2.787.795-.84-3.362c-.124-.499-.527-.882-1.028-.987-.503-.105-1.023.088-1.336.497l-3.716 4.83c-2.604-1.278-5.401-1.943-8.195-1.943-4.448-.004-8.883 1.703-12.394 5.22C1.726 12.169.02 16.607.022 21.052c0 4.95 2.085 9.907 5.956 13.776 3.872 3.87 8.829 5.953 13.778 5.956 4.446.004 8.883-1.703 12.393-5.22 3.52-3.51 5.224-7.946 5.22-12.394 0-2.793-.667-5.592-1.942-8.197l4.83-3.716c.404-.317.597-.839.492-1.34h0z" transform="rotate(-26 29.538 19.138)"/>
        <path fill="#414141" strokeWidth=".6" d="M13.656 9.898c-1.575.623-3.09 1.61-4.443 2.965-2.47 2.469-3.706 5.468-3.704 8.342 0 .37.021.739.061 1.104.05.442.45.759.891.708.443-.05.76-.448.71-.89-.037-.303-.053-.611-.053-.922 0-2.409 1.034-5.001 3.233-7.203 1.204-1.206 2.536-2.065 3.899-2.607.413-.163.614-.63.45-1.044-.163-.415-.63-.616-1.044-.453h0zM18.838 9.133c-.334-.032-.67-.048-1.007-.048-.421 0-.845.025-1.269.075-.442.052-.757.453-.704.893.052.443.45.757.893.707.363-.042.724-.063 1.082-.063.288 0 .573.013.856.04.442.042.837-.283.879-.726.037-.444-.288-.836-.73-.878z" transform="rotate(-26 29.538 19.138)"/>
      </g>
    </SelectedSvg>
  </Container>
)

export default pomegranate
