import React from 'react'
import { Container, SelectedSvg } from '../styles'

const merch = () => (
  <Container>
    <svg width="64" height="67" viewBox="0 0 64 67">
      <path fill="#BF311A" fillRule="evenodd" stroke="#BF311A" strokeWidth="1.8" d="M62.754 22.415L56.268 2.544c-.141-.442-.547-.744-1.01-.744H11.303c-.426 0-.811.256-.977.653L1.683 23.077c-.05.12-.075.244-.075.368l-.008 6.04c0 2.467 1.085 4.81 2.972 6.408v28.45c-.004.28.108.549.307.747.198.198.471.31.753.31h53.14c.278 0 .55-.112.75-.31.198-.198.31-.467.31-.748V35.893c1.883-1.599 2.968-3.941 2.968-6.408v-5.999c-.029-.099-.046-.206-.046-.314v-.757zm-50.743-18.5H54.48l5.82 18.514H4.25L12.01 3.915zm-5.323 59.37V52.862h51.024v10.423H6.688zm51.024-12.538H6.688v-13.53c3.99 1.73 8.643.148 10.742-3.653 1.486 2.685 4.314 4.35 7.385 4.35 3.072 0 5.9-1.665 7.385-4.35 1.486 2.685 4.314 4.35 7.385 4.35 3.072 0 5.9-1.665 7.386-4.35 2.098 3.8 6.751 5.383 10.742 3.652l-.001 13.53zm-3.353-14.948h-.004c-3.49-.004-6.322-2.826-6.326-6.313 0-.583-.476-1.058-1.06-1.058-.583 0-1.06.475-1.06 1.058 0 3.487-2.83 6.313-6.325 6.313-3.493 0-6.325-2.826-6.325-6.313 0-.583-.476-1.058-1.06-1.058-.583 0-1.06.475-1.06 1.058 0 3.487-2.83 6.313-6.325 6.313-3.494 0-6.325-2.826-6.325-6.313 0-.583-.476-1.058-1.06-1.058-.583 0-1.06.475-1.06 1.058 0 3.487-2.831 6.313-6.325 6.313S3.72 32.973 3.72 29.486v-4.942l56.96.005v4.94-.003c-.004 3.487-2.831 6.309-6.325 6.313h.005z"/>
    </svg>
    <SelectedSvg id="selected" width="64" height="67" viewBox="0 0 64 67">
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M6 22.8H56.4V61.2H6z" transform="translate(1.6 1.8)"/>
        <path fill="#FFF" d="M10.55 0h42.394l7.296 20.175-.814 8.263c-.368 3.743-3.556 6.57-7.316 6.49-.835-.017-1.398-.06-1.69-.128-.791-.184-2.303-1.184-4.534-3-2.085 4.546-5.062 5.546-8.932 3-2.083-1.37-7.06-.105-13.811 0-1.24.02-3.67-3-7.564-3-.621 0-2.687.545-6.196 1.636-3.796 1.18-7.83-.94-9.01-4.736-.278-.895-.378-1.836-.296-2.77.244-2.762.619-5.082 1.123-6.96C2.256 15.04 5.373 8.717 10.55 0z" transform="translate(1.6 1.8)"/>
        <path fill="#414141" stroke="#414141" strokeWidth="1.799" d="M61.154 20.615L54.668.744C54.527.302 54.12 0 53.658 0H9.703c-.426 0-.811.256-.977.653L.083 21.277c-.05.12-.075.244-.075.368L0 27.685c0 2.467 1.085 4.81 2.972 6.408v28.45c-.004.28.108.549.307.747.198.198.471.31.753.31h53.14c.278 0 .55-.112.75-.31.198-.198.31-.467.31-.748V34.093c1.883-1.599 2.968-3.941 2.968-6.408v-5.999c-.029-.099-.046-.206-.046-.314v-.757zm-50.743-18.5H52.88l5.82 18.514H2.65L10.41 2.115zm-5.323 59.37V51.062h51.024v10.423H5.088zm51.024-12.538H5.088v-13.53c3.99 1.73 8.643.148 10.742-3.653 1.486 2.685 4.314 4.35 7.385 4.35 3.072 0 5.9-1.665 7.385-4.35 1.486 2.685 4.314 4.35 7.385 4.35 3.072 0 5.9-1.665 7.386-4.35 2.098 3.8 6.751 5.383 10.742 3.652l-.001 13.53zm-3.353-14.948h-.004c-3.49-.004-6.322-2.826-6.326-6.313 0-.583-.476-1.058-1.06-1.058-.583 0-1.06.475-1.06 1.058 0 3.487-2.83 6.313-6.325 6.313-3.493 0-6.325-2.826-6.325-6.313 0-.583-.476-1.058-1.06-1.058-.583 0-1.06.475-1.06 1.058 0 3.487-2.83 6.313-6.325 6.313-3.494 0-6.325-2.826-6.325-6.313 0-.583-.476-1.058-1.06-1.058-.583 0-1.06.475-1.06 1.058 0 3.487-2.831 6.313-6.325 6.313S2.12 31.173 2.12 27.686v-4.942l56.96.005v4.94-.003c-.004 3.487-2.831 6.309-6.325 6.313h.005z" transform="translate(1.6 1.8)"/>
      </g>
    </SelectedSvg>
  </Container>
)

export default merch
