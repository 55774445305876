import React from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import { getLogoBase64 } from '../../lib/finalLogoBase64'
import Back from '../../assets/back'
import {
  ImageWrapper,
  Navigation,
  Content,
} from './PrintMode.styles'
import { setIsFinalCrestDisplay, setIsPrintModeDisplay } from '../../store/ui/actions'

function PrintMode({ dispatch }) {

  const handleClick = () => {
    dispatch(setIsFinalCrestDisplay(true))
    // dispatch(setIsPrintModeDisplay(false))
  }

  return (
    <>
      <ImageWrapper>
        <img src={getLogoBase64()} alt="crest family" />
      </ImageWrapper>
      <Navigation
        onClick={handleClick}
        id="backArrow"
      >
        <Back />
        <Content>
          <Translate id="navigation.back" />
        </Content>
      </Navigation>
    </>
  )
}

export default connect()(PrintMode)
