import React from 'react'
import { Container, SelectedSvg } from '../styles'

const shieldOfDavid = () => (
  <Container>
    <svg width="64" height="70" viewBox="0 0 64 70">
      <path fill="#FFF" fillRule="evenodd" stroke="#47C3D2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.6" d="M32.198 2.2l9.512 15.888H62.2L51.955 35.2 62.2 52.311H41.711L32.198 68.2l-9.511-15.889H2.2L12.443 35.2 2.2 18.089l20.487-.001L32.198 2.2z"/>
    </svg>
    <SelectedSvg id="selected" width="64" height="70" viewBox="0 0 64 70">
      <path fill="#FFF" fillRule="evenodd" stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.6" d="M32.198 2l9.512 15.888H62.2L51.955 35 62.2 52.111H41.711L32.198 68l-9.511-15.889H2.2L12.443 35 2.2 17.889l20.487-.001L32.198 2z"/>
    </SelectedSvg>
  </Container>
)

export default shieldOfDavid

