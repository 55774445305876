import React from 'react'
import { Container, SelectedSvg } from '../styles'

const fox = () => (
  <Container>
    <svg width="71" height="94" viewBox="0 0 71 94">
      <g fill="none" fillRule="evenodd">
        <g>
          <path fill="#FEFEFE" d="M.523 6.062c0-1.747 2.293-5.038 4.316-5.137 2.024-.098-.538 2.042-.27 3.772.27 1.727-.211 3.048-.211 3.048" transform="translate(.4 .6) translate(39.934 1.585)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M.523 6.062c0-1.747 2.293-5.038 4.316-5.137 2.024-.098-.538 2.042-.27 3.772.27 1.727-.211 3.048-.211 3.048" transform="translate(.4 .6) translate(39.934 1.585)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M29.621 40.786c-2.079 2.17-5.812 3.764-8.66 2.114-.856-.495-1.54-1.354-1.618-2.33-.055-.672.174-1.336.471-1.942 1.872-3.79 6.266-5.658 8.943-8.943 1.558-1.912 2.503-4.313 2.61-6.765.193-4.39-2.32-8.621-5.868-11.274-3.548-2.653-8.006-3.888-12.436-4.311-1.3-.124-2.639-.192-3.835-.71C6.823 5.581 5.57 3.02 3.91 1.01c-.138-.167-.287-.336-.488-.42-.558-.23-1.132.3-1.438.815C1.141 2.828.903 4.52.862 6.168.65 14.786 5.88 23.307 13.712 27.11c1.016.492 2.164 1.006 2.587 2.042.576 1.407-.481 2.89-1.314 4.167-1.146 1.754-1.956 3.72-2.375 5.767-.248 1.212-.36 2.484-.016 3.674.56 1.946 2.287 3.41 4.207 4.117 3.93 1.444 9.928.899 12.82-2.403" transform="translate(.4 .6) translate(0 13.98)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M29.621 40.786c-2.079 2.17-5.812 3.764-8.66 2.114-.856-.495-1.54-1.354-1.618-2.33-.055-.672.174-1.336.471-1.942 1.872-3.79 6.266-5.658 8.943-8.943 1.558-1.912 2.503-4.313 2.61-6.765.193-4.39-2.32-8.621-5.868-11.274-3.548-2.653-8.006-3.888-12.436-4.311-1.3-.124-2.639-.192-3.835-.71C6.823 5.581 5.57 3.02 3.91 1.01c-.138-.167-.287-.336-.488-.42-.558-.23-1.132.3-1.438.815C1.141 2.828.903 4.52.862 6.168.65 14.786 5.88 23.307 13.712 27.11c1.016.492 2.164 1.006 2.587 2.042.576 1.407-.481 2.89-1.314 4.167-1.146 1.754-1.956 3.72-2.375 5.767-.248 1.212-.36 2.484-.016 3.674.56 1.946 2.287 3.41 4.207 4.117 3.93 1.444 9.928.899 12.82-2.403" transform="translate(.4 .6) translate(0 13.98)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.033.258c1.624-.334 9.31-.676 11.832 2.387 2.751 3.341-.954 11.396-.33 12.251.402.551 2.569 1.531 3.21 1.776 2.483.948 4.201-.79 6.695.564 1.794.976 2.84 4.638.206 5.165-3.168.873-5.601.148-6.302-1.712-.504-1.343-5.276-.5-6.468-1.317-1.116-.765-.4-9.23-2.634-7.88-1.56.943-4.386 1.475-6.209 1.327" transform="translate(.4 .6) translate(42.216 55.546)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M.033.258c1.624-.334 9.31-.676 11.832 2.387 2.751 3.341-.954 11.396-.33 12.251.402.551 2.569 1.531 3.21 1.776 2.483.948 4.201-.79 6.695.564 1.794.976 2.84 4.638.206 5.165-3.168.873-5.601.148-6.302-1.712-.504-1.343-5.276-.5-6.468-1.317-1.116-.765-.4-9.23-2.634-7.88-1.56.943-4.386 1.475-6.209 1.327" transform="translate(.4 .6) translate(42.216 55.546)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.075 18.33c3.021-1.312 5.979 1.298 6.869-1.841.559-1.978-1.44-10.035-.801-11.989.745-2.284 3.411-3.18 5.734-3.883.944-.285 4.735-1.58 5.068.923.247 1.85-2.831 1.947-4.416 2.962C10.943 5.52 9.61 7.378 9.61 9.244c0 2.107 1.988 10.524 1.767 12.346-.5 4.125-5.006 3.56-8.26 4.125" transform="translate(.4 .6) translate(52.485 13.98)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M.075 18.33c3.021-1.312 5.979 1.298 6.869-1.841.559-1.978-1.44-10.035-.801-11.989.745-2.284 3.411-3.18 5.734-3.883.944-.285 4.735-1.58 5.068.923.247 1.85-2.831 1.947-4.416 2.962C10.943 5.52 9.61 7.378 9.61 9.244c0 2.107 1.988 10.524 1.767 12.346-.5 4.125-5.006 3.56-8.26 4.125" transform="translate(.4 .6) translate(52.485 13.98)"/>
        </g>
        <path fill="#FEFEFE" d="M46.13 22.169c.024-1.093 2.997-2.408 4.23-2.345 1.513.078 3.038.463 3.689-.89.633-1.313 2.05-1.377.752-2.067-1.298-.688-6.464-2.106-7.442-3.193-1.706-1.897-.1-2.625-2.2-4.084-1.931-1.34-5.137-.81-6.035-2.965-.643-1.543.834-3.075 2.082-4.2.299-.269.62-.64.49-1.018-.118-.352-.552-.474-.924-.518-3.324-.4-7.184 2.008-8.55 5.03-.848 1.876.724 3.883-.498 5.547-.783 1.067-1.414 1.413-2.102 2.543-1.426 2.337-2.562 8.082-.201 9.497 1.662.996 4.563 1.71 6.235 3.346 1.122 1.096.16 3.53.16 4.689 0 1.484-1.126 9.133-3.527 12.03-2.272 2.741-4.799 4.472-6.456 7.508-3.356 6.15-1.161 14.32 3.848 19.255 1.556 1.534 3.455 2.801 4.53 4.697 1.077 1.893.902 4.756-1.068 5.72-1.087.53-2.557.424-3.292 1.376-.828 1.076-.041 2.66 1.008 3.527 1.05.867 2.392 1.474 3.064 2.65.61 1.07.638 2.554 1.691 3.205.517.32 1.16.344 1.769.359l5.677.14c1.416.033 3.07-.04 3.899-1.175 1.019-1.391-.058-3.497-1.652-4.184-2.388-1.028-5.377.82-7.5-.876-1.765-1.411-.41-3.58 1.22-4.418 2.315-1.188 4.582-1.161 5.654-3.82.91-2.254.603-4.918-.083-7.189-.997-3.302-2.69-6.556-2.373-9.988.366-3.962 3.312-7.164 6.198-9.94 2.885-2.776 6.013-5.689 6.935-9.562.751-3.158-.194-6.662-2.439-9.031-1.921-2.03-6.928-3.4-6.789-9.656" transform="translate(.4 .6)"/>
        <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M46.13 22.169c.024-1.093 2.997-2.408 4.23-2.345 1.513.078 3.038.463 3.689-.89.633-1.313 2.05-1.377.752-2.067-1.298-.688-6.464-2.106-7.442-3.193-1.706-1.897-.1-2.625-2.2-4.084-1.931-1.34-5.137-.81-6.035-2.965-.643-1.543.834-3.075 2.082-4.2.299-.269.62-.64.49-1.018-.118-.352-.552-.474-.924-.518-3.324-.4-7.184 2.008-8.55 5.03-.848 1.876.724 3.883-.498 5.547-.783 1.067-1.414 1.413-2.102 2.543-1.426 2.337-2.562 8.082-.201 9.497 1.662.996 4.563 1.71 6.235 3.346 1.122 1.096.16 3.53.16 4.689 0 1.484-1.126 9.133-3.527 12.03-2.272 2.741-4.799 4.472-6.456 7.508-3.356 6.15-1.161 14.32 3.848 19.255 1.556 1.534 3.455 2.801 4.53 4.697 1.077 1.893.902 4.756-1.068 5.72-1.087.53-2.557.424-3.292 1.376-.828 1.076-.041 2.66 1.008 3.527 1.05.867 2.392 1.474 3.064 2.65.61 1.07.638 2.554 1.691 3.205.517.32 1.16.344 1.769.359l5.677.14c1.416.033 3.07-.04 3.899-1.175 1.019-1.391-.058-3.497-1.652-4.184-2.388-1.028-5.377.82-7.5-.876-1.765-1.411-.41-3.58 1.22-4.418 2.315-1.188 4.582-1.161 5.654-3.82.91-2.254.603-4.918-.083-7.189-.997-3.302-2.69-6.556-2.373-9.988.366-3.962 3.312-7.164 6.198-9.94 2.885-2.776 6.013-5.689 6.935-9.562.751-3.158-.194-6.662-2.439-9.031-1.921-2.03-6.928-3.4-6.789-9.656z" transform="translate(.4 .6)"/>
        <g>
          <path fill="#FEFEFE" d="M7.629.11s1.95 10.22-7.34 8.238" transform="translate(.4 .6) translate(2.282 20.741)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M7.629.11s1.95 10.22-7.34 8.238" transform="translate(.4 .6) translate(2.282 20.741)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M3.427.22S2.026-.11.827 3.858" transform="translate(.4 .6) translate(34.23 3.839)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M3.427.22S2.026-.11.827 3.858" transform="translate(.4 .6) translate(34.23 3.839)"/>
        </g>
        <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M53.197 18.22s-2.776-1.182-.132-2.053" transform="translate(.4 .6)"/>
        <path fill="#FEFEFE" d="M43.75 14.514c0 .541-.445.98-.992.98-.55 0-.993-.439-.993-.98 0-.54.444-.98.993-.98.547 0 .992.44.992.98" transform="translate(.4 .6)"/>
        <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M43.75 14.514c0 .541-.445.98-.992.98-.55 0-.993-.439-.993-.98 0-.54.444-.98.993-.98.547 0 .992.44.992.98z" transform="translate(.4 .6)"/>
        <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M46.489 13.534c-1.925-3.622-13.433-5.82-12.433-1.08 1.041 4.932-3.4 7.014-3.4 7.014s.721 3.337 5.7 3.952c6.266.773 5.894-3.952 9.774-1.25M49.652 29.344s-7.055-1.118-4.094 4.754c5.502 10.91-10.502 21.93-3.6 27.344" transform="translate(.4 .6)"/>
        <g>
          <path fill="#FEFEFE" d="M4.957.654c5.145 0 5.548 6.53 6.905 9.332 1.016 2.098 1.968 3.63 4.305 3.354 2.261-.268 6.222-1.844 8.135-2.901 2.014-1.114 3.735.991 5.268 3.494.681 1.112 1.466 3.424-.628 4.482-1.528.773-3.162-4.383-4.283-4.218-6.702.988-5.225 4.675-8.693 5.662-4.731 1.349-6.202-1.062-8.086-3.491-1.515-1.953-3.366-6.567-7.116-3.67" transform="translate(.4 .6) translate(38.793 32.01)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M4.957.654c5.145 0 5.548 6.53 6.905 9.332 1.016 2.098 1.968 3.63 4.305 3.354 2.261-.268 6.222-1.844 8.135-2.901 2.014-1.114 3.735.991 5.268 3.494.681 1.112 1.466 3.424-.628 4.482-1.528.773-3.162-4.383-4.283-4.218-6.702.988-5.225 4.675-8.693 5.662-4.731 1.349-6.202-1.062-8.086-3.491-1.515-1.953-3.366-6.567-7.116-3.67" transform="translate(.4 .6) translate(38.793 32.01)"/>
        </g>
      </g>
    </svg>
    <SelectedSvg id="selected" width="71" height="93" viewBox="0 0 71 93">
      <g fill="none" fillRule="evenodd">
        <g>
          <path fill="#FEFEFE" d="M.523 6.062c0-1.747 2.293-5.038 4.317-5.136 2.023-.099-.54 2.04-.27 3.77s-.213 3.049-.213 3.049" transform="translate(.4) translate(39.934 1.585)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" d="M.523 6.062c0-1.747 2.293-5.038 4.317-5.136 2.023-.099-.54 2.04-.27 3.77s-.213 3.049-.213 3.049" transform="translate(.4) translate(39.934 1.585)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M29.622 40.786c-2.08 2.17-5.813 3.765-8.66 2.114-.856-.495-1.54-1.355-1.62-2.33-.054-.672.175-1.336.473-1.942 1.87-3.79 6.265-5.658 8.942-8.942 1.558-1.913 2.502-4.314 2.61-6.765.192-4.391-2.32-8.622-5.868-11.275-3.548-2.654-8.006-3.889-12.437-4.311-1.3-.124-2.638-.19-3.834-.71C6.824 5.582 5.57 3.019 3.909 1.01 3.771.842 3.622.673 3.421.59c-.559-.231-1.132.298-1.438.814C1.141 2.828.902 4.52.862 6.17c-.211 8.616 5.018 17.138 12.85 20.94 1.015.493 2.163 1.007 2.587 2.042.577 1.408-.482 2.89-1.314 4.167-1.146 1.755-1.956 3.722-2.376 5.768-.247 1.212-.358 2.485-.014 3.674.559 1.946 2.286 3.411 4.205 4.116 3.93 1.445 9.928.9 12.822-2.402" transform="translate(.4) translate(0 13.98)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" d="M29.622 40.786c-2.08 2.17-5.813 3.765-8.66 2.114-.856-.495-1.54-1.355-1.62-2.33-.054-.672.175-1.336.473-1.942 1.87-3.79 6.265-5.658 8.942-8.942 1.558-1.913 2.502-4.314 2.61-6.765.192-4.391-2.32-8.622-5.868-11.275-3.548-2.654-8.006-3.889-12.437-4.311-1.3-.124-2.638-.19-3.834-.71C6.824 5.582 5.57 3.019 3.909 1.01 3.771.842 3.622.673 3.421.59c-.559-.231-1.132.298-1.438.814C1.141 2.828.902 4.52.862 6.17c-.211 8.616 5.018 17.138 12.85 20.94 1.015.493 2.163 1.007 2.587 2.042.577 1.408-.482 2.89-1.314 4.167-1.146 1.755-1.956 3.722-2.376 5.768-.247 1.212-.358 2.485-.014 3.674.559 1.946 2.286 3.411 4.205 4.116 3.93 1.445 9.928.9 12.822-2.402" transform="translate(.4) translate(0 13.98)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.033.258c1.623-.334 9.31-.676 11.832 2.387 2.75 3.341-.953 11.396-.33 12.25.402.552 2.568 1.532 3.21 1.777 2.482.947 4.2-.79 6.695.565 1.794.975 2.84 4.637.205 5.164-3.167.873-5.6.148-6.3-1.712-.506-1.343-5.277-.5-6.468-1.317-1.117-.766-.4-9.23-2.635-7.88-1.56.943-4.386 1.475-6.21 1.327" transform="translate(.4) translate(42.216 55.546)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" d="M.033.258c1.623-.334 9.31-.676 11.832 2.387 2.75 3.341-.953 11.396-.33 12.25.402.552 2.568 1.532 3.21 1.777 2.482.947 4.2-.79 6.695.565 1.794.975 2.84 4.637.205 5.164-3.167.873-5.6.148-6.3-1.712-.506-1.343-5.277-.5-6.468-1.317-1.117-.766-.4-9.23-2.635-7.88-1.56.943-4.386 1.475-6.21 1.327" transform="translate(.4) translate(42.216 55.546)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.075 18.33c3.02-1.312 5.978 1.298 6.868-1.842.56-1.976-1.437-10.034-.8-11.988.744-2.284 3.41-3.18 5.734-3.883.944-.285 4.735-1.58 5.068.923.247 1.85-2.832 1.947-4.417 2.962C10.942 5.52 9.61 7.378 9.61 9.244c0 2.108 1.989 10.525 1.767 12.346-.5 4.125-5.006 3.56-8.26 4.125" transform="translate(.4) translate(52.485 13.98)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" d="M.075 18.33c3.02-1.312 5.978 1.298 6.868-1.842.56-1.976-1.437-10.034-.8-11.988.744-2.284 3.41-3.18 5.734-3.883.944-.285 4.735-1.58 5.068.923.247 1.85-2.832 1.947-4.417 2.962C10.942 5.52 9.61 7.378 9.61 9.244c0 2.108 1.989 10.525 1.767 12.346-.5 4.125-5.006 3.56-8.26 4.125" transform="translate(.4) translate(52.485 13.98)"/>
        </g>
        <path fill="#FEFEFE" d="M46.13 22.169c.025-1.093 2.997-2.408 4.23-2.345 1.513.077 3.039.463 3.69-.89.632-1.313 2.048-1.377.751-2.066-1.297-.69-6.464-2.107-7.443-3.194-1.704-1.897-.099-2.625-2.2-4.084-1.93-1.341-5.136-.81-6.034-2.965-.643-1.544.834-3.075 2.082-4.2.298-.269.62-.64.49-1.018-.119-.35-.551-.473-.923-.518-3.326-.4-7.186 2.008-8.55 5.03-.85 1.876.722 3.883-.498 5.547-.784 1.068-1.415 1.413-2.103 2.543-1.425 2.337-2.563 8.082-.201 9.497 1.662.996 4.562 1.71 6.235 3.346 1.122 1.097.159 3.529.159 4.689 0 1.484-1.126 9.132-3.527 12.03-2.27 2.741-4.798 4.472-6.454 7.508-3.357 6.15-1.162 14.32 3.847 19.255 1.556 1.533 3.455 2.801 4.53 4.696 1.076 1.894.902 4.757-1.068 5.72-1.086.531-2.558.426-3.292 1.377-.83 1.076-.041 2.66 1.009 3.527 1.05.867 2.39 1.474 3.062 2.652.61 1.068.64 2.552 1.692 3.203.516.32 1.16.344 1.769.359l5.676.14c1.417.034 3.07-.04 3.9-1.174 1.019-1.392-.058-3.499-1.652-4.185-2.388-1.029-5.376.82-7.5-.876-1.765-1.411-.41-3.578 1.221-4.418 2.314-1.188 4.58-1.161 5.654-3.82.91-2.254.602-4.918-.084-7.189-.997-3.302-2.69-6.556-2.373-9.988.365-3.962 3.312-7.164 6.198-9.94 2.885-2.776 6.012-5.69 6.935-9.562.752-3.157-.194-6.662-2.438-9.032-1.923-2.029-6.929-3.4-6.79-9.655" transform="translate(.4)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" d="M46.13 22.169c.025-1.093 2.997-2.408 4.23-2.345 1.513.077 3.039.463 3.69-.89.632-1.313 2.048-1.377.751-2.066-1.297-.69-6.464-2.107-7.443-3.194-1.704-1.897-.099-2.625-2.2-4.084-1.93-1.341-5.136-.81-6.034-2.965-.643-1.544.834-3.075 2.082-4.2.298-.269.62-.64.49-1.018-.119-.35-.551-.473-.923-.518-3.326-.4-7.186 2.008-8.55 5.03-.85 1.876.722 3.883-.498 5.547-.784 1.068-1.415 1.413-2.103 2.543-1.425 2.337-2.563 8.082-.201 9.497 1.662.996 4.562 1.71 6.235 3.346 1.122 1.097.159 3.529.159 4.689 0 1.484-1.126 9.132-3.527 12.03-2.27 2.741-4.798 4.472-6.454 7.508-3.357 6.15-1.162 14.32 3.847 19.255 1.556 1.533 3.455 2.801 4.53 4.696 1.076 1.894.902 4.757-1.068 5.72-1.086.531-2.558.426-3.292 1.377-.83 1.076-.041 2.66 1.009 3.527 1.05.867 2.39 1.474 3.062 2.652.61 1.068.64 2.552 1.692 3.203.516.32 1.16.344 1.769.359l5.676.14c1.417.034 3.07-.04 3.9-1.174 1.019-1.392-.058-3.499-1.652-4.185-2.388-1.029-5.376.82-7.5-.876-1.765-1.411-.41-3.578 1.221-4.418 2.314-1.188 4.58-1.161 5.654-3.82.91-2.254.602-4.918-.084-7.189-.997-3.302-2.69-6.556-2.373-9.988.365-3.962 3.312-7.164 6.198-9.94 2.885-2.776 6.012-5.69 6.935-9.562.752-3.157-.194-6.662-2.438-9.032-1.923-2.029-6.929-3.4-6.79-9.655z" transform="translate(.4)"/>
        <g>
          <path fill="#FEFEFE" d="M7.63.11S9.58 10.33.287 8.348" transform="translate(.4) translate(2.282 20.741)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" d="M7.63.11S9.58 10.33.287 8.348" transform="translate(.4) translate(2.282 20.741)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M3.427.22s-1.4-.33-2.6 3.638" transform="translate(.4) translate(34.23 3.839)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" d="M3.427.22s-1.4-.33-2.6 3.638" transform="translate(.4) translate(34.23 3.839)"/>
        </g>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" d="M53.197 18.22s-2.776-1.182-.133-2.053" transform="translate(.4)"/>
        <path fill="#FEFEFE" d="M43.75 14.514c0 .541-.445.98-.993.98-.547 0-.992-.439-.992-.98 0-.54.445-.98.992-.98.548 0 .993.44.993.98" transform="translate(.4)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" d="M43.75 14.514c0 .541-.445.98-.993.98-.547 0-.992-.439-.992-.98 0-.54.445-.98.992-.98.548 0 .993.44.993.98z" transform="translate(.4)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" d="M46.488 13.534c-1.924-3.623-13.432-5.821-12.432-1.08 1.04 4.932-3.401 7.015-3.401 7.015s.722 3.337 5.701 3.95c6.266.774 5.894-3.95 9.775-1.25M49.652 29.344s-7.055-1.118-4.093 4.754c5.5 10.91-10.503 21.93-3.602 27.345" transform="translate(.4)"/>
        <g>
          <path fill="#FEFEFE" d="M4.957.655c5.145 0 5.548 6.53 6.905 9.331 1.015 2.097 1.968 3.63 4.305 3.354 2.262-.268 6.222-1.844 8.135-2.902 2.014-1.113 3.734.993 5.268 3.494.682 1.114 1.467 3.425-.629 4.484-1.527.772-3.161-4.385-4.282-4.219-6.702.988-5.224 4.675-8.692 5.662-4.733 1.348-6.203-1.061-8.087-3.492-1.515-1.951-3.366-6.566-7.116-3.669" transform="translate(.4) translate(38.793 32.01)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.7" d="M4.957.655c5.145 0 5.548 6.53 6.905 9.331 1.015 2.097 1.968 3.63 4.305 3.354 2.262-.268 6.222-1.844 8.135-2.902 2.014-1.113 3.734.993 5.268 3.494.682 1.114 1.467 3.425-.629 4.484-1.527.772-3.161-4.385-4.282-4.219-6.702.988-5.224 4.675-8.692 5.662-4.733 1.348-6.203-1.061-8.087-3.492-1.515-1.951-3.366-6.566-7.116-3.669" transform="translate(.4) translate(38.793 32.01)"/>
        </g>
      </g>
    </SelectedSvg>
  </Container>
)

export default fox
