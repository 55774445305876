import React from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import {
  Container,
  Content,
  MobileWrapper,
} from './CrestBuilder.styles'
import NumberSteps from '../NumberSteps/NumberSteps'
import Step from '../Step/Step'
import steps from '../../lib/steps'
import Crest from '../Crest/Crest'
import DirectionArrows from '../DirectionArrows/DirectionArrows'
import StartOverModal from '../StartOverModal'
import { DIRECTIONS } from '../../consts'

function CrestBuilder(props) {
  const { currentStep, activeLanguage } = props
  const currDirection = activeLanguage ? DIRECTIONS[activeLanguage.code] : 'ltr'

  return (
    <Container direction={currDirection}>
      <StartOverModal />
      <Content>
        <NumberSteps />
        <MobileWrapper>
          <Step
            title={steps[currentStep - 1].title}
            description={steps[currentStep - 1].description}
            instruction={steps[currentStep - 1].instruction}
            component={steps[currentStep - 1].component}
          />
          <Crest />
        </MobileWrapper>
      </Content>
      <DirectionArrows />
    </Container>
  );
}

const mapStateToProps = ({ ui: { currentStep } }) => ({ currentStep })

export default connect(mapStateToProps)(withLocalize(CrestBuilder))
