import _omit from 'lodash/omit'
import { featuresId, serviceUrl } from '../consts'
import api from '../lib/api'
import store from '../initializer'
import { setShareFinalCrestImageId } from '../store/ui/actions'

let logoBase64

const extractSelector = (id) => {
  if (id.includes('/')) {
    return id.split('/')[1]
  }
  return id
}

function manipulateSvg(svgElem) {
    const state = store.getState()
    const { userSelections } = state
    const { selectedFeatures } = userSelections
    Object.values(_omit(userSelections, ['selectedName', 'selectedFeatures'])).forEach(id => {
      const selectedNode = svgElem.querySelector(`g[id*="${extractSelector(id)}"]`)
      selectedNode.parentNode.innerHTML = selectedNode.outerHTML
    });

    [{
      id: selectedFeatures[0],
      parentId: featuresId.containerLeft,
    },
    {
      id: selectedFeatures[1],
      parentId: featuresId.containerRight, 
    }].forEach(({ id, parentId }) => {
      const selectedNode = svgElem.querySelector(`#${parentId} g[id*="${id.split('/')[1]}"]`)
      selectedNode.parentNode.innerHTML = selectedNode.outerHTML
    })

    const textElem = document.getElementById('crestName').cloneNode(true)
    svgElem.appendChild(textElem)
}

async function setLogoBase64() {
    const crest = document.getElementById('crest')
    const newSvg = crest.cloneNode(true)
    manipulateSvg(newSvg)
    const { b64, id } = await api.post(`${serviceUrl}/svg-to-b64`, { json: { svg: newSvg.outerHTML }}).json();
    store.dispatch(setShareFinalCrestImageId(id))
    logoBase64 = `data:image/jpeg;base64,${b64}`
}

function getLogoBase64() {
  return logoBase64 
}

export {
  setLogoBase64,
  getLogoBase64,
}