import React from 'react'
import { withLocalize } from 'react-localize-redux'
import { Container, LogoWrapper, InfoLangsWrapper, TitleImagesWrapper } from './Header.styles'
import LogoSvg from '../../assets/logo'
import LogoHebrewSvg from '../../assets/logoHebrew'
import FamilyCrestTitlePng from '../../assets/title.png'
import FamilyCrestTitleHebrewPng from '../../assets/titleHebrew.png'
import ravdoriLogo from '../../assets/ravdori-logo.png'
import socialEqualityLogo from '../../assets/social-equality.png'
import Languages from '../Languages/Languages'
import LanguagesMobile from '../Languages/LanguagesMobile'
import { isMobile } from '../../consts'
import Info from '../InfoModal/InfoModal'
import useDeviceWidth from '../../lib/useDeviceWidth'

function Header({ isCrestBuilderHidden, activeLanguage }) {
  const currLang = activeLanguage ? activeLanguage.code : 'en'
  const isTabletOrMobile = useDeviceWidth()
  const currLangTitle = currLang === 'en' ? FamilyCrestTitlePng : FamilyCrestTitleHebrewPng

  const handleLogoClick = () => {
    const url = currLang === 'en' ? 'https://www.bh.org.il/' : 'https://www.bh.org.il/he/'
    window.open(url, '_blank')
  }

  return (
    <Container isCrestBuilderHidden={isCrestBuilderHidden} direction={currLang === 'en' ? 'ltr' : 'rtl'}>
      <LogoWrapper onClick={handleLogoClick}>
        {currLang === 'en' && <LogoSvg />}
        {currLang !== 'en' && <LogoHebrewSvg />}
      </LogoWrapper>
      <TitleImagesWrapper>
        <img src={socialEqualityLogo} width={isTabletOrMobile ? '40px' : '70px'} />
        <img src={ravdoriLogo} width={isTabletOrMobile ? '85px' : '155px'} />
      </TitleImagesWrapper>
      <InfoLangsWrapper>
        {isMobile ? <LanguagesMobile />
        :<Languages isCrestBuilderHidden={isCrestBuilderHidden} />}
        <Info />
      </InfoLangsWrapper>
    </Container>
  )
}

export default withLocalize(Header)
