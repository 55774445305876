import styled from 'styled-components'
import device from '../../lib/devices'

const Container = styled.div(({ currLang }) => ({
  fontSize: '20rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '100rem',
  direction: currLang === 'en' ? 'rtl' : 'ltr',
  [`@media ${device.tablet}`]: {
    position: 'absolute',
    bottom: 0,
    background: 'white',
    paddingBottom: '15rem',
    margin: 0,
  },
}))

const Navigation = styled.button(({ disabled, currLang }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '22rem',
  fontWeight: 600,
  color: '#232e83',
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  pointerEvents: disabled ? 'none' : 'auto',
  opacity: disabled ? '0.5' : 1,
  direction: currLang === 'en' ? 'ltr' : 'rtl',
  [`@media ${device.mobileL}`]: {
    fontSize: '13rem',
  },
  '& svg': {
    margin: '0 5px',
    width: '12rem',
    height: 'auto',
    opacity: disabled ? '0.5' : 1,
    transform: currLang === 'he' ? 'rotate(180deg)' : '',
    [`@media ${device.mobileL}`]: {
      width: '8rem',
    },
  }
}))

const Content = styled.div({
  borderBottom: 'solid 3px #232e83',
})

export {
  Container,
  Navigation,
  Content,
}