import React from 'react'
import { Container, SelectedSvg } from '../styles'

const ox = () => (
  <Container>
    <svg width="68" height="94" viewBox="0 0 68 94">
      <g fill="none" fillRule="evenodd">
        <path fill="#FEFEFE" d="M19.88 56.594s-8.646 9.118-11.14 2.796c-2.475-6.274 12.872-14.643 2.697-22.18.206-.648.324-1.356.374-2.052.448-6.199-13.125-6.613-10.658-5.013 1.83 1.185 4.392 2.307 3.825 4.515-.898 3.485.366 5.09 2.667 5.09 1.076 0 1.798.02 2.324-.773 1.144 1.076 3.457 2.889 1.777 6.479-1.113 2.382-8.199 9.806-5.405 15.565 2.674 5.512 8.756 2.642 14.675-1.341l-1.136-3.086z" transform="translate(.6 .8)"/>
        <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M19.88 56.594s-8.646 9.118-11.14 2.796c-2.475-6.274 12.872-14.643 2.697-22.18.206-.648.324-1.356.374-2.052.448-6.199-13.125-6.613-10.658-5.013 1.83 1.185 4.392 2.307 3.825 4.515-.898 3.485.366 5.09 2.667 5.09 1.076 0 1.798.02 2.324-.773 1.144 1.076 3.457 2.889 1.777 6.479-1.113 2.382-8.199 9.806-5.405 15.565 2.674 5.512 8.756 2.642 14.675-1.341l-1.136-3.086z" transform="translate(.6 .8)"/>
        <path fill="#FEFEFE" d="M29.897 12.622c2.49-.042 16.648-4.19 7.442-11.335 0 0-2.197-1.256-.803.468 1.394 1.724 2.186 3.35-1.404 5.466-3.589 2.114-4.968 2.382-5.467 3.97-.164.524-.133 1.436.232 1.43" transform="translate(.6 .8)"/>
        <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M29.897 12.622c2.49-.042 16.648-4.19 7.442-11.335 0 0-2.197-1.256-.803.468 1.394 1.724 2.186 3.35-1.404 5.466-3.589 2.114-4.968 2.382-5.467 3.97-.164.524-.133 1.436.232 1.43z" transform="translate(.6 .8)"/>
        <g>
          <path fill="#FEFEFE" d="M.2.264C1.818-.071 9.484-.412 12 2.651c2.743 3.34-.951 11.395-.329 12.25.4.551 2.561 1.532 3.201 1.776 2.476.948 4.189-.79 6.677.566 1.79.976-5.207 9.654-6.105 5.208-.284-1.405-5.236-2.257-6.426-3.074-1.112-.765-.398-9.23-2.626-7.879-1.555.944-4.374 1.474-6.193 1.327" transform="translate(.6 .8) translate(35.276 55.762)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M.2.264C1.818-.071 9.484-.412 12 2.651c2.743 3.34-.951 11.395-.329 12.25.4.551 2.561 1.532 3.201 1.776 2.476.948 4.189-.79 6.677.566 1.79.976-5.207 9.654-6.105 5.208-.284-1.405-5.236-2.257-6.426-3.074-1.112-.765-.398-9.23-2.626-7.879-1.555.944-4.374 1.474-6.193 1.327" transform="translate(.6 .8) translate(35.276 55.762)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.241 14.46c2.681-1.168 4.27.153 5.058-2.64.498-1.758.056-7.574.622-9.313C6.58.475 8.184-.319 10.27.222c1.41.365 5.893-1.132 6.189 1.094.22 1.647-.7 5.253-2.107 6.156-1.406.905-5.17-4.916-5.17-3.255 0 1.874.388 10.643.192 12.263-.444 3.67-4.333 2.565-7.219 3.067" transform="translate(.6 .8) translate(45.517 18.703)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M.241 14.46c2.681-1.168 4.27.153 5.058-2.64.498-1.758.056-7.574.622-9.313C6.58.475 8.184-.319 10.27.222c1.41.365 5.893-1.132 6.189 1.094.22 1.647-.7 5.253-2.107 6.156-1.406.905-5.17-4.916-5.17-3.255 0 1.874.388 10.643.192 12.263-.444 3.67-4.333 2.565-7.219 3.067" transform="translate(.6 .8) translate(45.517 18.703)"/>
        </g>
        <path fill="#FEFEFE" d="M41.822 22.911c1.67.311 2.224.697 3.005.736 1.508.078.007-1.344 2.135-1.805 1.678-.363 1.228-4.81-.066-5.499-1.293-.689-4.22-3.38-6.481-3.973-1.653-.433-2.961-2.048-5.422-3.239-1.33-.642-2.796-1.96-7.05-1.196-1.649.297-5.66.089-6.06 8.858-.076 1.667 2.495 5.01-2.405 16.443-2.85 6.649.41 14.106-.646 16.045-3.348 6.15-3.11 16.757 4.339 17.979 2.164.355 2.189 1.477 2.926 3.522.575 1.596.9 4.65-.77 6.06-3.286 2.772 2.854 9.776 3.524 10.953.608 1.068-1.253 3.527-.204 4.178.516.32 5.75.209 7.636.255 1.413.035 1.763-4.043.173-4.73-2.382-1.028-3.293.194-5.411-1.503-1.761-1.412-1.359-7.12.269-7.958 2.306-1.189 5.516-2.363 6.587-5.021.908-2.255.6-4.919-.083-7.19-.995-3.302-4.598-1.699-2.825-7.292 1.202-3.795 3.762-5.12 6.64-7.895 2.876-2.775 5.996-5.688 6.916-9.561.75-3.159.292-7.23-2.432-9.033-5.922-3.915-5.72-9.399-4.295-9.134" transform="translate(.6 .8)"/>
        <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M41.822 22.911c1.67.311 2.224.697 3.005.736 1.508.078.007-1.344 2.135-1.805 1.678-.363 1.228-4.81-.066-5.499-1.293-.689-4.22-3.38-6.481-3.973-1.653-.433-2.961-2.048-5.422-3.239-1.33-.642-2.796-1.96-7.05-1.196-1.649.297-5.66.089-6.06 8.858-.076 1.667 2.495 5.01-2.405 16.443-2.85 6.649.41 14.106-.646 16.045-3.348 6.15-3.11 16.757 4.339 17.979 2.164.355 2.189 1.477 2.926 3.522.575 1.596.9 4.65-.77 6.06-3.286 2.772 2.854 9.776 3.524 10.953.608 1.068-1.253 3.527-.204 4.178.516.32 5.75.209 7.636.255 1.413.035 1.763-4.043.173-4.73-2.382-1.028-3.293.194-5.411-1.503-1.761-1.412-1.359-7.12.269-7.958 2.306-1.189 5.516-2.363 6.587-5.021.908-2.255.6-4.919-.083-7.19-.995-3.302-4.598-1.699-2.825-7.292 1.202-3.795 3.762-5.12 6.64-7.895 2.876-2.775 5.996-5.688 6.916-9.561.75-3.159.292-7.23-2.432-9.033-5.922-3.915-5.72-9.399-4.295-9.134z" transform="translate(.6 .8)"/>
        <g>
          <path fill="#FEFEFE" d="M9.507.325c3.809 1.198 1.412 5.166 2.765 7.968 1.013 2.098 1.688 3.076 4.018 2.799 2.255-.267 6.205-1.844 8.114-2.902 1.16-.642 2.198 1.224 4.33 2.255 1.557.753 4.342.43 4.988 1.485.68 1.113-3.472 6.138-5.86 6.07-1.712-.05-2.473-5.295-3.592-5.13-6.683.988-4.255 3.58-8.18 4.742-5.12 1.515-6.41-.38-8.289-2.81C6.291 12.85 4.67 7.553.928 10.45" transform="translate(.6 .8) translate(31.862 34.479)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M9.507.325c3.809 1.198 1.412 5.166 2.765 7.968 1.013 2.098 1.688 3.076 4.018 2.799 2.255-.267 6.205-1.844 8.114-2.902 1.16-.642 2.198 1.224 4.33 2.255 1.557.753 4.342.43 4.988 1.485.68 1.113-3.472 6.138-5.86 6.07-1.712-.05-2.473-5.295-3.592-5.13-6.683.988-4.255 3.58-8.18 4.742-5.12 1.515-6.41-.38-8.289-2.81C6.291 12.85 4.67 7.553.928 10.45" transform="translate(.6 .8) translate(31.862 34.479)"/>
        </g>
        <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M36.972 14.736c0 .54-.444.98-.99.98-.547 0-.99-.44-.99-.98 0-.541.443-.98.99-.98.546 0 .99.439.99.98z" transform="translate(.6 .8)"/>
        <g>
          <path fill="#FEFEFE" d="M2.042.804S.422.538.263 1.244C.06 2.15 2.317 2.281 1.918 3.291" transform="translate(.6 .8) translate(43.241 16.45)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M2.042.804S.422.538.263 1.244C.06 2.15 2.317 2.281 1.918 3.291" transform="translate(.6 .8) translate(43.241 16.45)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M1.584.231S3.742 2.86.84 5.398" transform="translate(.6 .8) translate(55.759 18.703)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M1.584.231S3.742 2.86.84 5.398" transform="translate(.6 .8) translate(55.759 18.703)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M4.278.522S3.578 4.266.58 4.66" transform="translate(.6 .8) translate(56.897 44.62)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M4.278.522S3.578 4.266.58 4.66" transform="translate(.6 .8) translate(56.897 44.62)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M1.939.069S-.562 3.518.184 4.91" transform="translate(.6 .8) translate(50.069 72.664)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M1.939.069S-.562 3.518.184 4.91" transform="translate(.6 .8) translate(50.069 72.664)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M4.342.582S1.672.889.514 2.567" transform="translate(.6 .8) translate(28.448 86.313)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M4.342.582S1.672.889.514 2.567" transform="translate(.6 .8) translate(28.448 86.313)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.353.691C1.45 4.05 8.235-.419 10.266.828" transform="translate(.6 .8) translate(31.862 22.084)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M.353.691C1.45 4.05 8.235-.419 10.266.828" transform="translate(.6 .8) translate(31.862 22.084)"/>
        </g>
        <path fill="#FEFEFE" d="M28.404 11.346c-2.211 1.136-16.658 4.149-11.987-6.47 0 0 1.33-2.14.93.033-.398 2.173-.314 3.977 3.858 4.148 4.173.173 5.515-.24 6.718.923.393.381.805 1.2.48 1.366" transform="translate(.6 .8)"/>
        <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M28.404 11.346c-2.211 1.136-16.658 4.149-11.987-6.47 0 0 1.33-2.14.93.033-.398 2.173-.314 3.977 3.858 4.148 4.173.173 5.515-.24 6.718.923.393.381.805 1.2.48 1.366z" transform="translate(.6 .8)"/>
        <g>
          <path fill="#FEFEFE" d="M10.067 1.22c-2.27-1.915-6.225.404-8.538 1.122-3.638 1.127 3.199 3.531 3.937 3.703.738.17 4.388-.353 5.237-1.879" transform="translate(.6 .8) translate(18.207 10.815)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M10.067 1.22c-2.27-1.915-6.225.404-8.538 1.122-3.638 1.127 3.199 3.531 3.937 3.703.738.17 4.388-.353 5.237-1.879" transform="translate(.6 .8) translate(18.207 10.815)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.412 1.21s2.46.15 4.312-1.12" transform="translate(.6 .8) translate(22.759 13.069)"/>
          <path stroke="#BD3426" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M.412 1.21s2.46.15 4.312-1.12" transform="translate(.6 .8) translate(22.759 13.069)"/>
        </g>
      </g>
    </svg>
    <SelectedSvg id="selected" width="67" height="94" viewBox="0 0 67 94">
      <g fill="none" fillRule="evenodd">
        <path fill="#FEFEFE" d="M19.88 56.594s-8.646 9.118-11.14 2.795c-2.475-6.273 12.872-14.642 2.697-22.179.206-.648.324-1.356.374-2.052.448-6.199-13.125-6.613-10.658-5.014 1.83 1.186 4.392 2.309 3.825 4.515-.898 3.486.366 5.09 2.667 5.09 1.076 0 1.798.021 2.324-.772 1.144 1.075 3.457 2.888 1.777 6.48-1.113 2.381-8.199 9.804-5.405 15.564 2.674 5.513 8.756 2.642 14.675-1.34l-1.136-3.087z" transform="translate(.4 .6)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.704" d="M19.88 56.594s-8.646 9.118-11.14 2.795c-2.475-6.273 12.872-14.642 2.697-22.179.206-.648.324-1.356.374-2.052.448-6.199-13.125-6.613-10.658-5.014 1.83 1.186 4.392 2.309 3.825 4.515-.898 3.486.366 5.09 2.667 5.09 1.076 0 1.798.021 2.324-.772 1.144 1.075 3.457 2.888 1.777 6.48-1.113 2.381-8.199 9.804-5.405 15.564 2.674 5.513 8.756 2.642 14.675-1.34l-1.136-3.087z" transform="translate(.4 .6)"/>
        <path fill="#FEFEFE" d="M29.897 12.622c2.49-.041 16.648-4.19 7.442-11.334 0 0-2.197-1.258-.803.466 1.394 1.726 2.186 3.352-1.404 5.467-3.589 2.114-4.968 2.382-5.467 3.972-.164.521-.133 1.434.232 1.429" transform="translate(.4 .6)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.704" d="M29.897 12.622c2.49-.041 16.648-4.19 7.442-11.334 0 0-2.197-1.258-.803.466 1.394 1.726 2.186 3.352-1.404 5.467-3.589 2.114-4.968 2.382-5.467 3.972-.164.521-.133 1.434.232 1.429z" transform="translate(.4 .6)"/>
        <g>
          <path fill="#FEFEFE" d="M.2.264C1.818-.07 9.484-.412 12 2.651c2.743 3.34-.951 11.395-.329 12.25.4.552 2.561 1.532 3.201 1.777 2.476.947 4.19-.79 6.677.565 1.79.975-5.207 9.653-6.105 5.208-.284-1.405-5.236-2.257-6.426-3.074-1.112-.765-.398-9.228-2.626-7.88-1.555.944-4.374 1.476-6.193 1.328" transform="translate(.4 .6) translate(35.276 55.762)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.704" d="M.2.264C1.818-.07 9.484-.412 12 2.651c2.743 3.34-.951 11.395-.329 12.25.4.552 2.561 1.532 3.201 1.777 2.476.947 4.19-.79 6.677.565 1.79.975-5.207 9.653-6.105 5.208-.284-1.405-5.236-2.257-6.426-3.074-1.112-.765-.398-9.228-2.626-7.88-1.555.944-4.374 1.476-6.193 1.328" transform="translate(.4 .6) translate(35.276 55.762)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.241 14.46c2.681-1.168 4.27.154 5.058-2.64.498-1.758.056-7.574.622-9.313C6.58.475 8.184-.319 10.27.222c1.41.366 5.893-1.133 6.189 1.094.22 1.646-.7 5.253-2.106 6.157-1.407.904-5.171-4.917-5.171-3.257 0 1.876.388 10.645.192 12.265-.444 3.67-4.333 2.563-7.219 3.067" transform="translate(.4 .6) translate(45.517 18.703)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.704" d="M.241 14.46c2.681-1.168 4.27.154 5.058-2.64.498-1.758.056-7.574.622-9.313C6.58.475 8.184-.319 10.27.222c1.41.366 5.893-1.133 6.189 1.094.22 1.646-.7 5.253-2.106 6.157-1.407.904-5.171-4.917-5.171-3.257 0 1.876.388 10.645.192 12.265-.444 3.67-4.333 2.563-7.219 3.067" transform="translate(.4 .6) translate(45.517 18.703)"/>
        </g>
        <path fill="#FEFEFE" d="M41.822 22.911c1.67.31 2.224.697 3.005.736 1.508.078.007-1.345 2.137-1.806 1.676-.363 1.226-4.808-.068-5.498-1.293-.688-4.22-3.38-6.481-3.973-1.653-.434-2.961-2.049-5.422-3.239-1.33-.643-2.796-1.96-7.05-1.196-1.649.296-5.66.088-6.06 8.858-.076 1.668 2.495 5.009-2.405 16.444-2.85 6.648.41 14.105-.646 16.045-3.348 6.15-3.11 16.755 4.339 17.977 2.164.356 2.189 1.478 2.926 3.524.575 1.595.9 4.649-.77 6.057-3.286 2.774 2.854 9.777 3.524 10.954.608 1.069-1.253 3.528-.204 4.18.516.319 5.75.208 7.636.255 1.413.034 1.763-4.044.173-4.731-2.382-1.029-3.293.193-5.411-1.504-1.761-1.411-1.359-7.119.269-7.957 2.306-1.189 5.516-2.364 6.587-5.021.908-2.255.6-4.919-.083-7.19-.995-3.303-4.598-1.698-2.825-7.292 1.202-3.795 3.762-5.12 6.64-7.895 2.876-2.775 5.996-5.689 6.916-9.56.75-3.16.292-7.23-2.432-9.033-5.922-3.916-5.72-9.4-4.295-9.135" transform="translate(.4 .6)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.704" d="M41.822 22.911c1.67.31 2.224.697 3.005.736 1.508.078.007-1.345 2.137-1.806 1.676-.363 1.226-4.808-.068-5.498-1.293-.688-4.22-3.38-6.481-3.973-1.653-.434-2.961-2.049-5.422-3.239-1.33-.643-2.796-1.96-7.05-1.196-1.649.296-5.66.088-6.06 8.858-.076 1.668 2.495 5.009-2.405 16.444-2.85 6.648.41 14.105-.646 16.045-3.348 6.15-3.11 16.755 4.339 17.977 2.164.356 2.189 1.478 2.926 3.524.575 1.595.9 4.649-.77 6.057-3.286 2.774 2.854 9.777 3.524 10.954.608 1.069-1.253 3.528-.204 4.18.516.319 5.75.208 7.636.255 1.413.034 1.763-4.044.173-4.731-2.382-1.029-3.293.193-5.411-1.504-1.761-1.411-1.359-7.119.269-7.957 2.306-1.189 5.516-2.364 6.587-5.021.908-2.255.6-4.919-.083-7.19-.995-3.303-4.598-1.698-2.825-7.292 1.202-3.795 3.762-5.12 6.64-7.895 2.876-2.775 5.996-5.689 6.916-9.56.75-3.16.292-7.23-2.432-9.033-5.922-3.916-5.72-9.4-4.295-9.135z" transform="translate(.4 .6)"/>
        <g>
          <path fill="#FEFEFE" d="M9.507.325c3.809 1.197 1.412 5.167 2.765 7.968 1.013 2.097 1.688 3.076 4.018 2.799 2.255-.268 6.205-1.844 8.114-2.902 1.16-.643 2.198 1.224 4.33 2.255 1.557.753 4.342.43 4.988 1.485.68 1.114-3.472 6.138-5.86 6.07-1.712-.049-2.473-5.296-3.592-5.13-6.683.988-4.255 3.581-8.18 4.742-5.12 1.514-6.41-.38-8.289-2.81C6.291 12.85 4.67 7.555.928 10.45" transform="translate(.4 .6) translate(31.862 34.479)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.704" d="M9.507.325c3.809 1.197 1.412 5.167 2.765 7.968 1.013 2.097 1.688 3.076 4.018 2.799 2.255-.268 6.205-1.844 8.114-2.902 1.16-.643 2.198 1.224 4.33 2.255 1.557.753 4.342.43 4.988 1.485.68 1.114-3.472 6.138-5.86 6.07-1.712-.049-2.473-5.296-3.592-5.13-6.683.988-4.255 3.581-8.18 4.742-5.12 1.514-6.41-.38-8.289-2.81C6.291 12.85 4.67 7.555.928 10.45" transform="translate(.4 .6) translate(31.862 34.479)"/>
        </g>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.704" d="M36.972 14.735c0 .541-.444.98-.99.98-.547 0-.99-.439-.99-.98 0-.54.443-.98.99-.98.546 0 .99.44.99.98z" transform="translate(.4 .6)"/>
        <g>
          <path fill="#FEFEFE" d="M2.042.804S.422.539.263 1.244C.06 2.15 2.317 2.281 1.918 3.291" transform="translate(.4 .6) translate(43.241 16.45)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.704" d="M2.042.804S.422.539.263 1.244C.06 2.15 2.317 2.281 1.918 3.291" transform="translate(.4 .6) translate(43.241 16.45)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M1.584.231S3.742 2.859.84 5.398" transform="translate(.4 .6) translate(55.759 18.703)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.704" d="M1.584.231S3.742 2.859.84 5.398" transform="translate(.4 .6) translate(55.759 18.703)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M4.278.522S3.578 4.265.58 4.661" transform="translate(.4 .6) translate(56.897 44.62)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.704" d="M4.278.522S3.578 4.265.58 4.661" transform="translate(.4 .6) translate(56.897 44.62)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M1.939.069S-.562 3.519.184 4.91" transform="translate(.4 .6) translate(50.069 72.664)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.704" d="M1.939.069S-.562 3.519.184 4.91" transform="translate(.4 .6) translate(50.069 72.664)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M4.342.581S1.672.89.514 2.567" transform="translate(.4 .6) translate(28.448 86.313)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.704" d="M4.342.581S1.672.89.514 2.567" transform="translate(.4 .6) translate(28.448 86.313)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.353.691C1.45 4.05 8.235-.419 10.266.828" transform="translate(.4 .6) translate(31.862 22.084)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.704" d="M.353.691C1.45 4.05 8.235-.419 10.266.828" transform="translate(.4 .6) translate(31.862 22.084)"/>
        </g>
        <path fill="#FEFEFE" d="M28.404 11.346c-2.211 1.136-16.658 4.15-11.987-6.47 0 0 1.33-2.141.93.033-.398 2.173-.314 3.976 3.858 4.149 4.173.172 5.515-.242 6.718.921.393.382.805 1.2.48 1.367" transform="translate(.4 .6)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.704" d="M28.404 11.346c-2.211 1.136-16.658 4.15-11.987-6.47 0 0 1.33-2.141.93.033-.398 2.173-.314 3.976 3.858 4.149 4.173.172 5.515-.242 6.718.921.393.382.805 1.2.48 1.367z" transform="translate(.4 .6)"/>
        <g>
          <path fill="#FEFEFE" d="M10.067 1.22c-2.27-1.915-6.225.404-8.538 1.12-3.638 1.129 3.199 3.533 3.937 3.705.739.171 4.388-.353 5.237-1.879" transform="translate(.4 .6) translate(18.207 10.815)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.704" d="M10.067 1.22c-2.27-1.915-6.225.404-8.538 1.12-3.638 1.129 3.199 3.533 3.937 3.705.739.171 4.388-.353 5.237-1.879" transform="translate(.4 .6) translate(18.207 10.815)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.412 1.21s2.46.15 4.312-1.12" transform="translate(.4 .6) translate(22.759 13.069)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.704" d="M.412 1.21s2.46.15 4.312-1.12" transform="translate(.4 .6) translate(22.759 13.069)"/>
        </g>
      </g>
    </SelectedSvg>
  </Container>
)

export default ox
