import React from 'react'
import { Container, SelectedSvg } from '../styles'

const lion = () => (
  <Container>
    <svg width="68" height="94" viewBox="0 0 68 94">
      <g fill="none" fillRule="evenodd">
        <path fill="#FEFEFE" d="M19.898 56.45s-8.655 9.118-11.15 2.796c-2.477-6.274 12.884-14.642 2.7-22.181.204-.647.323-1.354.374-2.052C12.271 28.814-1.315 28.4 1.154 30c1.83 1.185 4.397 2.309 3.828 4.515-.898 3.486.367 5.09 2.669 5.09 1.079 0 1.8.02 2.327-.772 1.146 1.075 3.46 2.888 1.779 6.48-1.114 2.38-8.206 9.804-5.41 15.563 2.676 5.514 8.764 2.642 14.688-1.34l-1.137-3.086z" transform="translate(.4)"/>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M19.898 56.45s-8.655 9.118-11.15 2.796c-2.477-6.274 12.884-14.642 2.7-22.181.204-.647.323-1.354.374-2.052C12.271 28.814-1.315 28.4 1.154 30c1.83 1.185 4.397 2.309 3.828 4.515-.898 3.486.367 5.09 2.669 5.09 1.079 0 1.8.02 2.327-.772 1.146 1.075 3.46 2.888 1.779 6.48-1.114 2.38-8.206 9.804-5.41 15.563 2.676 5.514 8.764 2.642 14.688-1.34l-1.137-3.086z" transform="translate(.4)"/>
        <g>
          <path fill="#FEFEFE" d="M.17.85C1.79.516 9.464 1.54 11.98 4.602c2.746 3.34-.952 11.395-.33 12.25.402.552 2.565 1.532 3.205 1.776 2.478.948 4.194-.79 6.683.566 1.79.975 2.835 4.637.205 5.164-3.161.874-6.456.742-7.155-1.12-.504-1.343-4.402-1.093-5.59-1.91-1.116-.765-.4-9.23-2.63-7.879-1.558.944-4.38 1.474-6.2 1.327" transform="translate(.4) translate(35.308 55.996)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M.17.85C1.79.516 9.464 1.54 11.98 4.602c2.746 3.34-.952 11.395-.33 12.25.402.552 2.565 1.532 3.205 1.776 2.478.948 4.194-.79 6.683.566 1.79.975 2.835 4.637.205 5.164-3.161.874-6.456.742-7.155-1.12-.504-1.343-4.402-1.093-5.59-1.91-1.116-.765-.4-9.23-2.63-7.879-1.558.944-4.38 1.474-6.2 1.327" transform="translate(.4) translate(35.308 55.996)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.212 16.203c3.016-1.311 4.803.173 5.691-2.966.558-1.977.063-8.514.698-10.468C7.346.485 9.148-.407 11.494.2c1.587.411 5.957 1.202 6.291 3.705.246 1.85-.115 3.429-1.698 4.445C14.505 9.366 9.73 5.252 9.73 7.118c0 2.107 1.985 10.523 1.764 12.345-.498 4.124-4.997 3.56-8.245 4.124" transform="translate(.4) translate(45.56 16.43)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M.212 16.203c3.016-1.311 4.803.173 5.691-2.966.558-1.977.063-8.514.698-10.468C7.346.485 9.148-.407 11.494.2c1.587.411 5.957 1.202 6.291 3.705.246 1.85-.115 3.429-1.698 4.445C14.505 9.366 9.73 5.252 9.73 7.118c0 2.107 1.985 10.523 1.764 12.345-.498 4.124-4.997 3.56-8.245 4.124" transform="translate(.4) translate(45.56 16.43)"/>
        </g>
        <path fill="#FEFEFE" d="M38.25 22.821c.023-1.093 3.016-.927 4.246-.864 1.51.078 1.627.249 2.276-1.103.632-1.314 1.362-4.061.067-4.75-1.296-.69-2.485-.684-3.461-1.772-1.703-1.896-.899-2.96-2.996-4.419-1.143-.794-4.928-1.817-9.186-1.053-1.65.297-6.922-.735-7.322 8.034-.075 1.668-1.56 5.52.798 6.936 1.659.996 4.554 1.709 6.225 3.345 1.12 1.097.16 3.53.16 4.689 0 1.484-1.126 9.133-3.522 12.031-2.267 2.74-4.79 4.471-6.444 7.507-3.35 6.15-1.158 14.32 3.841 19.255 1.554 1.534 3.449 2.802 4.522 4.696 1.074 1.894.9 4.757-1.066 5.72-1.085.531-2.553.425-3.286 1.378-.827 1.076-.041 2.659 1.007 3.527 1.047.866 2.387 1.472 3.058 2.65.608 1.07.638 2.553 1.687 3.205.517.319 1.158.343 1.766.358l5.667.14c1.414.034 3.063-.04 3.893-1.174 1.017-1.392-.058-3.498-1.65-4.184-2.383-1.03-5.366.82-7.486-.877-1.762-1.41-.41-3.579 1.219-4.417 2.31-1.189 4.571-1.162 5.643-3.82.91-2.255.602-4.919-.083-7.19-.995-3.302-2.686-6.555-2.369-9.988.366-3.961 7.436-5.52 10.317-8.295 2.88-2.776 3.773-7.094 4.694-10.967.75-3.158-2.095-6.901-4.335-9.271-1.92-2.03-8.02-3.07-7.88-9.327" transform="translate(.4)"/>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M38.25 22.821c.023-1.093 3.016-.927 4.246-.864 1.51.078 1.627.249 2.276-1.103.632-1.314 1.362-4.061.067-4.75-1.296-.69-2.485-.684-3.461-1.772-1.703-1.896-.899-2.96-2.996-4.419-1.143-.794-4.928-1.817-9.186-1.053-1.65.297-6.922-.735-7.322 8.034-.075 1.668-1.56 5.52.798 6.936 1.659.996 4.554 1.709 6.225 3.345 1.12 1.097.16 3.53.16 4.689 0 1.484-1.126 9.133-3.522 12.031-2.267 2.74-4.79 4.471-6.444 7.507-3.35 6.15-1.158 14.32 3.841 19.255 1.554 1.534 3.449 2.802 4.522 4.696 1.074 1.894.9 4.757-1.066 5.72-1.085.531-2.553.425-3.286 1.378-.827 1.076-.041 2.659 1.007 3.527 1.047.866 2.387 1.472 3.058 2.65.608 1.07.638 2.553 1.687 3.205.517.319 1.158.343 1.766.358l5.667.14c1.414.034 3.063-.04 3.893-1.174 1.017-1.392-.058-3.498-1.65-4.184-2.383-1.03-5.366.82-7.486-.877-1.762-1.41-.41-3.579 1.219-4.417 2.31-1.189 4.571-1.162 5.643-3.82.91-2.255.602-4.919-.083-7.19-.995-3.302-2.686-6.555-2.369-9.988.366-3.961 7.436-5.52 10.317-8.295 2.88-2.776 3.773-7.094 4.694-10.967.75-3.158-2.095-6.901-4.335-9.271-1.92-2.03-8.02-3.07-7.88-9.327z" transform="translate(.4)"/>
        <g>
          <path fill="#FEFEFE" d="M7.112.136c3.784.69 1.978 4.764 3.61 7.277 1.223 1.88 1.986 2.736 4.179 2.194 2.123-.524 5.705-2.499 7.4-3.736 1.784-1.3 6.656 1.214 8.421 3.42.785.982-1.636 5.313-3.926 5.533-1.641.157-3.006-4.76-4.053-4.47-6.267 1.738-3.671 5.402-7.279 6.976-4.708 2.055-6.945.073-9.036-2.023-1.68-1.684-3.06-7.696-6.283-4.486" transform="translate(.4) translate(35.308 35.713)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M7.112.136c3.784.69 1.978 4.764 3.61 7.277 1.223 1.88 1.986 2.736 4.179 2.194 2.123-.524 5.705-2.499 7.4-3.736 1.784-1.3 6.656 1.214 8.421 3.42.785.982-1.636 5.313-3.926 5.533-1.641.157-3.006-4.76-4.053-4.47-6.267 1.738-3.671 5.402-7.279 6.976-4.708 2.055-6.945.073-9.036-2.023-1.68-1.684-3.06-7.696-6.283-4.486" transform="translate(.4) translate(35.308 35.713)"/>
        </g>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M36.977 14.838c0 .54-.445.98-.991.98-.547 0-.991-.44-.991-.98 0-.541.444-.98.99-.98.547 0 .992.439.992.98z" transform="translate(.4)"/>
        <path fill="#FEFEFE" d="M39.922 11.705s-.77-1.323-.408-2.318c.957-2.63-1.918-5.415-5.326-7.507 0 0-4.608-2.328-3.061 0 1.521 2.29-1.483-.208-3.794-.594-3.13-.522-6.54.561-11.45 4.544-5.227 4.242-6.456 9.286-6.456 15.543 0 .39 1.471-.779 1.49-.395.255 5.398 2.428 10.966 5.632 12.512 8.054 3.885 6.361 13.21 6.361 13.21s4.725-.493 8.184-4.189c4.16-4.444 3.9-13.898 13.574-11.551 2.48.602-2.625-9.811-12.676-6.98-8.846 2.492-9.852-11.3-6.058-12.551.508-.168 4.015 1.835 5.991-.922 2.596-3.623 6.968 1.846 7.997 1.198" transform="translate(.4)"/>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M39.922 11.705s-.77-1.323-.408-2.318c.957-2.63-1.918-5.415-5.326-7.507 0 0-4.608-2.328-3.061 0 1.521 2.29-1.483-.208-3.794-.594-3.13-.522-6.54.561-11.45 4.544-5.227 4.242-6.456 9.286-6.456 15.543 0 .39 1.471-.779 1.49-.395.255 5.398 2.428 10.966 5.632 12.512 8.054 3.885 6.361 13.21 6.361 13.21s4.725-.493 8.184-4.189c4.16-4.444 3.9-13.898 13.574-11.551 2.48.602-2.625-9.811-12.676-6.98-8.846 2.492-9.852-11.3-6.058-12.551.508-.168 4.015 1.835 5.991-.922 2.596-3.623 6.968 1.846 7.997 1.198z" transform="translate(.4)"/>
        <g>
          <path fill="#FEFEFE" d="M5.594 7.416C4.501 8.141-.957 5.021.848 2.158 2.97-1.205 6.631.38 7.563 3.475" transform="translate(.4) translate(20.502 9.796)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M5.594 7.416C4.501 8.141-.957 5.021.848 2.158 2.97-1.205 6.631.38 7.563 3.475" transform="translate(.4) translate(20.502 9.796)"/>
        </g>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M45.295 17.355s-2.76-.704-2.919 0c-.204.909 3.195 1.479 2.796 2.488" transform="translate(.4)"/>
        <g>
          <path fill="#FEFEFE" d="M2.282 2.788S3.052.995.085.827" transform="translate(.4) translate(23.919 12.05)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M2.282 2.788S3.052.995.085.827" transform="translate(.4) translate(23.919 12.05)"/>
        </g>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M38.94 22.297s-6.53.383-8.527-1.274" transform="translate(.4)"/>
      </g>
    </svg>
    <SelectedSvg id="selected" width="68" height="94" viewBox="0 0 68 94">
      <g fill="none" fillRule="evenodd">
        <path fill="#FEFEFE" d="M19.898 56.45s-8.654 9.118-11.15 2.795c-2.478-6.273 12.884-14.642 2.7-22.18.205-.647.324-1.355.374-2.052.45-6.198-13.137-6.612-10.668-5.013 1.832 1.185 4.398 2.309 3.828 4.515-.899 3.486.367 5.09 2.67 5.09 1.077 0 1.8.02 2.326-.772 1.147 1.075 3.46 2.888 1.779 6.48-1.114 2.381-8.207 9.804-5.41 15.564 2.676 5.513 8.765 2.642 14.69-1.34l-1.139-3.087z" transform="translate(.4 .2)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M19.898 56.45s-8.654 9.118-11.15 2.795c-2.478-6.273 12.884-14.642 2.7-22.18.205-.647.324-1.355.374-2.052.45-6.198-13.137-6.612-10.668-5.013 1.832 1.185 4.398 2.309 3.828 4.515-.899 3.486.367 5.09 2.67 5.09 1.077 0 1.8.02 2.326-.772 1.147 1.075 3.46 2.888 1.779 6.48-1.114 2.381-8.207 9.804-5.41 15.564 2.676 5.513 8.765 2.642 14.69-1.34l-1.139-3.087z" transform="translate(.4 .2)"/>
        <g>
          <path fill="#FEFEFE" d="M.17.85c1.62-.334 9.294.69 11.811 3.752 2.747 3.341-.952 11.396-.329 12.251.401.55 2.564 1.531 3.204 1.776 2.479.946 4.193-.79 6.684.564 1.79.976 2.834 4.638.205 5.165-3.162.873-6.457.741-7.155-1.12-.505-1.342-4.402-1.092-5.593-1.91-1.114-.764-.398-9.23-2.628-7.88-1.557.944-4.379 1.476-6.199 1.328" transform="translate(.4 .2) translate(35.308 55.996)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M.17.85c1.62-.334 9.294.69 11.811 3.752 2.747 3.341-.952 11.396-.329 12.251.401.55 2.564 1.531 3.204 1.776 2.479.946 4.193-.79 6.684.564 1.79.976 2.834 4.638.205 5.165-3.162.873-6.457.741-7.155-1.12-.505-1.342-4.402-1.092-5.593-1.91-1.114-.764-.398-9.23-2.628-7.88-1.557.944-4.379 1.476-6.199 1.328" transform="translate(.4 .2) translate(35.308 55.996)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.211 16.203c3.016-1.312 4.805.174 5.692-2.966.559-1.977.062-8.514.7-10.468C7.345.485 9.147-.408 11.493.199c1.587.413 5.958 1.203 6.29 3.706.247 1.85-.114 3.429-1.698 4.445C14.504 9.367 9.73 5.251 9.73 7.117c0 2.108 1.985 10.524 1.764 12.346-.499 4.125-4.998 3.56-8.244 4.125" transform="translate(.4 .2) translate(45.56 16.43)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M.211 16.203c3.016-1.312 4.805.174 5.692-2.966.559-1.977.062-8.514.7-10.468C7.345.485 9.147-.408 11.493.199c1.587.413 5.958 1.203 6.29 3.706.247 1.85-.114 3.429-1.698 4.445C14.504 9.367 9.73 5.251 9.73 7.117c0 2.108 1.985 10.524 1.764 12.346-.499 4.125-4.998 3.56-8.244 4.125" transform="translate(.4 .2) translate(45.56 16.43)"/>
        </g>
        <path fill="#FEFEFE" d="M38.249 22.821c.024-1.093 3.017-.927 4.247-.864 1.51.077 1.626.249 2.277-1.103.63-1.314 1.36-4.062.066-4.75-1.295-.69-2.485-.684-3.461-1.771-1.703-1.897-.9-2.96-2.996-4.42-1.142-.794-4.928-1.817-9.186-1.053-1.65.297-6.923-.735-7.321 8.034-.077 1.669-1.56 5.52.798 6.937 1.659.995 4.553 1.708 6.224 3.344 1.12 1.096.159 3.53.159 4.689 0 1.484-1.125 9.133-3.52 12.031-2.267 2.74-4.791 4.471-6.445 7.507-3.35 6.15-1.158 14.32 3.84 19.255 1.555 1.534 3.45 2.801 4.524 4.697 1.074 1.893.9 4.756-1.068 5.72-1.084.53-2.552.424-3.284 1.377-.828 1.076-.041 2.659 1.005 3.527 1.048.866 2.388 1.473 3.058 2.65.609 1.07.638 2.553 1.688 3.205.516.318 1.158.343 1.766.358l5.666.14c1.415.033 3.064-.04 3.893-1.175 1.017-1.391-.057-3.497-1.649-4.183-2.384-1.03-5.367.82-7.486-.877-1.762-1.41-.41-3.579 1.218-4.417 2.31-1.19 4.573-1.162 5.644-3.82.91-2.255.601-4.919-.083-7.19-.996-3.302-2.686-6.555-2.37-9.988.366-3.962 7.437-5.519 10.318-8.295 2.88-2.776 3.773-7.094 4.693-10.966.75-3.158-2.093-6.903-4.335-9.272-1.919-2.03-8.02-3.07-7.88-9.327" transform="translate(.4 .2)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M38.249 22.821c.024-1.093 3.017-.927 4.247-.864 1.51.077 1.626.249 2.277-1.103.63-1.314 1.36-4.062.066-4.75-1.295-.69-2.485-.684-3.461-1.771-1.703-1.897-.9-2.96-2.996-4.42-1.142-.794-4.928-1.817-9.186-1.053-1.65.297-6.923-.735-7.321 8.034-.077 1.669-1.56 5.52.798 6.937 1.659.995 4.553 1.708 6.224 3.344 1.12 1.096.159 3.53.159 4.689 0 1.484-1.125 9.133-3.52 12.031-2.267 2.74-4.791 4.471-6.445 7.507-3.35 6.15-1.158 14.32 3.84 19.255 1.555 1.534 3.45 2.801 4.524 4.697 1.074 1.893.9 4.756-1.068 5.72-1.084.53-2.552.424-3.284 1.377-.828 1.076-.041 2.659 1.005 3.527 1.048.866 2.388 1.473 3.058 2.65.609 1.07.638 2.553 1.688 3.205.516.318 1.158.343 1.766.358l5.666.14c1.415.033 3.064-.04 3.893-1.175 1.017-1.391-.057-3.497-1.649-4.183-2.384-1.03-5.367.82-7.486-.877-1.762-1.41-.41-3.579 1.218-4.417 2.31-1.19 4.573-1.162 5.644-3.82.91-2.255.601-4.919-.083-7.19-.996-3.302-2.686-6.555-2.37-9.988.366-3.962 7.437-5.519 10.318-8.295 2.88-2.776 3.773-7.094 4.693-10.966.75-3.158-2.093-6.903-4.335-9.272-1.919-2.03-8.02-3.07-7.88-9.327z" transform="translate(.4 .2)"/>
        <g>
          <path fill="#FEFEFE" d="M7.112.136c3.784.69 1.978 4.764 3.61 7.277 1.223 1.88 1.986 2.736 4.179 2.194 2.123-.524 5.705-2.499 7.4-3.735 1.783-1.301 6.656 1.213 8.421 3.419.785.982-1.636 5.313-3.926 5.533-1.64.156-3.005-4.76-4.054-4.47-6.266 1.738-3.67 5.402-7.277 6.976-4.709 2.055-6.946.073-9.037-2.023-1.68-1.684-3.06-7.696-6.283-4.486" transform="translate(.4 .2) translate(35.308 35.713)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M7.112.136c3.784.69 1.978 4.764 3.61 7.277 1.223 1.88 1.986 2.736 4.179 2.194 2.123-.524 5.705-2.499 7.4-3.735 1.783-1.301 6.656 1.213 8.421 3.419.785.982-1.636 5.313-3.926 5.533-1.64.156-3.005-4.76-4.054-4.47-6.266 1.738-3.67 5.402-7.277 6.976-4.709 2.055-6.946.073-9.037-2.023-1.68-1.684-3.06-7.696-6.283-4.486" transform="translate(.4 .2) translate(35.308 35.713)"/>
        </g>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M36.976 14.837c0 .541-.443.98-.99.98-.547 0-.99-.439-.99-.98 0-.54.443-.98.99-.98.547 0 .99.44.99.98z" transform="translate(.4 .2)"/>
        <path fill="#FEFEFE" d="M39.922 11.705s-.77-1.324-.408-2.319c.957-2.628-1.918-5.413-5.326-7.507 0 0-4.607-2.327-3.062 0 1.523 2.29-1.483-.206-3.794-.593-3.13-.521-6.54.562-11.449 4.545-5.228 4.24-6.457 9.285-6.457 15.541 0 .391 1.473-.777 1.49-.393.255 5.397 2.43 10.965 5.633 12.511 8.054 3.885 6.362 13.21 6.362 13.21s4.723-.493 8.182-4.189c4.16-4.445 3.902-13.898 13.575-11.55 2.48.601-2.624-9.813-12.676-6.98-8.846 2.491-9.852-11.301-6.057-12.553.507-.167 4.014 1.837 5.99-.922 2.597-3.622 6.968 1.846 7.997 1.2" transform="translate(.4 .2)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M39.922 11.705s-.77-1.324-.408-2.319c.957-2.628-1.918-5.413-5.326-7.507 0 0-4.607-2.327-3.062 0 1.523 2.29-1.483-.206-3.794-.593-3.13-.521-6.54.562-11.449 4.545-5.228 4.24-6.457 9.285-6.457 15.541 0 .391 1.473-.777 1.49-.393.255 5.397 2.43 10.965 5.633 12.511 8.054 3.885 6.362 13.21 6.362 13.21s4.723-.493 8.182-4.189c4.16-4.445 3.902-13.898 13.575-11.55 2.48.601-2.624-9.813-12.676-6.98-8.846 2.491-9.852-11.301-6.057-12.553.507-.167 4.014 1.837 5.99-.922 2.597-3.622 6.968 1.846 7.997 1.2z" transform="translate(.4 .2)"/>
        <g>
          <path fill="#FEFEFE" d="M5.594 7.416C4.501 8.141-.957 5.021.848 2.16 2.97-1.206 6.631.38 7.564 3.476" transform="translate(.4 .2) translate(20.502 9.796)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M5.594 7.416C4.501 8.141-.957 5.021.848 2.16 2.97-1.206 6.631.38 7.564 3.476" transform="translate(.4 .2) translate(20.502 9.796)"/>
        </g>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M45.295 17.355s-2.76-.704-2.92 0c-.203.908 3.196 1.479 2.797 2.488" transform="translate(.4 .2)"/>
        <g>
          <path fill="#FEFEFE" d="M2.282 2.788S3.052.995.085.827" transform="translate(.4 .2) translate(23.919 12.05)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M2.282 2.788S3.052.995.085.827" transform="translate(.4 .2) translate(23.919 12.05)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M9.131 1.28S2.601 1.665.605.008" transform="translate(.4 .2) translate(29.614 20.938)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M9.131 1.28S2.601 1.665.605.008" transform="translate(.4 .2) translate(29.614 20.938)"/>
        </g>
      </g>
    </SelectedSvg>
  </Container>
)

export default lion
