import types from './types'

const setIsFinalCrestDisplay = (value) => ({
  type: types.setIsFinalCrestDisplay,
  value,
})

const setIsLoaderDisplay = (value) => ({
  type: types.setIsLoaderDisplay,
  value,
})

const setIsReceiveMailDisplay = (value) => ({
  type: types.setIsReceiveMailDisplay,
  value,
})

// const setIsPrintModeDisplay = (value) => ({
//   type: types.setIsPrintModeDisplay,
//   value,
// })

const setIsCrestBuilderHidden = () => ({
  type: types.setIsCrestBuilderHidden,
})

const startPrintMode = () => ({
  type: types.startPrintMode,
})

const setCurrentStep = (value) => {
  window.history.pushState({currStep: value}, "", "")
  window.history.pushState({currStep: value}, "", "")
  return ({
    type: types.setCurrentStep,
    value,
  })
}

const setIsError = (value) => ({
  type: types.setIsError,
  value,
})

const setShareFinalCrestImageId = (value) => ({
  type: types.setShareFinalCrestImageId,
  value,
})

export {
  setIsFinalCrestDisplay,
  setIsLoaderDisplay,
  setIsReceiveMailDisplay,
  // setIsPrintModeDisplay,
  setIsCrestBuilderHidden,
  startPrintMode,
  setCurrentStep,
  setIsError,
  setShareFinalCrestImageId,
}
