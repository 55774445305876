import React from 'react'
import { Container, SelectedSvg } from '../styles'

const olive = () => (
  <Container>
    <svg width="49" height="48" viewBox="0 0 49 48">
      <path fill="#47C3D2" fillRule="evenodd" d="M26.787 35.208c-1.003 4.415-4.426 10.104-8.752 10.682-6.036.806-7.372-7.093-6.002-12.568 3.734-14.928 18.398-14.127 14.75 1.9l.004-.014zm-11.65 5.208c0 1.832 3.494 2.543 3.998 1.273.167-1.703-3.27-2.684-3.998-1.273zm.227-2.316c-.947.237-1.528.514-1.856 1.727-.755 2.846 2.977 5.004 5.66 4.036 4.035-1.445.086-6.971-3.812-5.766l.008.003zm22.635-4.013c-.268-2.224 4.678-1.81 4.583-.38-.128 1.67-4.422 1.464-4.583.38zm5.92.695c2.153-4.23-6.177-5.412-7.575-1.86-1.633 4.147 5.805 5.292 7.563 1.865l.011-.005zm-7.045-19.839c-9.24 4.022-4.057 26.922 4.926 23.296 9.213-3.718 4.407-27.372-4.926-23.296zm-6.647 13.422c2.305 17.818 20.86 15.438 18.338-3.74-2.35-17.806-20.798-15.163-18.338 3.74zM3.11 19.505c7.698 1.81 31.341-9.033 32.47-9.15-2.302-.064-17.157.909-19.263 1.423-1.419.345-12.596 7.223-13.204 7.725l-.003.002zM28.247 8.888c-5.852.022-13.14-2.046-16.162-6.728l2.958-.191c4.859-.38 19.036 3.494 21.75 5.873-1.729.976-6.244 1.037-8.544 1.044l-.002.002zm-2.812 12.636c1.374-3.904 5.66-6.903 9.58-8.743 2.127-1.016 4.331-1.859 6.594-2.516 2.871-.833 6.557-.795 5.689-2.582-.585-1.205-4.323.277-6.226.6C35.55 3.46 17.777-2.16 10.047.843c-1.005 4.448 7.031 7.403 8.357 8.562-7.909 1.664-8.316 4.014-14.518 7.322C2.142 17.669.309 18.28.92 20.529c3.773 3.866 21.467-2.973 25.484-4.258-.883 1.638-1.886 2.012-2.743 4.464-6.79-.495-10.703 3.802-12.85 9.85-1.919 5.387-2.074 14.446 3.774 16.868 8.096 3.305 15.774-9.057 14.564-18.45-.162-1.536-.602-3.026-1.303-4.4-.988-1.858-1.513-1.692-2.41-3.078z"/>
    </svg>
    <SelectedSvg id="selected" width="49" height="49" viewBox="0 0 49 49">
      <g fill="none" fillRule="evenodd" transform="translate(.8 .4)">
        <ellipse cx="38.4" cy="27" fill="#FFF" rx="8.4" ry="12.6"/>
        <path fill="#FFF" d="M23.843 8.76c5.186 1.102 12.854 2.268 13.337 0 .482-2.27-8.15-5.156-13.337-6.258-5.186-1.102-11.82-2.269-12.302 0-.483 2.269 7.116 5.155 12.302 6.257z"/>
        <path fill="#FFF" d="M24.174 15.471c4.982-1.813 12.103-4.887 11.31-7.067-.793-2.18-9.644-.053-14.626 1.76-1.559.568-3.99.286-6.65 1.379-5.843 2.4-12.57 6.753-12.025 8.25.793 2.18 17.009-2.508 21.991-4.322z"/>
        <ellipse cx="19.001" cy="34.64" fill="#FFF" rx="8.4" ry="12.6" transform="rotate(14 19 34.64)"/>
        <path fill="#414141" d="M25.987 35.208c-1.003 4.415-4.426 10.104-8.752 10.682-6.036.806-7.372-7.093-6.002-12.568 3.734-14.928 18.398-14.127 14.75 1.9l.004-.014zm-11.65 5.208c0 1.832 3.494 2.543 3.998 1.273.167-1.703-3.27-2.684-3.998-1.273zm.227-2.316c-.947.237-1.528.514-1.856 1.727-.755 2.846 2.977 5.004 5.66 4.036 4.035-1.445.086-6.971-3.812-5.766l.008.003zm22.635-4.013c-.268-2.224 4.678-1.81 4.583-.38-.128 1.67-4.422 1.464-4.583.38zm5.92.695c2.153-4.23-6.177-5.412-7.575-1.86-1.633 4.147 5.805 5.292 7.563 1.865l.011-.005zm-7.045-19.839C26.834 18.965 32.017 41.865 41 38.24c9.213-3.718 4.407-27.372-4.926-23.296zm-6.647 13.422c2.305 17.818 20.86 15.438 18.338-3.74-2.35-17.806-20.798-15.163-18.338 3.74zM2.31 19.505c7.698 1.81 31.341-9.033 32.47-9.15-2.302-.064-17.157.909-19.263 1.423-1.419.345-12.596 7.223-13.204 7.725l-.003.002zM11.285 2.16l2.958-.191c4.859-.38 19.036 3.494 21.75 5.873-1.729.976-6.244 1.037-8.544 1.044-5.854.024-13.142-2.044-16.164-6.726zm22.93 10.62c2.127-1.015 4.331-1.858 6.594-2.515 2.871-.833 6.557-.795 5.689-2.582-.585-1.205-4.323.277-6.226.6C34.75 3.46 16.977-2.16 9.247.843c-1.005 4.448 7.031 7.403 8.357 8.562-7.909 1.664-8.316 4.014-14.518 7.322-1.744.942-3.577 1.554-2.966 3.802 3.773 3.866 21.467-2.973 25.484-4.258-.883 1.638-1.886 2.012-2.743 4.464-6.79-.495-10.703 3.802-12.85 9.85-1.919 5.387-2.074 14.446 3.774 16.868 8.096 3.305 15.774-9.057 14.564-18.45-.162-1.536-.602-3.026-1.303-4.4-.988-1.858-1.513-1.692-2.41-3.078 1.373-3.905 5.659-6.904 9.578-8.744z"/>
      </g>
    </SelectedSvg>
  </Container>
)

export default olive
