import React from 'react'
import { Container, SelectedSvg } from '../styles'

const australia = () => (
  <Container>
    <svg width="93" height="85" viewBox="0 0 93 85">
      <g fill="none" fillRule="evenodd" stroke="#FBB034" strokeWidth="3.6">
        <path d="M74.022 19.895c-.825-.917-1.93-6.694-2.848-9.627-.919-2.933-2.572-9.076-5.234-9.81h-.276c-2.849 0-1.378 14.67-5.234 16.139-.094 0-.277.093-.553.093-2.755 0-13.222-2.199-11.203-5.684 1.654-2.843 2.848-6.418 1.744-6.783-.826-.276-2.939-.458-5.234-.458-4.226 0-9.278.458-9.737 1.375-.735 1.468-1.47 4.86-2.572 6.325-.183.182-.366.275-.553.275-1.284 0-3.766-2.567-5.327-2.567-.276 0-.552.093-.825.365-9.736 10.544-5.417 9.533-11.297 16.503-2.479 2.933-12.032 2.75-13.869 7.428-3.031 8.07 6.335 23.104 8.172 25.49 1.837 2.385-1.654 5.318 2.39 6.142.458.093.918.182 1.377.182 3.58 0 7.347-3.115 15.706-8.344 5.417-3.302 8.448-4.22 10.56-4.22 1.561 0 2.663.46 3.768.824 2.572.735 6.705 5.684 8.082 6.053.093 0 .276.093.366.093 1.101 0 1.837-1.375 2.938-1.375h.366c1.47.365-2.02 1.468 6.705 8.893 1.47 1.282 2.662 1.74 3.673 1.74 1.93 0 3.215-1.65 4.592-2.199.183-.093.366-.093.553-.093 1.284 0 1.836 1.74 3.673 1.74.366 0 .825-.092 1.378-.275 3.397-1.282 5.693-.276 5.786-2.657.093-6.601 8.359-12.013 8.265-25.032.187-8.158-14.507-19.618-15.332-20.535v-.001zM76.59 72.431c-.276-.182-.735-.275-1.101-.275-1.012 0-2.39.458-3.857.458-.825 0-1.743-.093-2.662-.551-.276-.094-.552-.183-.735-.183-2.755 0-.552 9.075 3.856 9.075.277 0 .553 0 .919-.093 5.327-1.461 5.51-7.055 3.58-8.43h0z" transform="translate(1.8 2)"/>
      </g>
    </svg>
    <SelectedSvg id="selected" width="94" height="85" viewBox="0 0 94 85">
      <g fill="#FFF" fillRule="evenodd" stroke="#414141" strokeWidth="3.6">
        <path d="M74.022 19.895c-.825-.917-1.93-6.694-2.848-9.627-.919-2.933-2.572-9.076-5.234-9.81h-.276c-2.849 0-1.378 14.67-5.234 16.139-.094 0-.277.093-.553.093-2.755 0-13.222-2.199-11.203-5.684 1.654-2.843 2.848-6.418 1.744-6.783-.826-.276-2.939-.458-5.234-.458-4.226 0-9.278.458-9.737 1.375-.735 1.468-1.47 4.86-2.572 6.325-.183.182-.366.275-.553.275-1.284 0-3.766-2.567-5.327-2.567-.276 0-.552.093-.825.365-9.736 10.544-5.417 9.533-11.297 16.503-2.479 2.933-12.032 2.75-13.869 7.428-3.031 8.07 6.335 23.104 8.172 25.49 1.837 2.385-1.654 5.318 2.39 6.142.458.093.918.182 1.377.182 3.58 0 7.347-3.115 15.706-8.344 5.417-3.302 8.448-4.22 10.56-4.22 1.561 0 2.663.46 3.768.824 2.572.735 6.705 5.684 8.082 6.053.093 0 .276.093.366.093 1.101 0 1.837-1.375 2.938-1.375h.366c1.47.365-2.02 1.468 6.705 8.893 1.47 1.282 2.662 1.74 3.673 1.74 1.93 0 3.215-1.65 4.592-2.199.183-.093.366-.093.553-.093 1.284 0 1.836 1.74 3.673 1.74.366 0 .825-.092 1.378-.275 3.397-1.282 5.693-.276 5.786-2.657.093-6.601 8.359-12.013 8.265-25.032.187-8.158-14.507-19.618-15.332-20.535v-.001zM76.59 72.431c-.276-.182-.735-.275-1.101-.275-1.012 0-2.39.458-3.857.458-.825 0-1.743-.093-2.662-.551-.276-.094-.552-.183-.735-.183-2.755 0-.552 9.075 3.856 9.075.277 0 .553 0 .919-.093 5.327-1.461 5.51-7.055 3.58-8.43h0z" transform="translate(2.2 1.6)"/>
      </g>
    </SelectedSvg>
  </Container>
)

export default australia
