import React from 'react'
import { Translate } from 'react-localize-redux'
import {
  Image,
  Content,
  InnerContainer,
  ImageWrapper,
} from '../FinalCrest/FinalCrest.styles'
import { Container, MailPng } from './ReceiveMail.styles'
import Mail from '../../assets/mail.png'
import { getLogoBase64 } from '../../lib/finalLogoBase64'
import FinalActions from '../FinalActionsBtns/FinalActionsBtns'

function ReceiveMail() {
  return (
    <Container>
      <InnerContainer>
        <ImageWrapper><Image src={getLogoBase64()} alt="final crest" /></ImageWrapper>
        <MailPng src={Mail} alt='mail' />
        <Content>
          <Translate id="receiveMail.content" />
        </Content>
        <FinalActions />
      </InnerContainer>
    </Container>
  )
}

export default ReceiveMail
