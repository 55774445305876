import React from 'react'
import { Container, SelectedSvg } from '../styles'

const southAmerica = () => (
  <Container>
    <svg width="55" height="94" viewBox="0 0 55 94">
      <path fill="none" fillRule="evenodd" stroke="#47C3D2" strokeWidth="3.6" d="M15.584 1.803c-1.862 0-5.799-.134-6.597 1.49-.568 1.154-.034 5.498-.733 6.209-2.574 2.616-4.48 6.955-5.62 10.43-.456 1.39 3.834 15.82 4.642 16.64 1.29 1.312 3.782 1.857 5.132 3.23 1.798 1.827 1.057 10.932.244 13.41-1.04 3.169.453 7.771-.977 10.68-4.133 8.4-3.829 23.759 4.153 27.815 2.223 1.13.978-8.416.978-9.685 0-5.678 2.519-10.011 5.864-13.412 2.865-2.911 9.77-4.461 11.729-8.444 1.408-2.862 3.9-11.166 5.62-12.914 2.236-2.274 5.494-2.604 7.33-4.47 1.313-1.336-.737-5.957.489-7.203 1.681-1.709 6.793-6.058 4.642-10.43-2.186-4.444-6.843-1.244-10.018-4.471-5.51-5.601-11.095-13.462-17.837-16.888-2.653-1.349-5.95-.56-9.04-1.987h0z"/>
    </svg>
    <SelectedSvg id="selected" width="55" height="95" viewBox="0 0 55 95">
      <path fill="#FFF" fillRule="evenodd" stroke="#414141" strokeWidth="3.6" d="M15.584 2.403c-1.862 0-5.799-.134-6.597 1.49-.568 1.154-.034 5.498-.733 6.209-2.574 2.616-4.48 6.955-5.62 10.43-.456 1.39 3.834 15.82 4.642 16.64 1.29 1.312 3.782 1.857 5.132 3.23 1.798 1.827 1.057 10.932.244 13.41-1.04 3.169.453 7.771-.977 10.68-4.133 8.4-3.829 23.759 4.153 27.815 2.223 1.13.978-8.416.978-9.685 0-5.678 2.519-10.011 5.864-13.412 2.865-2.911 9.77-4.461 11.729-8.444 1.408-2.862 3.9-11.166 5.62-12.914 2.236-2.274 5.494-2.604 7.33-4.47 1.313-1.336-.737-5.957.489-7.203 1.681-1.709 6.793-6.058 4.642-10.43-2.186-4.444-6.843-1.244-10.018-4.471-5.51-5.601-11.095-13.462-17.837-16.888-2.653-1.349-5.95-.56-9.04-1.987h0z"/>
    </SelectedSvg>
  </Container>
)

export default southAmerica
