import styled from 'styled-components/macro'
import device from '../../lib/devices'

const Container = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  flexShrink: 0,
  overflow: 'hidden',
  maxWidth: '400rem',
  width: '100%',
  [`@media ${device.tablet}`]: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  [`@media ${device.mobileL}`]: {
    maxWidth: '100%',
    maxHeight: '270rem',
    overflow: 'auto',
  }
})

const InnerContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '350rem',
  width: '100%',
  fontSize: '16rem',
  [`@media ${device.tablet}`]: {
    alignItems: 'center',
    overflow: 'auto',
    height: '100%',
  },
  [`@media ${device.mobileL}`]: {
    maxWidth: '100%',
  }
})

const Title = styled.div({
  fontSize: '35rem',
  fontWeight: '600',
  color: '#414141',
  padding: '0 20rem',
  [`@media ${device.mobileL}`]: {
    fontSize: '26rem',
  }
})

const Description = styled.div({
  lineHeight: 1.53,
  color: '#828282',
  marginTop: '15rem',
  padding: '0 20rem',
  [`@media ${device.mobileL}`]: {
    fontSize: '14rem',
    padding: '0 10rem',
    marginTop: '9rem',
  }
})

const Instruction = styled.div({
  margin: '15rem 20rem 0 20rem',
  fontSize: '18rem',
  [`@media ${device.mobileL}`]: {
    margin: '10rem 20rem 0 20rem',
    fontSize: '17rem',
  }
})

const Component = styled.div({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  flexShrink: 1,
  flexGrow: 1,
  flexBasis: '220rem',
  overflow: 'hidden',
  [`@media ${device.tablet}`]: {
    flexBasis: 'unset',
    overflow: 'unset',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  }
})

export {
  Container,
  InnerContainer,
  Title,
  Description,
  Instruction,
  Component,
}