import styled from 'styled-components'

const Container = styled.div({
  background: 'rgba(255, 255, 255, 0.7)',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 3,
})

const LoaderSvg = styled.svg({
  background: 'transparent',
  display: 'block',
  shapeRendering: 'auto',
  textAlign: 'center',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '150px',
})

export {
  Container,
  LoaderSvg,
}