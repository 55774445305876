import React from 'react'
import { Container, SelectedSvg } from '../styles'

const barley = () => (
  <Container>
    <svg width="49" height="48" viewBox="0 0 49 48">
      <g fill="none" fillRule="evenodd" stroke="#FBB034" strokeLinecap="round" strokeWidth="1.8">
        <path strokeLinejoin="round" d="M27.884 33.387c-2.595.182-4.612 1.012-4.697 1.048l-1.01.42.813.72c3.642 3.219 7.06 4.743 10.162 4.526 2.484-.174 3.809-1.445 3.864-1.5l.383-.377-.275-.458c-1.903-3.155-5.097-4.668-9.24-4.379h0zM38.203 36.369l.482.24.353-.405c.188-.216 4.524-5.392-4.047-13.616l-.782-.751-.354 1.024c-.034.097-3.251 9.716 4.348 13.508h0zM25.89 32.113c2.264 0 3.55-1.064 3.604-1.108l.406-.344-.241-.467c-1.52-2.945-4.313-4.504-8.076-4.504-2.351 0-4.226.617-4.304.643l-1.029.343.756.76c3.077 3.103 6.067 4.677 8.883 4.677h0z" transform="translate(.8 1.4)"/>
        <path strokeLinejoin="round" d="M31.011 29.544l.348-.402c.17-.195 4.072-4.897-3.608-12.34l-.77-.746-.35 1.017c-.03.088-2.916 8.796 3.907 12.232l.473.239zM19.267 25.048c1.923 0 3.022-.944 3.069-.984l.414-.364-.246-.495c-1.28-2.577-3.632-3.937-6.798-3.937-1.968 0-3.537.535-3.603.557l-1.053.364.771.805c2.573 2.69 5.077 4.054 7.446 4.054h0zM23.978 22.479l.367-.41c.148-.166 3.56-4.147-3.093-10.389l-.811-.762-.369 1.036c-.896 2.543-1.341 7.965 3.407 10.28l.5.245zM8.185 13.968l-1.035.377.76.834c2.08 2.286 4.115 3.446 6.043 3.446 1.558 0 2.443-.774 2.539-.862l.408-.376-.242-.514c-1.048-2.214-2.967-3.386-5.55-3.386-1.596.005-2.871.463-2.923.481h0z" transform="translate(.8 1.4)"/>
        <path strokeLinejoin="round" d="M18.24 16.699l.38-.404c.129-.136 3.083-3.408-2.609-8.48l-.841-.75-.38 1.022c-.77 2.07-1.148 6.48 2.935 8.37l.515.242z" transform="translate(.8 1.4)"/>
        <path d="M16.25 0L33.096 20.058M.348 15.438L20.158 33.063M11.375 11.239L3.548 3.506M12.938 7.718L8.489 1.885M7.162 12.141L1.258 7.746M46.475 45.279L39 37.893" transform="translate(.8 1.4)"/>
      </g>
    </svg>
    <SelectedSvg id="selected" width="49" height="48" viewBox="0 0 49 48">
      <g fill="#FFF" fillRule="evenodd" stroke="#414141" strokeLinecap="round" strokeWidth="1.8">
        <path strokeLinejoin="round" d="M27.884 33.387c-2.595.182-4.612 1.012-4.697 1.048l-1.01.42.813.72c3.642 3.219 7.06 4.743 10.162 4.526 2.484-.174 3.809-1.445 3.864-1.5l.383-.377-.275-.458c-1.903-3.155-5.097-4.668-9.24-4.379h0zM38.203 36.369l.482.24.353-.405c.188-.216 4.524-5.392-4.047-13.616l-.782-.751-.354 1.024c-.034.097-3.251 9.716 4.348 13.508h0zM25.89 32.113c2.264 0 3.55-1.064 3.604-1.108l.406-.344-.241-.467c-1.52-2.945-4.313-4.504-8.076-4.504-2.351 0-4.226.617-4.304.643l-1.029.343.756.76c3.077 3.103 6.067 4.677 8.883 4.677h0z" transform="translate(.8 1.8)"/>
        <path strokeLinejoin="round" d="M31.011 29.544l.348-.402c.17-.195 4.072-4.897-3.608-12.34l-.77-.746-.35 1.017c-.03.088-2.916 8.796 3.907 12.232l.473.239zM19.267 25.048c1.923 0 3.022-.944 3.069-.984l.414-.364-.246-.495c-1.28-2.577-3.632-3.937-6.798-3.937-1.968 0-3.537.535-3.603.557l-1.053.364.771.805c2.573 2.69 5.077 4.054 7.446 4.054h0zM23.978 22.479l.367-.41c.148-.166 3.56-4.147-3.093-10.389l-.811-.762-.369 1.036c-.896 2.543-1.341 7.965 3.407 10.28l.5.245zM8.185 13.968l-1.035.377.76.834c2.08 2.286 4.115 3.446 6.043 3.446 1.558 0 2.443-.774 2.539-.862l.408-.376-.242-.514c-1.048-2.214-2.967-3.386-5.55-3.386-1.596.005-2.871.463-2.923.481h0z" transform="translate(.8 1.8)"/>
        <path strokeLinejoin="round" d="M18.24 16.699l.38-.404c.129-.136 3.083-3.408-2.609-8.48l-.841-.75-.38 1.022c-.77 2.07-1.148 6.48 2.935 8.37l.515.242z" transform="translate(.8 1.8)"/>
        <path d="M16.25 0L33.096 20.058M.348 15.438L20.158 33.063M11.375 11.239L3.548 3.506M12.938 7.718L8.489 1.885M7.162 12.141L1.258 7.746M46.475 45.279L39 37.893" transform="translate(.8 1.8)"/>
      </g>
    </SelectedSvg>
  </Container>
)

export default barley
