import React from 'react'
import { setCurrentStep } from '../../store/ui/actions'
import { connect } from 'react-redux'
import { Container, Step } from './NumberSteps.styles'
import steps from '../../lib/steps'

function NumberSteps(props) {
  const { dispatch, maxEnableStep, currentStep } = props

  return (
    <Container>
      {[...new Array(steps.length)].map((number, index) => (
      <Step
        onClick={() => {
          dispatch(setCurrentStep(index + 1))
        }}
        isChosen={currentStep === index + 1}
        key={index}
        disabled={index + 1 > maxEnableStep}
      >
        {index + 1}
      </Step>
    ))}
    </Container>
  )
}

const mapStateToProps = ({
  ui: { maxEnableStep, currentStep }
}) => ({ maxEnableStep, currentStep })

export default connect(mapStateToProps)(NumberSteps)
