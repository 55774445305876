import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import {
  Container,
  InnerContainer,
  Option,
  Image,
  Description,
  Name,
} from './OptionsGrid.styles'
import { updateState } from '../../store/userSelections/actions'
import { featuresId, DIRECTIONS } from '../../consts'

const MAXIMUM_OPTIONS = 6

function OptionsGrid(props) {
  const {
    options,
    isList,
    isLargerPics,
    dispatch,
    value = '',
    name,
    multiSelect,
    selectedFeatures,
    activeLanguage,
  } = props
  const currLang = activeLanguage ? activeLanguage.code : 'en'
  const container = useRef(null)


  useEffect(() => {
    container.current.scrollTop = 0
  }, [options])

  function isOptionSelected(id) {
    if (!multiSelect) {
      return (id === value)
    }
    return (Array.isArray(value) && value.includes(id))
  }


  function handleClick(e) {
    const { id } = e.currentTarget
    if (!multiSelect) {
      return dispatch(updateState(name, id))
    }

    // Handling features selections
    if (selectedFeatures[0] === featuresId.defaultLeft &&
       selectedFeatures[1] === featuresId.defaultRight) {
      return dispatch(updateState(name, [id, id]))
    } else if (selectedFeatures[0] === selectedFeatures[1]) {
      return dispatch(updateState(name, [selectedFeatures[0], id]))
    } else if (selectedFeatures[0] !== selectedFeatures[1]) {
      if (selectedFeatures.find(feature => feature === id)) {
        const feature = selectedFeatures.find(feature => feature !== id)
        return dispatch(updateState(name, [feature, feature]))
      } else dispatch(updateState(name, [selectedFeatures[1], id]))
    } 
    return
  }

  return (
    <Container
      isList={isList}
      isShadow={options.length > MAXIMUM_OPTIONS}
      ref={container}
    >
      <InnerContainer isList={isList}>
        {options.map(({ color, id, image, name, description }) => (
        <Option
          selectedColor={color}
          isList={isList}
          id={id}
          key={id}
          onClick={handleClick}
          selected={isOptionSelected(id)}
          currLang={currLang}
        >
          {image &&
          <Image
            isList={isList}
            isLargerPics={isLargerPics}
          >
            {image}
          </Image>}
          <Description isList={isList}>
            {name && <Name>{name}</Name>}
            {description}
          </Description>
        </Option>
        ))}
      </InnerContainer>
    </Container>
  )
}

const mapStateToProps = ({ userSelections }, props) => ({
  value: userSelections[props.name],
  selectedFeatures: userSelections.selectedFeatures,
})

export default connect(mapStateToProps)(withLocalize(OptionsGrid))
