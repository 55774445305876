import React from 'react'
import { Container, SelectedSvg } from '../styles'

const ant = () => (
  <Container>
    <svg width="51" height="94" viewBox="0 0 51 94">
      <g fill="none" fillRule="evenodd">
        <path fill="#FAAF33" d="M24.612 64.778l8.068-2.208s1.22 17.739 3.817 17.033c2.598-.706 7.23-3.397 7.23-3.397s.124-2.04-1.62-1.206c-1.746.834-5.295 2.917-5.295 2.917s-3.028-16.595-2.668-17.456c.3-.716-7.422 1.912-9.998 2.805-.442.153-.65.657-.452 1.084.162.348.548.529.918.428" transform="translate(0 .2)"/>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.134" d="M24.612 64.778l8.068-2.208s1.22 17.739 3.817 17.033c2.598-.706 7.23-3.397 7.23-3.397s.124-2.04-1.62-1.206c-1.746.834-5.295 2.917-5.295 2.917s-3.028-16.595-2.668-17.456c.3-.716-7.422 1.912-9.998 2.805-.442.153-.65.657-.452 1.084.162.348.548.529.918.428z" transform="translate(0 .2)"/>
        <path fill="#FAAF33" d="M17.91 14.342S28.983-4.392 42.142 1.83c0 0 1.242 2.547-1.029 1.383-1.967-1.007-5.056-3.296-10.72.264-5.666 3.56-10.842 10.547-11.178 11.213-.336.664-1.305-.348-1.305-.348" transform="translate(0 .2)"/>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.134" d="M17.91 14.342S28.983-4.392 42.142 1.83c0 0 1.242 2.547-1.029 1.383-1.967-1.007-5.056-3.296-10.72.264-5.666 3.56-10.842 10.547-11.178 11.213-.336.664-1.305-.348-1.305-.348z" transform="translate(0 .2)"/>
        <path fill="#FAAF33" d="M33.504 39.565l7.15 1.298s-.254-14.316 1.88-13.932c2.131.384 6.035 2.215 6.035 2.215s.241 1.63-1.217 1.083c-1.457-.547-4.45-1.968-4.45-1.968s-1.277 13.527-.928 14.191c.35.663-8.894-1.718-8.894-1.718l.424-1.169z" transform="translate(0 .2)"/>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.134" d="M33.504 39.565l7.15 1.298s-.254-14.316 1.88-13.932c2.131.384 6.035 2.215 6.035 2.215s.241 1.63-1.217 1.083c-1.457-.547-4.45-1.968-4.45-1.968s-1.277 13.527-.928 14.191c.35.663-8.894-1.718-8.894-1.718l.424-1.169z" transform="translate(0 .2)"/>
        <path fill="#FEFEFE" d="M37.126 22.512c-.023-1.701-5.856-11.785-19.193-10.415-1.642.168-8.227 1.103-6.74 9.76.57 3.313 5.144 1.89 7.104 4.75 1.394 2.036-4.012 8.46-4.466 11.749-.713 5.163 3.378 7.31 1.113 10.282-2.14 2.814-4.12 4.891-6.096 7.713-5.795 8.278-14.488 22.202-.372 26.61.795.246 13.826-.257 17.133-6.605 1.121-2.151 4.084-10.093 1.042-15.18-2.039-3.411-8.097-7.466-5.354-10.333 2.743-2.867 13.09-2.211 13.872-6.113.639-3.184-.44-5.776-2.67-8.122-3.046-3.2-8.216-2.312-10.987-10.63-.676-2.033 15.687 1.983 15.614-3.466" transform="translate(0 .2)"/>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M37.126 22.512c-.023-1.701-5.856-11.785-19.193-10.415-1.642.168-8.227 1.103-6.74 9.76.57 3.313 5.144 1.89 7.104 4.75 1.394 2.036-4.012 8.46-4.466 11.749-.713 5.163 3.378 7.31 1.113 10.282-2.14 2.814-4.12 4.891-6.096 7.713-5.795 8.278-14.488 22.202-.372 26.61.795.246 13.826-.257 17.133-6.605 1.121-2.151 4.084-10.093 1.042-15.18-2.039-3.411-8.097-7.466-5.354-10.333 2.743-2.867 13.09-2.211 13.872-6.113.639-3.184-.44-5.776-2.67-8.122-3.046-3.2-8.216-2.312-10.987-10.63-.676-2.033 15.687 1.983 15.614-3.466z" transform="translate(0 .2)"/>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M28.589 19.345c.017.542-.406.994-.943 1.012-.538.017-.988-.408-1.005-.95-.017-.54.406-.994.943-1.011.538-.017.988.408 1.005.95z" transform="translate(0 .2)"/>
        <path fill="#FAAF33" d="M24.032 14.62c3.148-2.695 16.485-13.083 26.052-4.607 0 0 .487 2.795-1.372 1.039-1.61-1.52-3.942-4.584-10.369-2.756-5.599 1.593-11.948 6.203-13.49 7.362-.213.162-.502.175-.732.036-.386-.234-.433-.78-.09-1.074" transform="translate(0 .2)"/>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.134" d="M24.032 14.62c3.148-2.695 16.485-13.083 26.052-4.607 0 0 .487 2.795-1.372 1.039-1.61-1.52-3.942-4.584-10.369-2.756-5.599 1.593-11.948 6.203-13.49 7.362-.213.162-.502.175-.732.036-.386-.234-.433-.78-.09-1.074z" transform="translate(0 .2)"/>
        <g>
          <path fill="#FEFEFE" d="M6.737 3.56S.607 5.191 1.01.076" transform="translate(0 .2) translate(14.56 47.283)"/>
          <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M6.737 3.56S.607 5.191 1.01.076" transform="translate(0 .2) translate(14.56 47.283)"/>
        </g>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M16.723 25.262s2.997 1.466 5.906.115" transform="translate(0 .2)"/>
        <path fill="#FAAF33" d="M24.59 45.74l5.318-4.156s6.36 12.808 8.073 11.473c1.714-1.334 4.331-4.778 4.331-4.778s-.535-1.556-1.575-.392c-1.042 1.165-3.04 3.82-3.04 3.82s-7.352-11.395-7.348-12.146c.003-.626-4.93 3.733-6.57 5.2-.283.251-.283.691-.005.947.229.208.573.221.816.032" transform="translate(0 .2)"/>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.134" d="M24.59 45.74l5.318-4.156s6.36 12.808 8.073 11.473c1.714-1.334 4.331-4.778 4.331-4.778s-.535-1.556-1.575-.392c-1.042 1.165-3.04 3.82-3.04 3.82s-7.352-11.395-7.348-12.146c.003-.626-4.93 3.733-6.57 5.2-.283.251-.283.691-.005.947.229.208.573.221.816.032z" transform="translate(0 .2)"/>
        <path fill="#FAAF33" d="M11.83 67.809l8.791 4.253S8.25 88.649 11.077 90.022c2.827 1.374 8.952 2.562 8.952 2.562s1.659-1.707-.514-2.311c-2.173-.606-6.884-1.495-6.884-1.495s9.911-16.97 10.881-17.45c.808-.402-7.996-4.019-10.947-5.21-.505-.206-1.072.078-1.22.607-.122.432.083.889.485 1.084" transform="translate(0 .2)"/>
        <path stroke="#FAAF33" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.134" d="M11.83 67.809l8.791 4.253S8.25 88.649 11.077 90.022c2.827 1.374 8.952 2.562 8.952 2.562s1.659-1.707-.514-2.311c-2.173-.606-6.884-1.495-6.884-1.495s9.911-16.97 10.881-17.45c.808-.402-7.996-4.019-10.947-5.21-.505-.206-1.072.078-1.22.607-.122.432.083.889.485 1.084z" transform="translate(0 .2)"/>
      </g>
    </svg>
    <SelectedSvg id="selected" width="52" height="94" viewBox="0 0 52 94">
      <g fill="none" fillRule="evenodd">
        <path fill="#414141" d="M24.612 64.778l8.068-2.208s1.22 17.739 3.817 17.033c2.598-.705 7.23-3.396 7.23-3.396s.124-2.042-1.62-1.208c-1.746.836-5.295 2.918-5.295 2.918s-3.028-16.596-2.668-17.455c.3-.717-7.42 1.91-9.998 2.804-.441.153-.65.657-.453 1.083.163.35.55.53.919.429" transform="translate(.8 .8)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.128" d="M24.612 64.778l8.068-2.208s1.22 17.739 3.817 17.033c2.598-.705 7.23-3.396 7.23-3.396s.124-2.042-1.62-1.208c-1.746.836-5.295 2.918-5.295 2.918s-3.028-16.596-2.668-17.455c.3-.717-7.42 1.91-9.998 2.804-.441.153-.65.657-.453 1.083.163.35.55.53.919.429z" transform="translate(.8 .8)"/>
        <path fill="#414141" d="M17.91 14.343S28.984-4.392 42.142 1.829c0 0 1.242 2.548-1.029 1.384-1.967-1.007-5.056-3.296-10.72.264-5.666 3.56-10.842 10.548-11.178 11.213-.336.663-1.305-.347-1.305-.347" transform="translate(.8 .8)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.128" d="M17.91 14.343S28.984-4.392 42.142 1.829c0 0 1.242 2.548-1.029 1.384-1.967-1.007-5.056-3.296-10.72.264-5.666 3.56-10.842 10.548-11.178 11.213-.336.663-1.305-.347-1.305-.347z" transform="translate(.8 .8)"/>
        <path fill="#414141" d="M33.504 39.565l7.149 1.298s-.253-14.315 1.88-13.932c2.133.384 6.036 2.215 6.036 2.215s.24 1.63-1.217 1.083c-1.457-.547-4.45-1.968-4.45-1.968s-1.276 13.527-.927 14.191c.348.664-8.895-1.719-8.895-1.719l.424-1.168z" transform="translate(.8 .8)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.128" d="M33.504 39.565l7.149 1.298s-.253-14.315 1.88-13.932c2.133.384 6.036 2.215 6.036 2.215s.24 1.63-1.217 1.083c-1.457-.547-4.45-1.968-4.45-1.968s-1.276 13.527-.927 14.191c.348.664-8.895-1.719-8.895-1.719l.424-1.168z" transform="translate(.8 .8)"/>
        <path fill="#FEFEFE" d="M37.126 22.511c-.022-1.7-5.856-11.784-19.193-10.414-1.641.168-8.226 1.103-6.738 9.76.569 3.312 5.142 1.89 7.102 4.751 1.394 2.035-4.012 8.46-4.466 11.748-.713 5.163 3.378 7.31 1.115 10.283-2.142 2.813-4.122 4.891-6.098 7.711C3.053 64.63-5.64 78.554 8.476 82.96c.794.247 13.826-.256 17.133-6.604 1.121-2.152 4.084-10.093 1.042-15.18-2.039-3.411-8.097-7.467-5.354-10.333 2.744-2.867 13.09-2.212 13.872-6.114.64-3.183-.44-5.776-2.67-8.121-3.046-3.2-8.215-2.312-10.987-10.63-.676-2.033 15.687 1.982 15.614-3.467" transform="translate(.8 .8)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.692" d="M37.126 22.511c-.022-1.7-5.856-11.784-19.193-10.414-1.641.168-8.226 1.103-6.738 9.76.569 3.312 5.142 1.89 7.102 4.751 1.394 2.035-4.012 8.46-4.466 11.748-.713 5.163 3.378 7.31 1.115 10.283-2.142 2.813-4.122 4.891-6.098 7.711C3.053 64.63-5.64 78.554 8.476 82.96c.794.247 13.826-.256 17.133-6.604 1.121-2.152 4.084-10.093 1.042-15.18-2.039-3.411-8.097-7.467-5.354-10.333 2.744-2.867 13.09-2.212 13.872-6.114.64-3.183-.44-5.776-2.67-8.121-3.046-3.2-8.215-2.312-10.987-10.63-.676-2.033 15.687 1.982 15.614-3.467z" transform="translate(.8 .8)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.692" d="M28.589 19.345c.016.541-.406.995-.943 1.012-.538.017-.988-.407-1.005-.95-.017-.541.405-.995.943-1.011.538-.017.988.408 1.005.95z" transform="translate(.8 .8)"/>
        <path fill="#414141" d="M24.032 14.62c3.148-2.695 16.485-13.083 26.052-4.607 0 0 .488 2.794-1.372 1.038-1.609-1.519-3.943-4.584-10.368-2.756-5.6 1.594-11.95 6.204-13.49 7.363-.215.161-.503.175-.732.036-.387-.233-.434-.78-.09-1.074" transform="translate(.8 .8)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.128" d="M24.032 14.62c3.148-2.695 16.485-13.083 26.052-4.607 0 0 .488 2.794-1.372 1.038-1.609-1.519-3.943-4.584-10.368-2.756-5.6 1.594-11.95 6.204-13.49 7.363-.215.161-.503.175-.732.036-.387-.233-.434-.78-.09-1.074z" transform="translate(.8 .8)"/>
        <g>
            <path fill="#FEFEFE" d="M6.737 3.56S.607 5.191 1.008.076" transform="translate(.8 .8) translate(14.56 47.283)"/>
            <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.692" d="M6.737 3.56S.607 5.191 1.008.076" transform="translate(.8 .8) translate(14.56 47.283)"/>
        </g>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.692" d="M16.723 25.261s2.997 1.466 5.906.115" transform="translate(.8 .8)"/>
        <path fill="#414141" d="M24.59 45.74l5.318-4.156s6.36 12.808 8.073 11.474c1.714-1.334 4.331-4.778 4.331-4.778s-.535-1.557-1.576-.393c-1.041 1.165-3.04 3.82-3.04 3.82s-7.35-11.395-7.348-12.146c.004-.627-4.929 3.733-6.57 5.2-.282.252-.282.691-.004.946.228.209.572.223.815.033" transform="translate(.8 .8)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.128" d="M24.59 45.74l5.318-4.156s6.36 12.808 8.073 11.474c1.714-1.334 4.331-4.778 4.331-4.778s-.535-1.557-1.576-.393c-1.041 1.165-3.04 3.82-3.04 3.82s-7.35-11.395-7.348-12.146c.004-.627-4.929 3.733-6.57 5.2-.282.252-.282.691-.004.946.228.209.572.223.815.033z" transform="translate(.8 .8)"/>
        <path fill="#414141" d="M11.83 67.809l8.792 4.252S8.25 88.65 11.076 90.022c2.827 1.374 8.954 2.562 8.954 2.562s1.657-1.707-.515-2.311c-2.173-.605-6.883-1.496-6.883-1.496s9.91-16.968 10.88-17.45c.807-.401-7.996-4.018-10.946-5.21-.506-.205-1.073.079-1.222.608-.12.432.084.888.486 1.084" transform="translate(.8 .8)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.128" d="M11.83 67.809l8.792 4.252S8.25 88.65 11.076 90.022c2.827 1.374 8.954 2.562 8.954 2.562s1.657-1.707-.515-2.311c-2.173-.605-6.883-1.496-6.883-1.496s9.91-16.968 10.88-17.45c.807-.401-7.996-4.018-10.946-5.21-.506-.205-1.073.079-1.222.608-.12.432.084.888.486 1.084z" transform="translate(.8 .8)"/>
      </g>
    </SelectedSvg>
  </Container>
)

export default ant
