import React from 'react'
import { Container, SelectedSvg } from '../styles'

const hamsaShield = () => (
  <Container>
    <svg width="64" height="82" viewBox="0 0 64 82">
      <path fill="#BF311A" fillRule="evenodd" stroke="#BF311A" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.14" d="M60.726 42.458c-2.75-2.775-6.424-4.303-10.348-4.303-.249 0-.494.007-.743.018l.003-24.99c0-2.278-.878-4.42-2.475-6.03-1.598-1.612-3.717-2.498-5.975-2.498-.63 0-1.255.07-1.863.21C37.848 2.271 35.096.6 31.998.6s-5.85 1.672-7.322 4.268c-.609-.14-1.235-.21-1.864-.21-2.258 0-4.38.887-5.974 2.499-1.598 1.612-2.476 3.751-2.476 6.03v24.936c-.225-.01-.453-.017-.678-.017-3.93 0-7.63 1.546-10.41 4.351L1.4 44.35l2.202 1.486c.903.611 1.753 1.316 2.531 2.101 3.143 3.172 4.875 7.39 4.875 11.879C11.005 71.495 20.423 81 31.998 81c11.576 0 20.994-9.506 20.994-21.189 0-4.487 1.732-8.706 4.875-11.879.774-.781 1.628-1.49 2.53-2.1l2.203-1.487-1.874-1.887zM56.33 46.38c-3.675 3.71-5.51 8.57-5.51 13.431 0 10.49-8.427 18.994-18.82 18.994s-18.819-8.504-18.819-18.994c0-4.86-1.836-9.722-5.51-13.431-.893-.9-1.85-1.689-2.86-2.373 2.451-2.474 5.663-3.71 8.875-3.71.957 0 1.915.112 2.852.332l-.007-27.446c0-1.622.612-3.241 1.84-4.48 1.223-1.235 2.831-1.857 4.439-1.857 1.065 0 2.13.273 3.084.817.65-2.788 3.136-4.868 6.102-4.868 2.967 0 5.452 2.08 6.106 4.871.954-.544 2.019-.816 3.084-.816 1.608 0 3.212.618 4.44 1.856 1.223 1.236 1.839 2.858 1.839 4.48v27.502c.957-.223 1.936-.338 2.914-.338 3.191 0 6.376 1.204 8.81 3.66-1.01.68-1.971 1.47-2.86 2.37h.001z"/>
    </svg>
    <SelectedSvg id="selected" width="66" height="85" viewBox="0 0 66 85">
      <path fill="#FFF" fillRule="evenodd" stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.6" d="M61.726 44.258c-2.75-2.775-6.424-4.303-10.348-4.303-.249 0-.494.007-.743.018l.003-24.99c0-2.278-.878-4.42-2.475-6.03-1.598-1.612-3.717-2.498-5.975-2.498-.63 0-1.255.07-1.863.21C38.848 4.071 36.096 2.4 32.998 2.4s-5.85 1.672-7.322 4.268c-.609-.14-1.235-.21-1.864-.21-2.258 0-4.38.887-5.974 2.499-1.598 1.612-2.476 3.751-2.476 6.03v24.936c-.225-.01-.453-.017-.678-.017-3.93 0-7.63 1.546-10.41 4.351L2.4 46.15l2.202 1.486c.903.611 1.753 1.316 2.531 2.101 3.143 3.172 4.875 7.39 4.875 11.879-.003 11.68 9.415 21.185 20.99 21.185 11.576 0 20.994-9.506 20.994-21.189 0-4.487 1.732-8.706 4.875-11.879.774-.781 1.628-1.49 2.53-2.1l2.203-1.487-1.874-1.887z"/>
    </SelectedSvg>
  </Container>
)

export default hamsaShield
