import React, { useState } from 'react'
import { connect } from 'react-redux'
import StartOverBtn from './StartOverBtn/StartOverBtn'
import { reset } from '../store/userSelections/actions'
import Modal from './Modal/Modal'

function StartOverModal(props) {
  const { dispatch } = props
  const [isOpen, setIsOpen] = useState(false)

  function handleInnerRestartClick() {
    dispatch(reset())
    setIsOpen(false)
  }

  return (
    <>
      <StartOverBtn onClick={() => setIsOpen(true)} />
      <Modal
        title='modal.title'
        content='modal.content'
        onClick={(value => setIsOpen(value))}
        isOpen={isOpen}
        isStartOver={true}
        handleInnerClickBtn={handleInnerRestartClick}
      />
    </>
  )
}

export default connect()(StartOverModal)
