import React, { useState } from "react"
import { withLocalize } from "react-localize-redux"
import {
  LangContainer,
  LangBtn,
} from './Languages.styles'

const Languages = ({
    languages, activeLanguage, setActiveLanguage, isCrestBuilderHidden,
}) => {
    const [LangChosen, setLangChosen] = useState('en')

    const handleClick = (code) => {
      setActiveLanguage(code)
      setLangChosen(code)
    }

    return (
      <LangContainer isCrestBuilderHidden={isCrestBuilderHidden}>
        {languages.map(lang => (
        <LangBtn
          key={lang.code}
          onClick={() => handleClick(lang.code)}
          selected={lang.code === LangChosen}
        >
          {lang.name}
        </LangBtn>
        ))}
       </LangContainer>
    )
}

export default withLocalize(Languages)