import Immutable from 'seamless-immutable'
import { handleActions } from 'redux-actions'
import types from './types'
import {
  shieldsId,
  occupationsId,
  attributesId,
  mottosId,
  continentsId,
  featuresId,
} from '../../consts'

const initialState = Immutable({
  selectedName: '',
  selectedShield: shieldsId.default,
  selectedOccupation: occupationsId.default,
  selectedAttribute: attributesId.default,
  selectedFeatures: [featuresId.defaultLeft, featuresId.defaultRight],
  selectedMotto: mottosId.default,
  selectedContinent: continentsId.default,
})

export default handleActions({
  [types.updateState]: (state, { key, value }) => state.set([key], value),
  [types.reset]: (state) => (Immutable({...initialState })),
}, initialState)