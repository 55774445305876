import React from 'react'
import { Translate } from 'react-localize-redux'
import FamilyName from "../components/FamilyName"
import OptionsGrid from "../components/OptionsGrid/OptionsGrid"
import {
  shields,
  occupations,
  attributes,
  features,
  mottos,
  continents,
 } from '../consts'

export default [
    {
      title: <Translate id="steps.1.title" />,
      description: <Translate id="steps.1.description" />,
      component: <FamilyName />,
    },
    {
      title: <Translate id="steps.2.title" />,
      description: <Translate id="steps.2.description" />,
      instruction: <Translate id="steps.2.instruction" />,
      component:
      <OptionsGrid
        options={shields}
        name='selectedShield'
      />,
    },
    {
      title: <Translate id="steps.3.title" />,
      description: <Translate id="steps.3.description" />,
      instruction: <Translate id="steps.3.instruction" />,
      component:
      <OptionsGrid
        options={occupations}
        name='selectedOccupation'
      />,
    },
    {
      title: <Translate id="steps.4.title" />,
      description: <Translate id="steps.4.description" />,
      instruction: <Translate id="steps.4.instruction" />,
      component:
      <OptionsGrid
        options={attributes}
        isList
        name='selectedAttribute'
      />,
    },
    {
      title: <Translate id="steps.5.title" />,
      description: <Translate id="steps.5.description" />,
      instruction: <Translate id="steps.5.instruction" />,
      component:
      <OptionsGrid
        options={features}
        isLargerPics
        multiSelect
        name='selectedFeatures'
      />,
    },
    {
      title: <Translate id="steps.6.title" />,
      description: <Translate id="steps.6.description" />,
      instruction: <Translate id="steps.6.instruction" />,
      component:
      <OptionsGrid
        options={mottos}
        isList
        name='selectedMotto'
      />,
    },
    {
      title: <Translate id="steps.7.title" />,
      description: <Translate id="steps.7.description" />,
      instruction: <Translate id="steps.7.instruction" />,
      component:
      <OptionsGrid
        options={continents}
        isLargerPics
        name="selectedContinent"
      />,
    }
  ]