import React from 'react'
import { connect } from 'react-redux'
import { Translate, withLocalize } from 'react-localize-redux'
import {
  BtnWrapper,
} from './FinalActionsBtns.styles'
import { Btn } from '../../generalStyles/Btn.styles'
import { startPrintMode } from '../../store/ui/actions'
import { reset } from '../../store/userSelections/actions'

function FinalActionsBtns({ dispatch, activeLanguage }) {
  const currLang = activeLanguage ? activeLanguage.code : 'en'

  const createNewClick = () => {
    dispatch(reset())
  }

  // const printModeClick = () => {
  //   dispatch(startPrintMode())
  // }

  const handleMeaningNameClick = () => {
    const url = currLang === 'en' ? 'https://dbs.bh.org.il/' : 'https://dbs.bh.org.il/he/'
    window.open(url, '_blank')
  }

  return (
    <BtnWrapper>
      {/* <Btn onClick={printModeClick}>
        <Translate id="finalActionsButtons.printBtn" />
      </Btn> */}
      <Btn onClick={handleMeaningNameClick}>
        <Translate id='finalActionsButtons.meaningName' />
      </Btn>
      <Btn onClick={createNewClick}>
        <Translate id="finalActionsButtons.createNewBtn" />
      </Btn>
    </BtnWrapper>
  )
}

export default connect()(withLocalize(FinalActionsBtns))
