import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import { Translate, withLocalize } from 'react-localize-redux'
import {
  Container,
  Image,
  Content,
  StartBtn,
  InnerContainer,
  BtnWrapper,
  YourFamilyCrestPng,

} from './Introduction.styles'
import OpeningCrestPng from '../../assets/openingCrest.png'
import OpeningCrestHebrewPng from '../../assets/openingCrestHebrew.png'
import FamilyCrestTitlePng from '../../assets/title.png'
import FamilyCrestTitleHebrewPng from '../../assets/titleHebrew.png'
import { setIsCrestBuilderHidden } from '../../store/ui/actions'

function Introduction(props) {
  const { dispatch, activeLanguage } = props
  const currLang = activeLanguage ? activeLanguage.code : 'en'
  const currLangTitle = currLang === 'en' ? FamilyCrestTitlePng : FamilyCrestTitleHebrewPng
  const currLangOpeningCrest = currLang === 'en' ? OpeningCrestPng : OpeningCrestHebrewPng

  useEffect(() => {
    ReactGA.pageview('/')
  }, [])

  return (
    <Container>
      <InnerContainer>
        <Image src={currLangOpeningCrest} alt="opening crest" lang={currLang} />
        <YourFamilyCrestPng src={currLangTitle} alt='title' />
        <Content direction={currLang === 'en' ? 'ltr' : 'rtl'}>
          <Translate id="introduction.content" />
        </Content>
        <BtnWrapper>
          <StartBtn onClick={() => dispatch(setIsCrestBuilderHidden())}>
            <Translate id="introduction.button" />
          </StartBtn>
        </BtnWrapper>
      </InnerContainer>
    </Container>
  )
}

export default connect()(withLocalize(Introduction))
