import React, { useState } from "react"
import { withLocalize } from "react-localize-redux"
import {
  LangContainer,
  LangBtn,
  Arrow,
  Dropdown,
  DropdownItem,
} from './Languages.styles'

const Languages = ({
    languages, activeLanguage, setActiveLanguage, isCrestBuilderHidden,
}) => {
    const [LangChosen, setLangChosen] = useState('En')
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const handleClick = (code, name) => {
      setActiveLanguage(code)
      if (code === 'he') {
        document.body.classList.add('he')
      } else {
        document.body.classList.remove('he')
      }
      setLangChosen(name)
    }

    return (
      <LangContainer
        isCrestBuilderHidden={isCrestBuilderHidden}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <LangBtn>
          {LangChosen}
        </LangBtn>
        <Arrow />
        {isDropdownOpen &&
        <Dropdown>
            {languages.map(lang => (
            <DropdownItem
              key={lang.code}
              onClick={() => handleClick(lang.code, lang.name)}
            >
              {lang.name}
            </DropdownItem>
            ))}
        </Dropdown>}
       </LangContainer>
    )
}

export default withLocalize(Languages)