import styled from 'styled-components/macro'

const Container = styled.div(({ isOpen, direction }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  visibility: isOpen ? 'visible' : 'hidden',
  zIndex: 3,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  background: 'rgba(255,255,252, 0.9)',
  direction: direction,
}))

const ModalContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  background: '#ffffff',
  margin: 'auto',
  padding: '20px',
  border: '2px solid #232e83',
  width: '100%',
  maxWidth: '320rem',
  boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.15)',
  textAlign: 'center',
  position: 'relative',
  '& button': {
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%',
  },
  '& > svg': {
    position: 'absolute',
    height: 'auto',
  },
  '& #modal-deco-left': {
    top: '5rem',
    left: '5rem',
    width: '18rem',
  },
  '& #modal-deco-right': {
    bottom: '5rem',
    right: '5rem',
    width: '15rem',
  },
})

const Title = styled.div({
  fontSize: '32rem',
  fontWeight: 600,
  color: '#414141',
})

const Content = styled.div({
    color: '#5a5959',
    lineHeight: 1.4,
    fontSize: '13rem',
    margin: '20rem 0',
})

const CloseWrapper = styled.button({
  position: 'absolute',
  top: '5rem',
  right: '5rem',
  width: '15rem',
  height: 'auto',
  cursor: 'pointer',
  background: 'transparent',
  border: 'none',
  outline: 'none',
  padding: 0,
  '& svg': {
    width: '100%',
    height: '100%',
  }
})

export {
  Container,
  ModalContent,
  Title,
  Content,
  CloseWrapper,
}