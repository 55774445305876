import styled from 'styled-components/macro'
import device from '../../lib/devices'
import { isMobile } from '../../consts'

const Container = styled.div(({ isList, isShadow }) => ({
  width: '100%',
  maxHeight: '100%',
  height: '100%',
  overflowY: 'auto',
  paddingInlineEnd: '20rem',
  paddingInlineStart: '16rem',
  paddingTop: '16rem',
  paddingBottom: '16rem',
  direction: 'rtl',
  display: isList ? 'block' : 'flex',
  justifyContent: isList ? 'none' : 'flex-end',
  alignItems: 'flex-start',
  boxSizing: 'border-box',
  '&:before': {
    content: isShadow ? '""' : '',
    position: 'absolute',
    minHeight: '40rem',
    top: '-13rem',
    left: '20rem',
    right: '16rem',
    backgroundImage: 'linear-gradient(to top, rgba(255, 255, 252, 0), #fffefb 50%)',
    zIndex: 1,
  },
  '&:after': {
    content: isShadow ? '""' : '',
	  position: 'absolute',
    minHeight: '25rem',
    bottom: 0,
    left: 15,
    right: 10,
    background: 'linear-gradient(to top, white, transparent)',
  },
  [`@media ${device.tablet}`]: {
    '&:after': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    }
  },
  [`@media ${device.mobileL}`]: {
    paddingInlineEnd: '10rem',
    paddingInlineStart: '10rem',
    justifyContent: 'center',
    overflowY: 'unset',
  },
}))

const InnerContainer = styled.div(({ isList }) => ({
  display: 'grid',
  gridTemplateColumns: isList ? '100%' : 'repeat(3, 100rem)',
  gridTemplateRows: isList? 'none' : 'repeat(2, 100rem)',
  gridGap: '7rem',
  direction: isList ? 'rtl' : 'ltr',
  [`@media ${device.mobileL}`]: {
    gridTemplateColumns: isList ? '100%' : 'repeat(3, 96rem)',
    gridTemplateRows: isList? 'none' : 'repeat(2, 96rem)',
    gridGap: '7px 3px',
  }
}))

const Option = styled.button(({ selectedColor, isList, selected, currLang }) => ({
  display: 'flex',
  flexDirection: isList ? 'row-reverse' : 'column',
  justifyContent: isList ? 'flex-start' : 'space-around',
  alignItems: 'center',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  background: 'white',
  padding: isList ? '11rem 20rem' : '5rem',
  boxShadow: '0 2rem 12rem 0 rgba(0, 0, 0, 0.15)',
  color: '#414141',
  fontSize: isList ? '14rem' : '',
  textAlign: isList ? 'end' : 'center',
  minHeight: '50rem',
  height: '100%',
  direction: currLang === 'en' ? 'rtl' : 'ltr',
  [!isMobile && '&:hover']: {
    background: selectedColor,
    '& #selected': {
      display: 'block',
    },
    '& div': {
      color: 'white',
    }
  },
  [selected && '&']: {
    background: selectedColor,
    '& #selected': {
      display: 'block',
    },
    '& div': {
      color: 'white',
    }
  },
  [`@media ${device.mobileL}`]: {
    padding: '9rem',
  }
}))

const Image = styled.div(({ isList, isLargerPics }) => ({
  width: isList ? '25rem' : isLargerPics ? '60rem' : '45rem',
  height: isList ? '25rem' : isLargerPics ? '60rem' : '45rem',
  marginInlineStart: isList ? '10rem' : 0,
  '& svg': {
    width: '100%',
    height: '100%',
  },
}))

const Description = styled.div(({ isList }) => ({
  fontWeight: 'bold',
  lineHeight: isList ? 1.44 : 1.13,
  color: '#414141',
  whiteSpace: 'pre-wrap',
}))

const Name = styled.div({
  fontWeight: 'normal',
})

export {
  Container,
  InnerContainer,
  Option,
  Image,
  Description,
  Name,
}
