import React from 'react'
import { Container, SelectedSvg } from '../styles'

const mule = () => (
  <Container>
    <svg width="69" height="103" viewBox="0 0 69 103">
      <g fill="none" fillRule="evenodd">
        <g>
          <path fill="#FEFEFE" d="M21.207 32.512s-13.917 5.092-11.2-6.555c1.274-5.458 8.522-3.935 11.515-10.455 1.659-3.615 2.969-9.407-7.81-14.636-7.896-3.83 13.084 11.825-3.838 15.709-11.817 2.71-15.712 30.911 11.581 18.87" transform="translate(.6 1) translate(0 28.88)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M21.207 32.512s-13.917 5.092-11.2-6.555c1.274-5.458 8.522-3.935 11.515-10.455 1.659-3.615 2.969-9.407-7.81-14.636-7.896-3.83 13.084 11.825-3.838 15.709-11.817 2.71-15.712 30.911 11.581 18.87" transform="translate(.6 1) translate(0 28.88)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M5.176 17.444C8.34 11.66 6.986 7.9 7.24 2.96c.4-7.77-4.815 4.156-5.34 5.876-.527 1.72-1.603 9.73-.291 11.09" transform="translate(.6 1) translate(29.614 .212)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.909" d="M5.176 17.444C8.34 11.66 6.986 7.9 7.24 2.96c.4-7.77-4.815 4.156-5.34 5.876-.527 1.72-1.603 9.73-.291 11.09" transform="translate(.6 1) translate(29.614 .212)"/>
        </g>
        <g>
          <path fill="#46C2D1" d="M8.338 36.21c-.997-3.399-.648-1.879.3-5.706 2.154-8.696-3.629-7.61-5.932-12.494C-.904 10.36 1.284 3.263 6.408.83c5.122-2.433 11.991 1.536 11.991 1.536l.188 3.268S7.804 6.103 8.147 9.12c.343 3.015.342 6.436.342 6.436s2.79 1.405 4.81 8.105c2.023 6.698-5.152 17.452-5.152 17.452l-2.93-.16" transform="translate(.6 1) translate(19.363 12.68)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.669" d="M8.338 36.21c-.997-3.399-.648-1.879.3-5.706 2.154-8.696-3.629-7.61-5.932-12.494C-.904 10.36 1.284 3.263 6.408.83c5.122-2.433 11.991 1.536 11.991 1.536l.188 3.268S7.804 6.103 8.147 9.12c.343 3.015.342 6.436.342 6.436s2.79 1.405 4.81 8.105c2.023 6.698-5.152 17.452-5.152 17.452l-2.93-.16" transform="translate(.6 1) translate(19.363 12.68)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.004.286c1.62-.34 9.294-.688 11.811 2.429 2.746 3.4-.952 11.596-.329 12.466.401.561 2.564 1.559 3.204 1.808 2.479.964 4.193-.804 6.684.575 1.79.993-5.212 9.824-6.11 5.3-.286-1.43-5.243-2.297-6.433-3.128-1.114-.779-.398-9.391-2.629-8.018-1.557.96-4.378 1.5-6.198 1.35" transform="translate(.6 1) translate(36.447 63.132)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M.004.286c1.62-.34 9.294-.688 11.811 2.429 2.746 3.4-.952 11.596-.329 12.466.401.561 2.564 1.559 3.204 1.808 2.479.964 4.193-.804 6.684.575 1.79.993-5.212 9.824-6.11 5.3-.286-1.43-5.243-2.297-6.433-3.128-1.114-.779-.398-9.391-2.629-8.018-1.557.96-4.378 1.5-6.198 1.35" transform="translate(.6 1) translate(36.447 63.132)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.045 16.512c3.016-1.335 4.805.177 5.692-3.018.56-2.012.062-8.664.7-10.653C7.18.517 8.981-.39 11.327.227c1.587.42 6.631-1.295 6.964 1.252.246 1.883-.788 6.008-2.372 7.042-1.582 1.034-5.815-5.625-5.815-3.724 0 2.144 1.444 13.178 1.223 15.032-.499 4.198-4.998 3.623-8.244 4.198" transform="translate(.6 1) translate(46.698 23)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M.045 16.512c3.016-1.335 4.805.177 5.692-3.018.56-2.012.062-8.664.7-10.653C7.18.517 8.981-.39 11.327.227c1.587.42 6.631-1.295 6.964 1.252.246 1.883-.788 6.008-2.372 7.042-1.582 1.034-5.815-5.625-5.815-3.724 0 2.144 1.444 13.178 1.223 15.032-.499 4.198-4.998 3.623-8.244 4.198" transform="translate(.6 1) translate(46.698 23)"/>
        </g>
        <path fill="#FEFEFE" d="M37.95 29.672c2.145.837 3.428-.22 4.855.05 1.67.315 2.225.707 3.006.749 1.511.079.009-1.37 2.139-1.838 1.677-.37 1.227-4.893-.066-5.595-1.297-.702-5.792-3.05-8.055-3.653-1.654-.44-1.397-2.475-3.86-3.686-1.333-.654-2.8-1.996-7.058-1.217-1.65.301-5.665.09-6.063 9.012-.077 1.699-.257 5.801 1.736 7.72 2.119 2.042 5.86 3.818 5.445 7.514-.168 1.5-1.124 9.294-3.52 12.243-2.267 2.789-4.79 4.55-6.445 7.639-3.351 6.258-1.158 9.748 3.84 14.769 1.555 1.562 3.45 2.852 4.523 4.78 1.074 1.927.604 4.386-1.067 5.82-3.288 2.822 1.79 10.57 2.46 11.768.609 1.087-1.252 3.59-.202 4.253.516.325 5.753.212 7.642.26 1.414.034 1.763-4.115.172-4.815-2.384-1.046-3.296.197-5.415-1.53-1.763-1.436-1.36-7.245.268-8.097 2.31-1.21 5.523-2.406 6.594-5.11.91-2.295.601-5.006-.082-7.316-.996-3.361-2.687-1.847-2.37-5.34.365-4.03 3.306-7.29 6.186-10.115 2.88-2.824 6.003-5.789 6.923-9.728.752-3.216-.192-6.78-2.433-9.192-1.73-1.863-9.352-2.179-10.536-6.966-.13-.52-.221-3.006 1.382-2.38" transform="translate(.6 1)"/>
        <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M37.95 29.672c2.145.837 3.428-.22 4.855.05 1.67.315 2.225.707 3.006.749 1.511.079.009-1.37 2.139-1.838 1.677-.37 1.227-4.893-.066-5.595-1.297-.702-5.792-3.05-8.055-3.653-1.654-.44-1.397-2.475-3.86-3.686-1.333-.654-2.8-1.996-7.058-1.217-1.65.301-5.665.09-6.063 9.012-.077 1.699-.257 5.801 1.736 7.72 2.119 2.042 5.86 3.818 5.445 7.514-.168 1.5-1.124 9.294-3.52 12.243-2.267 2.789-4.79 4.55-6.445 7.639-3.351 6.258-1.158 9.748 3.84 14.769 1.555 1.562 3.45 2.852 4.523 4.78 1.074 1.927.604 4.386-1.067 5.82-3.288 2.822 1.79 10.57 2.46 11.768.609 1.087-1.252 3.59-.202 4.253.516.325 5.753.212 7.642.26 1.414.034 1.763-4.115.172-4.815-2.384-1.046-3.296.197-5.415-1.53-1.763-1.436-1.36-7.245.268-8.097 2.31-1.21 5.523-2.406 6.594-5.11.91-2.295.601-5.006-.082-7.316-.996-3.361-2.687-1.847-2.37-5.34.365-4.03 3.306-7.29 6.186-10.115 2.88-2.824 6.003-5.789 6.923-9.728.752-3.216-.192-6.78-2.433-9.192-1.73-1.863-9.352-2.179-10.536-6.966-.13-.52-.221-3.006 1.382-2.38z" transform="translate(.6 1)"/>
        <g>
          <path fill="#FEFEFE" d="M9.32.33c3.811 1.22 1.414 5.258 2.768 8.109 1.013 2.135 1.689 3.13 4.022 2.849 2.257-.273 6.21-1.877 8.12-2.954 1.162-.653 2.201 1.245 4.334 2.295 1.56.766 4.347.437 4.992 1.511.682 1.133-3.473 6.246-5.864 6.177-1.713-.05-2.475-5.388-3.595-5.22-6.689 1.005-4.26 3.645-8.187 4.824-5.125 1.543-7.404.714-9.286-1.759-1.511-1.987-2.147-8.476-5.89-5.528" transform="translate(.6 1) translate(33.03 41.492)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M9.32.33c3.811 1.22 1.414 5.258 2.768 8.109 1.013 2.135 1.689 3.13 4.022 2.849 2.257-.273 6.21-1.877 8.12-2.954 1.162-.653 2.201 1.245 4.334 2.295 1.56.766 4.347.437 4.992 1.511.682 1.133-3.473 6.246-5.864 6.177-1.713-.05-2.475-5.388-3.595-5.22-6.689 1.005-4.26 3.645-8.187 4.824-5.125 1.543-7.404.714-9.286-1.759-1.511-1.987-2.147-8.476-5.89-5.528" transform="translate(.6 1) translate(33.03 41.492)"/>
        </g>
        <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M37.95 21.402c0 .55-.444.997-.992.997-.546 0-.99-.447-.99-.997s.444-.998.99-.998c.548 0 .991.447.991.998z" transform="translate(.6 1)"/>
        <g>
          <path fill="#FEFEFE" d="M1.848.818S.226.548.068 1.265c-.204.925 2.055 1.058 1.657 2.085" transform="translate(.6 1) translate(44.42 23.146)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M1.848.818S.226.548.068 1.265c-.204.925 2.055 1.058 1.657 2.085" transform="translate(.6 1) translate(44.42 23.146)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M6.05 16.343C6.937 9.398 4.437 6.74 2.987 2.145.705-5.084.054 8.525.165 10.365c.11 1.837 1.864 9.655 3.51 10.166" transform="translate(.6 1) translate(23.919 .066)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.909" d="M6.05 16.343C6.937 9.398 4.437 6.74 2.987 2.145.705-5.084.054 8.525.165 10.365c.11 1.837 1.864 9.655 3.51 10.166" transform="translate(.6 1) translate(23.919 .066)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M.508.39S.369 8.282 2.455 9.953" transform="translate(.6 1) translate(26.197 7.092)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.611" d="M.508.39S.369 8.282 2.455 9.953" transform="translate(.6 1) translate(26.197 7.092)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M1.688.091S4.117 3.097.85 6.001" transform="translate(.6 1) translate(58.088 23.146)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M1.688.091S4.117 3.097.85 6.001" transform="translate(.6 1) translate(58.088 23.146)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M4.087.53S3.385 4.342.385 4.744" transform="translate(.6 1) translate(58.088 51.812)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M4.087.53S3.385 4.342.385 4.744" transform="translate(.6 1) translate(58.088 51.812)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M2.884.087S.38 3.597 1.128 5.015" transform="translate(.6 1) translate(50.115 80.332)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M2.884.087S.38 3.597 1.128 5.015" transform="translate(.6 1) translate(50.115 80.332)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M4.15.592S1.478.905.32 2.612" transform="translate(.6 1) translate(29.614 94.24)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M4.15.592S1.478.905.32 2.612" transform="translate(.6 1) translate(29.614 94.24)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M7.931 1.87S1.143 3.726.045.307" transform="translate(.6 1) translate(28.475 27.732)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M7.931 1.87S1.143 3.726.045.307" transform="translate(.6 1) translate(28.475 27.732)"/>
        </g>
      </g>
    </svg>
    <SelectedSvg id="selected" width="69" height="103" viewBox="0 0 69 103">
      <g fill="none" fillRule="evenodd">
        <g>
            <path fill="#FEFEFE" d="M21.207 32.512s-13.918 5.092-11.2-6.556c1.273-5.457 8.522-3.933 11.515-10.454 1.659-3.614 2.968-9.407-7.81-14.636-7.896-3.83 13.084 11.825-3.838 15.708-11.816 2.712-15.712 30.912 11.582 18.871" transform="translate(.6 1.6) translate(0 28.88)"/>
            <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M21.207 32.512s-13.918 5.092-11.2-6.556c1.273-5.457 8.522-3.933 11.515-10.454 1.659-3.614 2.968-9.407-7.81-14.636-7.896-3.83 13.084 11.825-3.838 15.708-11.816 2.712-15.712 30.912 11.582 18.871" transform="translate(.6 1.6) translate(0 28.88)"/>
        </g>
        <g>
            <path fill="#FEFEFE" d="M5.176 17.444C8.338 11.661 6.986 7.9 7.24 2.96c.4-7.769-4.815 4.156-5.34 5.875-.527 1.72-1.603 9.732-.29 11.092" transform="translate(.6 1.6) translate(29.614 .212)"/>
            <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.91" d="M5.176 17.444C8.338 11.661 6.986 7.9 7.24 2.96c.4-7.769-4.815 4.156-5.34 5.875-.527 1.72-1.603 9.732-.29 11.092" transform="translate(.6 1.6) translate(29.614 .212)"/>
        </g>
        <g>
            <path fill="#414141" d="M8.339 36.21c-.998-3.399-.648-1.878.3-5.706 2.153-8.697-3.63-7.61-5.933-12.494C-.903 10.36 1.283 3.263 6.408.83 11.53-1.603 18.4 2.365 18.4 2.365l.188 3.268s-10.782.47-10.44 3.487c.342 3.016.342 6.435.342 6.435s2.79 1.406 4.811 8.106c2.022 6.699-5.153 17.452-5.153 17.452l-2.93-.16" transform="translate(.6 1.6) translate(19.363 12.68)"/>
            <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.671" d="M8.339 36.21c-.998-3.399-.648-1.878.3-5.706 2.153-8.697-3.63-7.61-5.933-12.494C-.903 10.36 1.283 3.263 6.408.83 11.53-1.603 18.4 2.365 18.4 2.365l.188 3.268s-10.782.47-10.44 3.487c.342 3.016.342 6.435.342 6.435s2.79 1.406 4.811 8.106c2.022 6.699-5.153 17.452-5.153 17.452l-2.93-.16" transform="translate(.6 1.6) translate(19.363 12.68)"/>
        </g>
        <g>
            <path fill="#FEFEFE" d="M.004.286c1.62-.34 9.294-.688 11.811 2.43 2.746 3.399-.951 11.595-.33 12.466.402.56 2.565 1.558 3.205 1.807 2.478.964 4.194-.804 6.683.575 1.79.992-5.212 9.824-6.11 5.3-.284-1.43-5.242-2.296-6.431-3.128-1.115-.778-.4-9.392-2.63-8.017-1.557.96-4.378 1.5-6.198 1.35" transform="translate(.6 1.6) translate(36.447 63.132)"/>
            <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M.004.286c1.62-.34 9.294-.688 11.811 2.43 2.746 3.399-.951 11.595-.33 12.466.402.56 2.565 1.558 3.205 1.807 2.478.964 4.194-.804 6.683.575 1.79.992-5.212 9.824-6.11 5.3-.284-1.43-5.242-2.296-6.431-3.128-1.115-.778-.4-9.392-2.63-8.017-1.557.96-4.378 1.5-6.198 1.35" transform="translate(.6 1.6) translate(36.447 63.132)"/>
        </g>
        <g>
            <path fill="#FEFEFE" d="M.045 16.512c3.016-1.334 4.804.176 5.692-3.018.56-2.012.063-8.664.698-10.652C7.18.517 8.982-.391 11.328.227c1.587.419 6.631-1.294 6.964 1.252.246 1.883-.788 6.008-2.371 7.042-1.582 1.033-5.816-5.624-5.816-3.725 0 2.144 1.444 13.18 1.223 15.034-.499 4.196-4.998 3.622-8.244 4.196" transform="translate(.6 1.6) translate(46.698 23)"/>
            <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M.045 16.512c3.016-1.334 4.804.176 5.692-3.018.56-2.012.063-8.664.698-10.652C7.18.517 8.982-.391 11.328.227c1.587.419 6.631-1.294 6.964 1.252.246 1.883-.788 6.008-2.371 7.042-1.582 1.033-5.816-5.624-5.816-3.725 0 2.144 1.444 13.18 1.223 15.034-.499 4.196-4.998 3.622-8.244 4.196" transform="translate(.6 1.6) translate(46.698 23)"/>
        </g>
        <path fill="#FEFEFE" d="M37.95 29.672c2.145.837 3.428-.22 4.854.049 1.67.317 2.225.709 3.007.75 1.511.08.009-1.37 2.139-1.838 1.677-.37 1.228-4.894-.067-5.595-1.296-.701-5.791-3.05-8.054-3.653-1.654-.44-1.397-2.475-3.861-3.687-1.332-.653-2.799-1.995-7.056-1.216-1.65.301-5.666.09-6.065 9.013-.076 1.697-.256 5.8 1.736 7.72 2.12 2.04 5.86 3.816 5.446 7.513-.169 1.501-1.124 9.294-3.52 12.243-2.268 2.789-4.79 4.55-6.445 7.64-3.35 6.258-1.158 9.746 3.84 14.768 1.555 1.562 3.45 2.852 4.524 4.78 1.074 1.926.603 4.387-1.068 5.82-3.29 2.822 1.79 10.57 2.46 11.768.609 1.087-1.254 3.59-.202 4.252.516.326 5.753.213 7.642.26 1.414.035 1.763-4.115.172-4.813-2.384-1.047-3.296.196-5.414-1.531-1.764-1.436-1.36-7.245.267-8.097 2.31-1.21 5.522-2.405 6.594-5.11.91-2.295.601-5.006-.082-7.316-.996-3.361-2.687-1.846-2.37-5.339.365-4.032 3.306-7.292 6.187-10.116 2.88-2.824 6.002-5.788 6.922-9.728.752-3.216-.193-6.78-2.434-9.192-1.73-1.863-9.35-2.179-10.535-6.966-.129-.52-.222-3.005 1.382-2.38" transform="translate(.6 1.6)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M37.95 29.672c2.145.837 3.428-.22 4.854.049 1.67.317 2.225.709 3.007.75 1.511.08.009-1.37 2.139-1.838 1.677-.37 1.228-4.894-.067-5.595-1.296-.701-5.791-3.05-8.054-3.653-1.654-.44-1.397-2.475-3.861-3.687-1.332-.653-2.799-1.995-7.056-1.216-1.65.301-5.666.09-6.065 9.013-.076 1.697-.256 5.8 1.736 7.72 2.12 2.04 5.86 3.816 5.446 7.513-.169 1.501-1.124 9.294-3.52 12.243-2.268 2.789-4.79 4.55-6.445 7.64-3.35 6.258-1.158 9.746 3.84 14.768 1.555 1.562 3.45 2.852 4.524 4.78 1.074 1.926.603 4.387-1.068 5.82-3.29 2.822 1.79 10.57 2.46 11.768.609 1.087-1.254 3.59-.202 4.252.516.326 5.753.213 7.642.26 1.414.035 1.763-4.115.172-4.813-2.384-1.047-3.296.196-5.414-1.531-1.764-1.436-1.36-7.245.267-8.097 2.31-1.21 5.522-2.405 6.594-5.11.91-2.295.601-5.006-.082-7.316-.996-3.361-2.687-1.846-2.37-5.339.365-4.032 3.306-7.292 6.187-10.116 2.88-2.824 6.002-5.788 6.922-9.728.752-3.216-.193-6.78-2.434-9.192-1.73-1.863-9.35-2.179-10.535-6.966-.129-.52-.222-3.005 1.382-2.38z" transform="translate(.6 1.6)"/>
        <g>
            <path fill="#FEFEFE" d="M9.32.33c3.811 1.218 1.414 5.258 2.768 8.109 1.014 2.134 1.69 3.13 4.022 2.848 2.257-.273 6.21-1.876 8.12-2.953 1.162-.654 2.201 1.246 4.335 2.295 1.559.766 4.346.437 4.992 1.511.68 1.133-3.474 6.246-5.865 6.176-1.713-.05-2.475-5.388-3.595-5.22-6.689 1.006-4.26 3.645-8.187 4.826-5.125 1.54-7.404.713-9.286-1.76-1.512-1.986-2.147-8.475-5.89-5.529" transform="translate(.6 1.6) translate(33.03 41.492)"/>
            <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M9.32.33c3.811 1.218 1.414 5.258 2.768 8.109 1.014 2.134 1.69 3.13 4.022 2.848 2.257-.273 6.21-1.876 8.12-2.953 1.162-.654 2.201 1.246 4.335 2.295 1.559.766 4.346.437 4.992 1.511.68 1.133-3.474 6.246-5.865 6.176-1.713-.05-2.475-5.388-3.595-5.22-6.689 1.006-4.26 3.645-8.187 4.826-5.125 1.54-7.404.713-9.286-1.76-1.512-1.986-2.147-8.475-5.89-5.529" transform="translate(.6 1.6) translate(33.03 41.492)"/>
        </g>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M37.95 21.402c0 .55-.444.998-.991.998-.547 0-.991-.448-.991-.998s.444-.998.99-.998c.548 0 .991.448.991.998z" transform="translate(.6 1.6)"/>
        <g>
            <path fill="#FEFEFE" d="M1.848.818S.226.548.068 1.266c-.204.923 2.056 1.057 1.657 2.084" transform="translate(.6 1.6) translate(44.42 23.146)"/>
            <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M1.848.818S.226.548.068 1.266c-.204.923 2.056 1.057 1.657 2.084" transform="translate(.6 1.6) translate(44.42 23.146)"/>
        </g>
        <g>
            <path fill="#FEFEFE" d="M6.05 16.343c.887-6.946-1.613-9.602-3.064-14.198C.705-5.084.055 8.525.165 10.363c.11 1.84 1.863 9.657 3.51 10.168" transform="translate(.6 1.6) translate(23.919 .066)"/>
            <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.91" d="M6.05 16.343c.887-6.946-1.613-9.602-3.064-14.198C.705-5.084.055 8.525.165 10.363c.11 1.84 1.863 9.657 3.51 10.168" transform="translate(.6 1.6) translate(23.919 .066)"/>
        </g>
        <g>
            <path fill="#FEFEFE" d="M.508.391s-.139 7.89 1.947 9.56" transform="translate(.6 1.6) translate(26.197 7.092)"/>
            <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.613" d="M.508.391s-.139 7.89 1.947 9.56" transform="translate(.6 1.6) translate(26.197 7.092)"/>
        </g>
        <g>
            <path fill="#FEFEFE" d="M1.688.091S4.117 3.096.85 6.001" transform="translate(.6 1.6) translate(58.088 23.146)"/>
            <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M1.688.091S4.117 3.096.85 6.001" transform="translate(.6 1.6) translate(58.088 23.146)"/>
        </g>
        <g>
            <path fill="#FEFEFE" d="M4.087.531S3.385 4.341.384 4.743" transform="translate(.6 1.6) translate(58.088 51.812)"/>
            <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M4.087.531S3.385 4.341.384 4.743" transform="translate(.6 1.6) translate(58.088 51.812)"/>
        </g>
        <g>
            <path fill="#FEFEFE" d="M2.884.088S.381 3.596 1.128 5.015" transform="translate(.6 1.6) translate(50.115 80.332)"/>
            <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M2.884.088S.381 3.596 1.128 5.015" transform="translate(.6 1.6) translate(50.115 80.332)"/>
        </g>
        <g>
            <path fill="#FEFEFE" d="M4.15.592S1.478.905.32 2.612" transform="translate(.6 1.6) translate(29.614 94.24)"/>
            <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M4.15.592S1.478.905.32 2.612" transform="translate(.6 1.6) translate(29.614 94.24)"/>
        </g>
        <g>
            <path fill="#FEFEFE" d="M7.932 1.869S1.143 3.726.044.309" transform="translate(.6 1.6) translate(28.475 27.732)"/>
            <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.702" d="M7.932 1.869S1.143 3.726.044.309" transform="translate(.6 1.6) translate(28.475 27.732)"/>
        </g>
      </g>
    </SelectedSvg>
  </Container>
)

export default mule
