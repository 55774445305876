import React from 'react'
import { Container, SelectedSvg } from '../styles'

const dove = () => (
  <Container>
    <svg width="72" height="94" viewBox="0 0 72 94">
      <g fill="none" fillRule="evenodd">
        <g>
          <path fill="#FEFEFE" d="M35.278 36.624c.64-4.552-8.214-8.218-10.525-15.648C20.575 7.54 11.218 3.051 4.908.874c-3.42-1.18-3.51 5.418.187 11.834C6.217 14.654.79 15.487.418 18.004c-.854 5.792 4.134 7.226 4.864 10.662.363 1.709-4.87 3.268-5.121 7.08-.583 8.827 10.74 6.06 11.09 8.273 1.579 10.029 3.823 13.356 15.791 6.762" transform="translate(.4 .2) translate(15.733 .11)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M35.278 36.624c.64-4.552-8.214-8.218-10.525-15.648C20.575 7.54 11.218 3.051 4.908.874c-3.42-1.18-3.51 5.418.187 11.834C6.217 14.654.79 15.487.418 18.004c-.854 5.792 4.134 7.226 4.864 10.662.363 1.709-4.87 3.268-5.121 7.08-.583 8.827 10.74 6.06 11.09 8.273 1.579 10.029 3.823 13.356 15.791 6.762" transform="translate(.4 .2) translate(15.733 .11)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M2.84.413c-.185.976 2.923-.693 4.686-.15 2.404.742 4.596 3.33 8.37 7.692 1.464 1.692.561 14.854 1.352 14.746 1.123-.155 3.937-4.927 4.609-3.57.116.233-3.182 2.781-4.505 4.747-.356.527-.363 2.02-.303 2.595.13 1.242-1.047 1.463-1.334.02-.037-.187.545-.89.482-1.515-.374-3.716-.336-15.547-1.456-15.448-3.409.304-7.961-1.302-9.093.697-.85 1.501-3.194 1.499-4.845 1.362" transform="translate(.4 .2) translate(48.324 54.24)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M2.84.413c-.185.976 2.923-.693 4.686-.15 2.404.742 4.596 3.33 8.37 7.692 1.464 1.692.561 14.854 1.352 14.746 1.123-.155 3.937-4.927 4.609-3.57.116.233-3.182 2.781-4.505 4.747-.356.527-.363 2.02-.303 2.595.13 1.242-1.047 1.463-1.334.02-.037-.187.545-.89.482-1.515-.374-3.716-.336-15.547-1.456-15.448-3.409.304-7.961-1.302-9.093.697-.85 1.501-3.194 1.499-4.845 1.362" transform="translate(.4 .2) translate(48.324 54.24)"/>
        </g>
        <path fill="#FEFEFE" d="M56.28 21.132c-1.073-2.78-3.743-4.475-6.474-5.325-6.368-1.98-11.24 1.239-11.143 9.314.054 4.508 2.116 5.38 2.133 8.632.06 11.134-7.85 14.535-9.054 15.357-4.432 3.026-3.515 6.084-6.25 15.084-.362 1.193-14.83 16.467-15.122 17.532-.954 3.49 2.324-.48 4.589 6.7.505 1.602 4.455-1.417 7.607-2.414 2.328-.736 3.067 2 3.863-.912 1.006-3.684 4.016-11.323 5.583-13.363 4.235-5.51 7.714 6.451 10.415 7.905.538.29 1.66.667 2.095 1.509 1.063 2.061 1.12 6.107.802 9.3-.073.742-3.75.856-4.211 1.413-.824.995.35.877.544.999.063.04.653-1.21 2.126-1.323 2.503-.19 6.507.69 7.194.707 1.282.032.783-1.124-.762-1.428-2.537-.5-3.298.63-3.683-1.243-.564-2.735-.721-5.783-.453-8.546.106-1.08 1.939-3.237 2.414-3.672 3.17-2.898.953-9.503.84-11.068-.154-2.153.037-4.386.987-6.322 1.087-2.213 6.807-10.295 8.17-12.452 6.578-10.424-4.481-15.442-4.754-17.755-.307-2.596 3.336-6.58 2.544-8.63" transform="translate(.4 .2)"/>
        <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M56.28 21.132c-1.073-2.78-3.743-4.475-6.474-5.325-6.368-1.98-11.24 1.239-11.143 9.314.054 4.508 2.116 5.38 2.133 8.632.06 11.134-7.85 14.535-9.054 15.357-4.432 3.026-3.515 6.084-6.25 15.084-.362 1.193-14.83 16.467-15.122 17.532-.954 3.49 2.324-.48 4.589 6.7.505 1.602 4.455-1.417 7.607-2.414 2.328-.736 3.067 2 3.863-.912 1.006-3.684 4.016-11.323 5.583-13.363 4.235-5.51 7.714 6.451 10.415 7.905.538.29 1.66.667 2.095 1.509 1.063 2.061 1.12 6.107.802 9.3-.073.742-3.75.856-4.211 1.413-.824.995.35.877.544.999.063.04.653-1.21 2.126-1.323 2.503-.19 6.507.69 7.194.707 1.282.032.783-1.124-.762-1.428-2.537-.5-3.298.63-3.683-1.243-.564-2.735-.721-5.783-.453-8.546.106-1.08 1.939-3.237 2.414-3.672 3.17-2.898.953-9.503.84-11.068-.154-2.153.037-4.386.987-6.322 1.087-2.213 6.807-10.295 8.17-12.452 6.578-10.424-4.481-15.442-4.754-17.755-.307-2.596 3.336-6.58 2.544-8.63z" transform="translate(.4 .2)"/>
        <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M52.877 21.864c0 .497-.403.901-.898.901-.497 0-.9-.404-.9-.901s.403-.902.9-.902c.495 0 .898.405.898.902z" transform="translate(.4 .2)"/>
        <g>
          <path fill="#FEFEFE" d="M43.266 23.177c-.97-4.493-10.54-4.86-15.27-11.029C19.444.992 9.118.03 2.446.178-1.17.258 1.798 7.319 6.7 11.216c1.757 1.393-3.08 4.1-2.56 6.592 1.195 5.73 6.368 5.344 8.238 8.316.93 1.475-3.442 4.756-2.365 8.42 2.496 8.484 12.167 1.957 13.257 3.913 4.94 8.86 8.194 11.203 17.15.86" transform="translate(.4 .2) translate(0 13.642)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M43.266 23.177c-.97-4.493-10.54-4.86-15.27-11.029C19.444.992 9.118.03 2.446.178-1.17.258 1.798 7.319 6.7 11.216c1.757 1.393-3.08 4.1-2.56 6.592 1.195 5.73 6.368 5.344 8.238 8.316.93 1.475-3.442 4.756-2.365 8.42 2.496 8.484 12.167 1.957 13.257 3.913 4.94 8.86 8.194 11.203 17.15.86" transform="translate(.4 .2) translate(0 13.642)"/>
        </g>
        <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M65.79 17.844s-9.24 4.954-9.265 11.273" transform="translate(.4 .2)"/>
        <path fill="#FEFEFE" d="M56.211 21.418c-.01-.162.193-.24.293-.113.299.385.739.844 1.227.998 1.494.476 6.227 2.8 4.029 2.954-1.947.136-4.856-.163-6.758.929-1.308.75 1.423-1.706 1.21-4.768" transform="translate(.4 .2)"/>
        <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M56.211 21.418c-.01-.162.193-.24.293-.113.299.385.739.844 1.227.998 1.494.476 6.227 2.8 4.029 2.954-1.947.136-4.856-.163-6.758.929-1.308.75 1.423-1.706 1.21-4.768z" transform="translate(.4 .2)"/>
        <path fill="#46C2D1" d="M64.028 16.944c0 1.277-.473 2.313-1.057 2.313-.585 0-1.059-1.036-1.059-2.313 0-1.278 1.059-2.313 1.059-2.313s1.057 1.035 1.057 2.313" transform="translate(.4 .2)"/>
        <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.134" d="M64.028 16.944c0 1.277-.473 2.313-1.057 2.313-.585 0-1.059-1.036-1.059-2.313 0-1.278 1.059-2.313 1.059-2.313s1.057 1.035 1.057 2.313z" transform="translate(.4 .2)"/>
        <path fill="#46C2D1" d="M64.298 22.072c-1.27.104-2.337-.285-2.384-.87-.047-.583.943-1.142 2.212-1.245 1.268-.104 2.382.869 2.382.869s-.942 1.142-2.21 1.246" transform="translate(.4 .2)"/>
        <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.134" d="M64.298 22.072c-1.27.104-2.337-.285-2.384-.87-.047-.583.943-1.142 2.212-1.245 1.268-.104 2.382.869 2.382.869s-.942 1.142-2.21 1.246z" transform="translate(.4 .2)"/>
        <path fill="#46C2D1" d="M60.7 19.615c0 1.278-.474 2.313-1.058 2.313-.584 0-1.058-1.035-1.058-2.313 0-1.278 1.058-2.313 1.058-2.313s1.057 1.035 1.057 2.313" transform="translate(.4 .2)"/>
        <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.134" d="M60.7 19.615c0 1.278-.474 2.313-1.058 2.313-.584 0-1.058-1.035-1.058-2.313 0-1.278 1.058-2.313 1.058-2.313s1.057 1.035 1.057 2.313z" transform="translate(.4 .2)"/>
        <path fill="#46C2D1" d="M67.659 16.579c-.6 1.127-1.504 1.816-2.02 1.54-.515-.277-.446-1.415.154-2.541.6-1.127 2.02-1.539 2.02-1.539s.446 1.413-.154 2.54" transform="translate(.4 .2)"/>
        <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.134" d="M67.659 16.579c-.6 1.127-1.504 1.816-2.02 1.54-.515-.277-.446-1.415.154-2.541.6-1.127 2.02-1.539 2.02-1.539s.446 1.413-.154 2.54z" transform="translate(.4 .2)"/>
        <g>
          <path fill="#FEFEFE" d="M3.48.9S1.672 1.923.153.9" transform="translate(.4 .2) translate(42.705 79.05)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M3.48.9S1.672 1.923.153.9" transform="translate(.4 .2) translate(42.705 79.05)"/>
        </g>
        <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M63.786 61.693s1.123 1.953-.982 2.096" transform="translate(.4 .2)"/>
        <g>
          <path fill="#FEFEFE" d="M6.284 3.783S1.11 4.091.627.097" transform="translate(.4 .2) translate(28.095 66.517)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M6.284 3.783S1.11 4.091.627.097" transform="translate(.4 .2) translate(28.095 66.517)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M44.301 24.21c0 2.123-5.067 3.332-7.778 2.967-2.71-.363-2.71-3.029-2.71-3.029-19.319-.605-16.18-10.54-18.502-14.852C12.99 4.984.005 4.73.005.276" transform="translate(.4 .2) translate(6.743 17.025)"/>
          <path stroke="#46C2D1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.701" d="M44.301 24.21c0 2.123-5.067 3.332-7.778 2.967-2.71-.363-2.71-3.029-2.71-3.029-19.319-.605-16.18-10.54-18.502-14.852C12.99 4.984.005 4.73.005.276" transform="translate(.4 .2) translate(6.743 17.025)"/>
        </g>
      </g>
    </svg>
    <SelectedSvg id="selected" width="71" height="94" viewBox="0 0 71 94">
      <g fill="none" fillRule="evenodd">
        <g>
          <path fill="#FEFEFE" d="M35.867 36.84c.638-4.548-8.2-8.21-10.506-15.633C21.191 7.783 11.85 3.301 5.551 1.125c-3.415-1.179-3.504 5.413.187 11.822 1.119 1.944-4.298 2.776-4.668 5.291-.853 5.786 4.124 7.219 4.854 10.652.362 1.706-4.86 3.264-5.113 7.072-.583 8.819 10.722 6.055 11.07 8.265 1.577 10.02 3.817 13.345 15.765 6.755" transform="translate(.2 -.6) translate(15.018 .898)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.62" d="M35.867 36.84c.638-4.548-8.2-8.21-10.506-15.633C21.191 7.783 11.85 3.301 5.551 1.125c-3.415-1.179-3.504 5.413.187 11.822 1.119 1.944-4.298 2.776-4.668 5.291-.853 5.786 4.124 7.219 4.854 10.652.362 1.706-4.86 3.264-5.113 7.072-.583 8.819 10.722 6.055 11.07 8.265 1.577 10.02 3.817 13.345 15.765 6.755" transform="translate(.2 -.6) translate(15.018 .898)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M2.766.878c-.186.976 2.917-.693 4.677-.15 2.399.741 4.59 3.326 8.355 7.684 1.462 1.691.56 14.84 1.35 14.73 1.121-.152 3.93-4.921 4.601-3.564.115.232-3.176 2.778-4.499 4.741-.354.528-.362 2.018-.301 2.593.13 1.241-1.044 1.46-1.332.02-.036-.187.545-.89.483-1.513-.374-3.713-.336-15.532-1.456-15.433-3.401.303-7.945-1.301-9.076.696-.849 1.5-3.188 1.497-4.836 1.361" transform="translate(.2 -.6) translate(48.273 54.762)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.62" d="M2.766.878c-.186.976 2.917-.693 4.677-.15 2.399.741 4.59 3.326 8.355 7.684 1.462 1.691.56 14.84 1.35 14.73 1.121-.152 3.93-4.921 4.601-3.564.115.232-3.176 2.778-4.499 4.741-.354.528-.362 2.018-.301 2.593.13 1.241-1.044 1.46-1.332.02-.036-.187.545-.89.483-1.513-.374-3.713-.336-15.532-1.456-15.433-3.401.303-7.945-1.301-9.076.696-.849 1.5-3.188 1.497-4.836 1.361" transform="translate(.2 -.6) translate(48.273 54.762)"/>
        </g>
        <path fill="#FEFEFE" d="M56.145 22.151c-1.071-2.777-3.737-4.47-6.463-5.32-6.357-1.977-11.22 1.239-11.124 9.305.055 4.503 2.113 5.375 2.13 8.624.06 11.124-7.837 14.521-9.038 15.342-4.424 3.022-3.51 6.078-6.238 15.07-.362 1.192-14.806 16.45-15.097 17.514-.952 3.487 2.32-.478 4.58 6.694.505 1.6 4.448-1.417 7.593-2.412 2.326-.735 3.063 1.999 3.858-.912 1.004-3.68 4.009-11.312 5.574-13.35 4.226-5.502 7.7 6.446 10.396 7.897.537.29 1.657.667 2.09 1.508 1.062 2.06 1.12 6.102.803 9.292-.074.74-3.744.855-4.204 1.41-.823.994.348.877.543.998.062.04.652-1.208 2.121-1.32 2.5-.19 6.497.69 7.182.706 1.28.032.781-1.123-.761-1.427-2.53-.5-3.29.628-3.677-1.242-.563-2.732-.476-5.763-.451-8.537.015-1.725 1.936-3.234 2.41-3.669 3.164-2.894.952-9.494.84-11.058-.156-2.15.035-4.381.984-6.315 1.084-2.211 3.043-3.833 4.887-5.458 1.91-1.683 3.803-3.442 5.162-5.598 6.567-10.413-6.366-16.81-6.64-19.121-.306-2.593 3.33-6.572 2.54-8.62" transform="translate(.2 -.6)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.62" d="M56.145 22.151c-1.071-2.777-3.737-4.47-6.463-5.32-6.357-1.977-11.22 1.239-11.124 9.305.055 4.503 2.113 5.375 2.13 8.624.06 11.124-7.837 14.521-9.038 15.342-4.424 3.022-3.51 6.078-6.238 15.07-.362 1.192-14.806 16.45-15.097 17.514-.952 3.487 2.32-.478 4.58 6.694.505 1.6 4.448-1.417 7.593-2.412 2.326-.735 3.063 1.999 3.858-.912 1.004-3.68 4.009-11.312 5.574-13.35 4.226-5.502 7.7 6.446 10.396 7.897.537.29 1.657.667 2.09 1.508 1.062 2.06 1.12 6.102.803 9.292-.074.74-3.744.855-4.204 1.41-.823.994.348.877.543.998.062.04.652-1.208 2.121-1.32 2.5-.19 6.497.69 7.182.706 1.28.032.781-1.123-.761-1.427-2.53-.5-3.29.628-3.677-1.242-.563-2.732-.476-5.763-.451-8.537.015-1.725 1.936-3.234 2.41-3.669 3.164-2.894.952-9.494.84-11.058-.156-2.15.035-4.381.984-6.315 1.084-2.211 3.043-3.833 4.887-5.458 1.91-1.683 3.803-3.442 5.162-5.598 6.567-10.413-6.366-16.81-6.64-19.121-.306-2.593 3.33-6.572 2.54-8.62z" transform="translate(.2 -.6)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.62" d="M52.748 22.883c0 .497-.402.9-.897.9s-.897-.403-.897-.9c0-.498.402-.9.897-.9s.897.402.897.9z" transform="translate(.2 -.6)"/>
        <g>
          <path fill="#FEFEFE" d="M43.154 22.998c-.968-4.49-10.523-4.854-15.243-11.018C19.373.834 9.066-.126 2.405.02c-3.61.08-.647 7.137 4.25 11.028 1.752 1.394-3.077 4.097-2.56 6.586 1.195 5.725 6.359 5.34 8.225 8.307.928 1.475-3.436 4.752-2.36 8.412 2.491 8.476 12.146 1.956 13.235 3.909 4.93 8.853 8.178 11.194 17.118.86" transform="translate(.2 -.6) translate(0 14.825)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.62" d="M43.154 22.998c-.968-4.49-10.523-4.854-15.243-11.018C19.373.834 9.066-.126 2.405.02c-3.61.08-.647 7.137 4.25 11.028 1.752 1.394-3.077 4.097-2.56 6.586 1.195 5.725 6.359 5.34 8.225 8.307.928 1.475-3.436 4.752-2.36 8.412 2.491 8.476 12.146 1.956 13.235 3.909 4.93 8.853 8.178 11.194 17.118.86" transform="translate(.2 -.6) translate(0 14.825)"/>
        </g>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.62" d="M65.638 18.866s-9.224 4.95-9.249 11.263" transform="translate(.2 -.6)"/>
        <path fill="#FEFEFE" d="M56.077 22.437c-.012-.162.192-.24.29-.112.3.383.74.842 1.225.996 1.493.476 6.217 2.797 4.023 2.951-1.944.137-4.848-.162-6.745.928-1.307.75 1.42-1.704 1.207-4.763" transform="translate(.2 -.6)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.62" d="M56.077 22.437c-.012-.162.192-.24.29-.112.3.383.74.842 1.225.996 1.493.476 6.217 2.797 4.023 2.951-1.944.137-4.848-.162-6.745.928-1.307.75 1.42-1.704 1.207-4.763z" transform="translate(.2 -.6)"/>
        <path fill="#414141" d="M63.88 17.967c0 1.277-.473 2.311-1.056 2.311-.584 0-1.057-1.034-1.057-2.31 0-1.277 1.057-2.311 1.057-2.311s1.056 1.034 1.056 2.31" transform="translate(.2 -.6)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".81" d="M63.88 17.967c0 1.277-.473 2.311-1.056 2.311-.584 0-1.057-1.034-1.057-2.31 0-1.277 1.057-2.311 1.057-2.311s1.056 1.034 1.056 2.31z" transform="translate(.2 -.6)"/>
        <path fill="#414141" d="M64.148 23.09c-1.267.105-2.332-.284-2.38-.868-.046-.583.942-1.141 2.209-1.245 1.266-.104 2.379.87 2.379.87s-.94 1.14-2.208 1.244" transform="translate(.2 -.6)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".81" d="M64.148 23.09c-1.267.105-2.332-.284-2.38-.868-.046-.583.942-1.141 2.209-1.245 1.266-.104 2.379.87 2.379.87s-.94 1.14-2.208 1.244z" transform="translate(.2 -.6)"/>
        <path fill="#414141" d="M60.557 20.636c0 1.277-.472 2.311-1.056 2.311-.583 0-1.057-1.034-1.057-2.31 0-1.277 1.057-2.311 1.057-2.311s1.056 1.034 1.056 2.31" transform="translate(.2 -.6)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".81" d="M60.557 20.636c0 1.277-.472 2.311-1.056 2.311-.583 0-1.057-1.034-1.057-2.31 0-1.277 1.057-2.311 1.057-2.311s1.056 1.034 1.056 2.31z" transform="translate(.2 -.6)"/>
        <path fill="#414141" d="M67.504 17.603c-.6 1.125-1.502 1.814-2.016 1.537-.515-.276-.445-1.411.153-2.537.6-1.126 2.016-1.537 2.016-1.537s.446 1.411-.153 2.537" transform="translate(.2 -.6)"/>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".81" d="M67.504 17.603c-.6 1.125-1.502 1.814-2.016 1.537-.515-.276-.445-1.411.153-2.537.6-1.126 2.016-1.537 2.016-1.537s.446 1.411-.153 2.537z" transform="translate(.2 -.6)"/>
        <g>
          <path fill="#FEFEFE" d="M4.23.295S2.427 1.317.91.295" transform="translate(.2 -.6) translate(41.836 80.616)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.62" d="M4.23.295S2.427 1.317.91.295" transform="translate(.2 -.6) translate(41.836 80.616)"/>
        </g>
        <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.62" d="M63.638 62.673s1.121 1.95-.98 2.093" transform="translate(.2 -.6)"/>
        <g>
          <path fill="#FEFEFE" d="M6.391 4.66S1.227 4.966.744.976" transform="translate(.2 -.6) translate(27.89 66.612)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.62" d="M6.391 4.66S1.227 4.966.744.976" transform="translate(.2 -.6) translate(27.89 66.612)"/>
        </g>
        <g>
          <path fill="#FEFEFE" d="M44.481 24.1c0 2.122-5.058 3.329-7.764 2.965-2.707-.362-2.707-3.025-2.707-3.025C14.726 23.434 17.86 13.508 15.542 9.2 13.225 4.893.262 4.64.262.191" transform="translate(.2 -.6) translate(6.436 18.134)"/>
          <path stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.62" d="M44.481 24.1c0 2.122-5.058 3.329-7.764 2.965-2.707-.362-2.707-3.025-2.707-3.025C14.726 23.434 17.86 13.508 15.542 9.2 13.225 4.893.262 4.64.262.191" transform="translate(.2 -.6) translate(6.436 18.134)"/>
        </g>
      </g>
    </SelectedSvg>
  </Container>
)

export default dove
