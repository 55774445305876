import React from 'react'
import { Container, SelectedSvg } from '../styles'

const scientists = () => (
  <Container>
    <svg width="40" height="72" viewBox="0 0 40 72">
      <path fill="#BF311A" fillRule="evenodd" d="M6.488.802c-.63.034-1.222.314-1.642.778-.424.467-.644 1.078-.61 1.705.031.627.316 1.214.783 1.632.47.42 1.086.639 1.717.605h3.168v11.013c0 .722-.263 1.321-.94 2.139-.678.817-1.758 1.73-2.946 2.704-1.188.974-2.484 2.025-3.588 3.368C1.325 26.088.4 27.843.4 29.908v36.186c0 3.008 2.515 5.506 5.544 5.506h28.512c3.029 0 5.544-2.498 5.544-5.506V29.908c0-2.065-.925-3.82-2.03-5.162-1.104-1.343-2.4-2.394-3.588-3.368-1.188-.974-2.268-1.887-2.946-2.704-.677-.818-.94-1.417-.94-2.139V5.522h3.168c.637.01 1.25-.236 1.702-.679.454-.442.708-1.048.708-1.68 0-.634-.254-1.24-.708-1.682-.452-.442-1.065-.688-1.702-.679H6.736C6.652.8 6.572.8 6.489.802zm8.168 4.72h11.088v11.013c0 2.059.925 3.807 2.03 5.138 1.104 1.33 2.4 2.372 3.588 3.343 1.188.971 2.268 1.88 2.945 2.704.678.824.94 1.444.94 2.188v1.573H5.153v-1.573c0-.744.263-1.364.94-2.188.678-.823 1.757-1.733 2.945-2.704 1.188-.971 2.485-2.013 3.59-3.343 1.104-1.33 2.029-3.08 2.029-5.138V5.522zm-9.504 30.68h30.096v22.025H5.152V36.201zm15.023 2.31c-.634.006-1.24.264-1.683.716-.44.455-.68 1.063-.668 1.693v3.933h-3.96c-.084-.003-.164-.003-.248 0-.63.034-1.222.314-1.643.778-.423.467-.643 1.078-.609 1.705.031.627.316 1.214.783 1.632.47.42 1.086.639 1.717.605h3.96v3.933c-.01.633.238 1.242.684 1.69.445.452 1.054.704 1.692.704.637 0 1.247-.252 1.692-.704.446-.448.693-1.057.684-1.69v-3.933h3.96c.637.01 1.25-.237 1.701-.68.455-.442.709-1.047.709-1.68s-.254-1.238-.709-1.68c-.451-.443-1.064-.689-1.701-.68h-3.96v-3.933c.012-.64-.235-1.257-.687-1.709-.452-.454-1.07-.706-1.714-.7zM5.152 62.947h30.096v3.146c0 .476-.313.787-.792.787H5.944c-.48 0-.792-.31-.792-.787v-3.146z"/>
    </svg>
    <SelectedSvg id="selected" width="40" height="72" viewBox="0 0 40 72">
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M10.977 3.268h18.42c-1.009 7.638-1.009 13.222 0 16.752.36 1.255 7.104 3.245 7.696 8.2.717 5.988.717 19.093 0 39.312H2.2l1.019-41.156 7.758-7.042V3.268z" transform="translate(.4 .8)"/>
        <path fill="#414141" d="M6.088.002c-.63.034-1.222.314-1.642.778-.424.467-.644 1.078-.61 1.705.031.627.316 1.214.783 1.632.47.42 1.086.639 1.717.605h3.168v11.013c0 .722-.263 1.321-.94 2.139-.678.817-1.758 1.73-2.946 2.704-1.188.974-2.484 2.025-3.589 3.368C.925 25.288 0 27.043 0 29.108v36.186C0 68.302 2.515 70.8 5.544 70.8h28.512c3.029 0 5.544-2.498 5.544-5.506V29.108c0-2.065-.925-3.82-2.03-5.162-1.104-1.343-2.4-2.394-3.588-3.368-1.188-.974-2.268-1.887-2.945-2.704-.678-.818-.941-1.417-.941-2.139V4.722h3.168c.637.01 1.25-.236 1.702-.679.454-.442.708-1.048.708-1.68 0-.634-.254-1.24-.708-1.682C34.514.24 33.9-.007 33.264.002H6.336C6.252 0 6.172 0 6.088.002zm8.168 4.72h11.088v11.013c0 2.059.925 3.807 2.03 5.138 1.104 1.33 2.4 2.372 3.588 3.343 1.188.971 2.268 1.88 2.945 2.704.678.824.94 1.444.94 2.188v1.573H4.753v-1.573c0-.744.263-1.364.94-2.188.678-.823 1.757-1.733 2.945-2.704 1.188-.971 2.485-2.013 3.59-3.343 1.104-1.33 2.029-3.08 2.029-5.138V4.722zm-9.504 30.68h30.096v22.025H4.752V35.401zm15.023 2.31c-.634.006-1.24.264-1.683.716-.44.455-.68 1.063-.668 1.693v3.933h-3.96c-.084-.003-.164-.003-.248 0-.63.034-1.222.314-1.643.778-.423.467-.643 1.078-.609 1.705.031.627.316 1.214.783 1.632.47.42 1.086.639 1.717.605h3.96v3.933c-.01.633.238 1.242.684 1.69.445.452 1.054.704 1.692.704.637 0 1.247-.252 1.692-.704.446-.448.693-1.057.684-1.69v-3.933h3.96c.637.01 1.25-.237 1.701-.68.455-.442.709-1.047.709-1.68s-.254-1.238-.709-1.68c-.451-.443-1.064-.689-1.701-.68h-3.96v-3.933c.012-.64-.235-1.257-.687-1.709-.452-.454-1.07-.706-1.714-.7zM4.752 62.147h30.096v3.146c0 .476-.313.787-.792.787H5.544c-.48 0-.792-.31-.792-.787v-3.146z" transform="translate(.4 .8)"/>
      </g>
    </SelectedSvg>
  </Container>
)

export default scientists
