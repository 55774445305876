import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import ReactGA from 'react-ga'
import { Translate, withLocalize } from 'react-localize-redux'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share'
import {
  Container,
  Image,
  Content,
  InnerContainer,
  BtnWrapper,
  ImageWrapper,
  CongratPng,
  SocialMedia,
  ShareBtnWrapper,
  ShareTooltip,
} from './FinalCrest.styles'
import { Btn } from '../../generalStyles/Btn.styles'
import Congrat from '../../assets/congrat.png'
import CongratHebrew from '../../assets/congratHebrew.png'
import { getLogoBase64 } from '../../lib/finalLogoBase64'
import api from '../../lib/api'
import { serviceUrl } from '../../consts'
import { InputTag } from '../../generalStyles/Input.styles'
import {
  setIsFinalCrestDisplay,
  setIsReceiveMailDisplay,
  setIsLoaderDisplay,
  setIsError,
} from '../../store/ui/actions'
import FinalActions from '../FinalActionsBtns/FinalActionsBtns'
import { DIRECTIONS, isMobile } from '../../consts'

function FinalCrest({ dispatch, activeLanguage, shareFinalCrestImageId, selectedName }) {
  const [mail, setMail] = useState('')
  const [isMailBtnChosen, setIsMailBtnChosen] = useState(false)
  const currLang = activeLanguage ? activeLanguage.code : 'en'
  const currDirection = activeLanguage ? DIRECTIONS[activeLanguage.code] : 'ltr'
  const currLangCongrat = currLang === 'en' ? Congrat : CongratHebrew

  useEffect(() => {
    ReactGA.pageview('/success')
  }, [])

  const mailBtnClick = () => {
    setIsMailBtnChosen(true)
  }
 
  const sendMailClick = async() => {
    try {
      const { success } = await api.post(`${serviceUrl}/mailer`, { json: { to: mail, lang: activeLanguage.code }}).json();
      dispatch(setIsFinalCrestDisplay(false))
      dispatch(setIsReceiveMailDisplay(true))
      if (!success) throw Error('api to sendMail failed')
    } catch (e) {
      console.log(e)
      dispatch(setIsLoaderDisplay(false))
      dispatch(setIsError(true))
    }
  }

  return (
    <Container isMailBtnChosen={isMailBtnChosen}>
      <InnerContainer>
        <ImageWrapper><Image src={getLogoBase64()} alt="final crest" /></ImageWrapper>
        <CongratPng src={currLangCongrat} alt='congratulations' lang={currLang} />
        <Content direction={currDirection}>
          <Translate id="congrat.content" />
        </Content>
        {isMailBtnChosen &&
        <Translate>
          {({ translate }) => (
            <InputTag
              placeholder={translate('congrat.placeholder')}
              onChange={({ target: { value } }) => setMail(value)}
              value={mail}
              direction={currDirection}
            />
          )}
        </Translate>}
        <BtnWrapper isMailBtnChosen={isMailBtnChosen}>
          <Btn onClick={isMailBtnChosen ? sendMailClick : mailBtnClick}>
            <Translate id="congrat.button" />
          </Btn>
          {!isMailBtnChosen &&
          <FinalActions />}
        </BtnWrapper>
        <SocialMedia>
          <Translate>
            {({ translate }) => (
            <>
              <ShareBtnWrapper>
                <FacebookShareButton url={`${process.env.REACT_APP_SERVICE_URL}/share?id=${shareFinalCrestImageId}&l=${currLang}&fml=${selectedName}`} quote={translate('shareMessage')}>
                  <FacebookIcon size={45} round />
                </FacebookShareButton>
                {!isMobile &&
                <ShareTooltip id='share-tooltip' direction={currDirection}>
                  {translate('socialMedia.facebook')}
                </ShareTooltip>}
              </ShareBtnWrapper>
              <ShareBtnWrapper>
                <LinkedinShareButton url={`${process.env.REACT_APP_SERVICE_URL}/share?id=${shareFinalCrestImageId}&l=${currLang}&fml=${selectedName}`} title={translate('shareMessage')}>
                  <LinkedinIcon size={45} round />
                </LinkedinShareButton>
                {!isMobile &&
                <ShareTooltip id='share-tooltip' direction={currDirection}>
                  {translate('socialMedia.linkedin')}
                </ShareTooltip>}
              </ShareBtnWrapper>
              <ShareBtnWrapper>
                <TwitterShareButton url={`${process.env.REACT_APP_SERVICE_URL}/share?id=${shareFinalCrestImageId}&l=${currLang}&fml=${selectedName}`} title={translate('shareMessage')}>
                  <TwitterIcon size={45} round />
                </TwitterShareButton>
                {!isMobile &&
                <ShareTooltip id='share-tooltip' direction={currDirection}>
                  {translate('socialMedia.twitter')}
                </ShareTooltip>}
              </ShareBtnWrapper>
              <ShareBtnWrapper>
                <WhatsappShareButton url={`${process.env.REACT_APP_SERVICE_URL}/share?id=${shareFinalCrestImageId}&l=${currLang}&fml=${selectedName}`} title={translate('shareMessage')}>
                  <WhatsappIcon size={45} round />
                </WhatsappShareButton>
                {!isMobile &&
                <ShareTooltip id='share-tooltip' direction={currDirection}>
                  {translate('socialMedia.whatsapp')}
                </ShareTooltip>}
              </ShareBtnWrapper>
            </>
            )}
          </Translate>
        </SocialMedia>
      </InnerContainer>
    </Container>
  )
}

const mapStateToProps = ({ ui: { shareFinalCrestImageId }, userSelections: { selectedName } }) => ({
  shareFinalCrestImageId,
  selectedName,
})

export default connect(mapStateToProps)(withLocalize(FinalCrest))
