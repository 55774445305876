import React from 'react'
import { Container, SelectedSvg } from '../styles'

const classicShield = () => (
  <Container>
    <svg width="65" height="78" viewBox="0 0 65 78">
      <path fill="#FFF" fillRule="evenodd" stroke="#FBB034" strokeWidth="3.6" d="M60.95 6.537c-2.427.304-4.953.403-7.475.403-6.505 0-12.721-2.227-18.156-4.252-.679-.305-1.456-.506-2.135-.81-.292-.104-.679-.104-1.07 0-.678.2-1.357.505-2.135.81C24.54 4.713 18.33 6.94 11.824 6.94c-2.526 0-4.953-.103-7.476-.403-.485 0-.872.103-1.263.403-.292.305-.485.811-.485 1.214v36.857c0 17.214 28.058 30.58 29.321 31.086.194.103.387.103.679.103.292 0 .485 0 .679-.103C34.443 75.488 62.6 62.225 62.6 45.011V8.157c0-.506-.193-.91-.485-1.214-.194-.304-.68-.506-1.165-.407h0z"/>
    </svg>
    <SelectedSvg id="selected" width="65" height="79" viewBox="0 0 65 79">
      <path fill="#FFF" fillRule="evenodd" stroke="#414141" strokeWidth="3.6" d="M60.95 6.737c-2.427.304-4.953.403-7.475.403-6.505 0-12.721-2.227-18.156-4.252-.679-.305-1.456-.506-2.135-.81-.292-.104-.679-.104-1.07 0-.678.2-1.357.505-2.135.81C24.54 4.913 18.33 7.14 11.824 7.14c-2.526 0-4.953-.103-7.476-.403-.485 0-.872.103-1.263.403-.292.305-.485.811-.485 1.214v36.857c0 17.214 28.058 30.58 29.321 31.086.194.103.387.103.679.103.292 0 .485 0 .679-.103C34.443 75.688 62.6 62.425 62.6 45.211V8.357c0-.506-.193-.91-.485-1.214-.194-.304-.68-.506-1.165-.407h0z"/>
    </SelectedSvg>
  </Container>
)

export default classicShield