import React from 'react'
import { connect } from 'react-redux'
import { Translate, withLocalize } from 'react-localize-redux'
import { InputTag } from '../generalStyles/Input.styles'
import { updateState } from '../store/userSelections/actions'
import { DIRECTIONS } from '../consts'

function FamilyName({ dispatch, selectedName, activeLanguage }) {
  const currDirection = activeLanguage ? DIRECTIONS[activeLanguage.code] : 'ltr'

  return (
    <Translate>
      {({ translate }) => (
        <InputTag
            type='text'
            placeholder={translate('steps.1.placeholder')}
            onChange={({ target: { value } }) => dispatch(updateState('selectedName', value))}
            value={selectedName}
            direction={currDirection}
        >
        </InputTag>
      )}
    </Translate>
  )
}

const mapStateToProps = ({ userSelections: { selectedName }}) => ({ selectedName })

export default connect(mapStateToProps)(withLocalize(FamilyName))
