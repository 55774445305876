import Immutable from 'seamless-immutable'
import { handleActions } from 'redux-actions'
import types from './types'
import userSelectionsTypes from '../userSelections/types'

const initialState = Immutable({
  isFinalCrestDisplay: false,
  isLoaderDisplay: false,
  isReceiveMailDisplay: false,
  // isPrintModeDisplay: false,
  maxEnableStep: 1, // disabled from this (step + 1) on
  currentStep: 1,
  isError: false,
  isCrestBuilderHidden: true,
  shareFinalCrestImageId: '', // share image in social media - from server
})

export default handleActions({
  [types.setIsFinalCrestDisplay]: (state, { value }) => state.set(['isFinalCrestDisplay'], value),
  [types.setIsLoaderDisplay]: (state, { value }) => state.set(['isLoaderDisplay'], value),
  [types.setIsReceiveMailDisplay]: (state, { value }) => state.set(['isReceiveMailDisplay'], value),
  // [types.setIsPrintModeDisplay]: (state, { value }) => state.set(['isPrintModeDisplay'], value),
  [types.setIsCrestBuilderHidden]: (state) => state.set(['isCrestBuilderHidden'], false),
  [userSelectionsTypes.reset]: () => (Immutable({ ...initialState, isCrestBuilderHidden: false })),
  [types.startPrintMode]: (state) => state
    .merge({ 
      isReceiveMailDisplay: false,
      isFinalCrestDisplay: false, 
      // isPrintModeDisplay: true
     }),
  [userSelectionsTypes.updateState]: (state, { maxEnableStep }) => state.set('maxEnableStep', maxEnableStep),
  [types.setCurrentStep]: (state, { value }) => state.set(['currentStep'], value),
  [types.setIsError]: (state, { value }) => state.set(['isError'], value),
  [types.setShareFinalCrestImageId]: (state, { value }) => state.set(['shareFinalCrestImageId'], value),
}, initialState)
