import React from 'react'
import { Container, SelectedSvg } from '../styles'

const asia = () => (
  <Container>
    <svg width="124" height="75" viewBox="0 0 124 75">
      <path fill="#FFF" fillRule="evenodd" stroke="#BF311A" strokeWidth="3.24" d="M11.403 30.488c-.84 1.695-4.22 1.814-4.954 3.293-.269.542 1.763 1.551 2.027 1.817 1.212 1.223 4.495 5.363 3.603 8.063-.46 1.392-11.674-.042-10.134 3.066 1.198 2.416 5.279.327 6.643 1.703.999 1.007-1.223 5.807-.112 6.927 4.135 4.17 2.223 13.474 10.471 11.81 3.368-.68 4.515-2.055 6.643-4.201 1.49-1.504.349-7.075 4.842-5.565 6.713 2.257 5.743 5.567 8.444 11.015.634 1.28.417 3.423 1.328 4.308.91.884 2.6-6.21 3.176-7.374 1.449-2.921 4.056-3.863 6.306-6.132.155-.157.587.138.675.227 1.862 1.878 1.395 5.268 3.153 7.04 2.083 2.102 5.56 2.089 7.544 4.089.752.114 1.427 1.325 2.252.908.045-.022 0-.79 0-.794 0-2.252-1.926-5.77-.901-7.836.606-1.224 2.486-1.18 3.603-1.93 3.15-2.119 5.726-4.64 8.107-7.041 1.637-1.651-.272-6.86-1.464-8.063-.041-.042-.408-.724-.225-.908 1.799-1.815 5.321 3.036 6.08 2.27.813-.82-.581-2.674.675-3.52 1.923-1.292 4.702-1.561 6.306-3.179.92-.927 1.223-3.342 2.477-3.975.692-.348 2.21-.526 2.815-1.135 1.774-1.79-2.65-3.755-3.266-4.997-1.161-2.343 1.5-3.884 3.153-4.996 2.328-1.566 4.64-.262 7.206-.909.917-.231 6.651-2.36 6.869-1.703.54 1.633-2.295 2.105-3.266 2.839-2.27 1.717-2.617 5.196-.563 7.268.612.616 4.141-5.069 4.504-5.678.54-.909 1.464-1.978 1.464-2.953 0-.192.027-.354.225-.454 2.064-1.041 13.783-6.293 14.525-7.04.782-.79-1.417-3.34-2.027-2.726-1.266 1.277-7.576.732-9.233.454-7.117-1.196-13.966-3.226-21.055-4.656-6.385-1.288-12.595-2.385-19.141-2.385-.658 0-6.14.56-6.418 0-.49-.986 3.037-1.6 2.477-3.293-.417-1.262-6.173-2.505-7.544-2.044-6.177 2.077-12.398 6.019-19.254 6.019-1.879 0-4.962-1.075-6.418-.34-2.312 1.165-3.588 2.985-6.53 3.974-4.827 1.622-9.05.383-12.498 3.86 1.358 5.481-.596 10.855-2.59 14.877z"/>
    </svg>
    <SelectedSvg id="selected" width="124" height="75" viewBox="0 0 124 75">
      <path fill="#FFF" fillRule="evenodd" stroke="#414141" strokeWidth="3.24" d="M11.403 30.488c-.84 1.695-4.22 1.814-4.954 3.293-.269.542 1.763 1.551 2.027 1.817 1.212 1.223 4.495 5.363 3.603 8.063-.46 1.392-11.674-.042-10.134 3.066 1.198 2.416 5.279.327 6.643 1.703.999 1.007-1.223 5.807-.112 6.927 4.135 4.17 2.223 13.474 10.471 11.81 3.368-.68 4.515-2.055 6.643-4.201 1.49-1.504.349-7.075 4.842-5.565 6.713 2.257 5.743 5.567 8.444 11.015.634 1.28.417 3.423 1.328 4.308.91.884 2.6-6.21 3.176-7.374 1.449-2.921 4.056-3.863 6.306-6.132.155-.157.587.138.675.227 1.862 1.878 1.395 5.268 3.153 7.04 2.083 2.102 5.56 2.089 7.544 4.089.752.114 1.427 1.325 2.252.908.045-.022 0-.79 0-.794 0-2.252-1.926-5.77-.901-7.836.606-1.224 2.486-1.18 3.603-1.93 3.15-2.119 5.726-4.64 8.107-7.041 1.637-1.651-.272-6.86-1.464-8.063-.041-.042-.408-.724-.225-.908 1.799-1.815 5.321 3.036 6.08 2.27.813-.82-.581-2.674.675-3.52 1.923-1.292 4.702-1.561 6.306-3.179.92-.927 1.223-3.342 2.477-3.975.692-.348 2.21-.526 2.815-1.135 1.774-1.79-2.65-3.755-3.266-4.997-1.161-2.343 1.5-3.884 3.153-4.996 2.328-1.566 4.64-.262 7.206-.909.917-.231 6.651-2.36 6.869-1.703.54 1.633-2.295 2.105-3.266 2.839-2.27 1.717-2.617 5.196-.563 7.268.612.616 4.141-5.069 4.504-5.678.54-.909 1.464-1.978 1.464-2.953 0-.192.027-.354.225-.454 2.064-1.041 13.783-6.293 14.525-7.04.782-.79-1.417-3.34-2.027-2.726-1.266 1.277-7.576.732-9.233.454-7.117-1.196-13.966-3.226-21.055-4.656-6.385-1.288-12.595-2.385-19.141-2.385-.658 0-6.14.56-6.418 0-.49-.986 3.037-1.6 2.477-3.293-.417-1.262-6.173-2.505-7.544-2.044-6.177 2.077-12.398 6.019-19.254 6.019-1.879 0-4.962-1.075-6.418-.34-2.312 1.165-3.588 2.985-6.53 3.974-4.827 1.622-9.05.383-12.498 3.86 1.358 5.481-.596 10.855-2.59 14.877z"/>
    </SelectedSvg>
  </Container>
)

export default asia
