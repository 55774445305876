import React from 'react'
import { Translate, withLocalize } from 'react-localize-redux'
import {
  Container,
  ModalContent,
  Title,
  Content,
  CloseWrapper,
} from './Modal.styles'
import ModalDecoLeft from '../../assets/modalDecoLeft'
import ModalDecoRight from '../../assets/modalDecoRight'
import StartOverBtn from '../StartOverBtn/StartOverBtn'
import CloseIcon from '../../assets/close'
import { DIRECTIONS } from '../../consts'

function Modal({
  title, content, onClick, isOpen, isStartOver,
  activeLanguage, handleInnerClickBtn,
}) {
  const currDirection = activeLanguage ? DIRECTIONS[activeLanguage.code] : 'ltr'

  return (
    <Container isOpen={isOpen} onClick={() => onClick(false)} direction={currDirection}>
      <ModalContent>
        <ModalDecoLeft />
        <ModalDecoRight />
        <CloseWrapper onClick={() => onClick(false)}>
          <CloseIcon />
        </CloseWrapper>
        <Title id='modal-title' >
          <Translate id={title} />
        </Title>
        <Content id='modal-content'>
          <Translate id={content} />
        </Content>
        {isStartOver && <StartOverBtn onClick={handleInnerClickBtn}/>}
      </ModalContent>
    </Container>
  )
}

export default withLocalize(Modal)
