import React, { useState } from 'react'
import { Info } from './InfoModal.styles'
import Modal from '../Modal/Modal'

function InfoModal() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Info onClick={() => setIsOpen(true)}>
        <i class="fa fa-info-circle"></i>
      </Info>
      <Modal
        title='infoModal.title'
        content='infoModal.text'
        onClick={(value => setIsOpen(value))}
        isOpen={isOpen}
      />
    </>
  )
}

export default InfoModal
