import React from 'react'
import { Container, SelectedSvg } from '../styles'

const bookShield = () => (
  <Container>
    <svg width="90" height="70" viewBox="0 0 90 70">
      <path fill="none" fillRule="evenodd" stroke="#47C3D2" strokeWidth="3.6" d="M85.639 2.6H56.835C51.919 2.6 47.572 5.098 45 8.894 42.427 5.098 38.085 2.6 33.165 2.6H4.361C2.947 2.6 1.8 3.748 1.8 5.162v53.127c0 1.414 1.147 2.562 2.561 2.562h28.804c3.506 0 6.653 1.962 8.213 5.12.43.874 1.32 1.429 2.295 1.429h1.218c.038 0 .072-.011.109-.011.037.004.071.011.109.011h1.218c.975 0 1.864-.551 2.295-1.43 1.56-3.157 4.707-5.119 8.213-5.119h28.804c1.414 0 2.561-1.148 2.561-2.562l-.004-53.127C88.2 3.748 87.053 2.6 85.64 2.6h0z"/>
    </svg>
    <SelectedSvg id="selected" width="90" height="69" viewBox="0 0 90 69">
      <path fill="#FFF" fillRule="evenodd" stroke="#414141" strokeWidth="3.6" d="M85.639 2.4H56.835C51.919 2.4 47.572 4.898 45 8.694 42.427 4.898 38.085 2.4 33.165 2.4H4.361C2.947 2.4 1.8 3.548 1.8 4.962v53.127c0 1.414 1.147 2.562 2.561 2.562h28.804c3.506 0 6.653 1.962 8.213 5.12.43.874 1.32 1.429 2.295 1.429h1.218c.038 0 .072-.011.109-.011.037.004.071.011.109.011h1.218c.975 0 1.864-.551 2.295-1.43 1.56-3.157 4.707-5.119 8.213-5.119h28.804c1.414 0 2.561-1.148 2.561-2.562l-.004-53.127C88.2 3.548 87.053 2.4 85.64 2.4h0z"/>
    </SelectedSvg>
  </Container>
)

export default bookShield
