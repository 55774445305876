import React from 'react'
import { Container, SelectedSvg } from '../styles'

const europe = () => (
  <Container>
    <svg width="91" height="83" viewBox="0 0 91 83">
      <g fill="none" fillRule="evenodd" stroke="#47C3D2" strokeWidth="3.6">
        <path d="M13.002 27.619c-7.89-2.578-1.08 7.712-1.08 11.22 0 1.48-2.862 6.298-1.728 7.41.212.207 12.702-4.063 9.504-7.198-2.574-2.523-5.086-7.683-6.696-11.432h0zM4.31 40.44C3.031 36.125.43 42.637 1 45.197c.73 3.289 4.677 2.924 3.31-4.757h0z" transform="translate(2.2 2.4)"/>
        <path d="M11.926 51.728c-.636 2.497 1.1 9.858-.429 11.358-4.407 4.326-8.446-4.909-11.143 5.68-2.637 10.354 10.121 11.52 15.857 5.89 3.999-3.925-1.926-9.467 7.715-12.621 5.408-1.77 8.62 3.24 12.857 4.627 4.549 1.489 6.652-2.1 9.857-1.051 5.474 1.79 10.46 6.645 16.285 3.786 2.757-1.353.35-7.477 3.215-8.414 2.298-.752 9.67.157 10.714-1.893 2.647-5.198-12.219-6.937-9.214-9.886 4.186-4.11 12.014-8.021 15-13.883 2.522-4.951 1.173-10.919 2.57-16.406.471-1.846 1.806-6.974.858-8.835-1.605-3.15-11.248-5.231-14.786-6.1C60.136 1.246 52.845-2.85 41.068 2.93c-2.545 1.249-4.724 10.74-6.642 13.251-4.088 5.35-11.196 4.945-6.858 13.462.438.859-.237 2.922.429 3.576 1.855 1.82 6.125-2.191 6.643-1.683 2.13 2.09-2.93 3.997 3.428 3.997 1.856 0 5.412.578 6.429-.421.7-.686.904-4.509 1.5-5.68.401-.787 3.64-1.897 2.786-3.575-1.16-2.276-2.915-3.91-2.143-6.941.383-1.505 6.246-7.541 8.571-5.259 2.75 2.7-4.783 6.109-3.643 9.466 1.04 3.063 8.037 1.574 7.072 6.31-2.03 9.961-21.175 9.551-27.429 12.62-1.1.54-5.251-.314-5.785.21-1.186 1.164-.022 4.67-.858 6.31-.156.307-10.478 3.05-12.642 3.156h0z" transform="translate(2.2 2.4)"/>
      </g>
    </svg>
    <SelectedSvg id="selected" width="91" height="82" viewBox="0 0 91 82">
      <g fill="#FFF" fillRule="evenodd" stroke="#414141" strokeWidth="3.6">
        <path d="M13.002 27.619c-7.89-2.578-1.08 7.712-1.08 11.22 0 1.48-2.862 6.298-1.728 7.41.212.207 12.702-4.063 9.504-7.198-2.574-2.523-5.086-7.683-6.696-11.432h0zM4.31 40.44C3.031 36.125.43 42.637 1 45.197c.73 3.289 4.677 2.924 3.31-4.757h0z" transform="translate(2.6 2.2)"/>
        <path d="M11.926 51.728c-.636 2.497 1.1 9.858-.429 11.358-4.407 4.326-8.446-4.909-11.143 5.68-2.637 10.354 10.121 11.52 15.857 5.89 3.999-3.925-1.926-9.467 7.715-12.621 5.408-1.77 8.62 3.24 12.857 4.627 4.549 1.489 6.652-2.1 9.857-1.051 5.474 1.79 10.46 6.645 16.285 3.786 2.757-1.353.35-7.477 3.215-8.414 2.298-.752 9.67.157 10.714-1.893 2.647-5.198-12.219-6.937-9.214-9.886 4.186-4.11 12.014-8.021 15-13.883 2.522-4.951 1.173-10.919 2.57-16.406.471-1.846 1.806-6.974.858-8.835-1.605-3.15-11.248-5.231-14.786-6.1C60.136 1.246 52.845-2.85 41.068 2.93c-2.545 1.249-4.724 10.74-6.642 13.251-4.088 5.35-11.196 4.945-6.858 13.462.438.859-.237 2.922.429 3.576 1.855 1.82 6.125-2.191 6.643-1.683 2.13 2.09-2.93 3.997 3.428 3.997 1.856 0 5.412.578 6.429-.421.7-.686.904-4.509 1.5-5.68.401-.787 3.64-1.897 2.786-3.575-1.16-2.276-2.915-3.91-2.143-6.941.383-1.505 6.246-7.541 8.571-5.259 2.75 2.7-4.783 6.109-3.643 9.466 1.04 3.063 8.037 1.574 7.072 6.31-2.03 9.961-21.175 9.551-27.429 12.62-1.1.54-5.251-.314-5.785.21-1.186 1.164-.022 4.67-.858 6.31-.156.307-10.478 3.05-12.642 3.156h0z" transform="translate(2.6 2.2)"/>
      </g>
    </SelectedSvg>
  </Container>
)

export default europe
