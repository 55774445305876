import { createStore, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './store/reducers'

const composeEnhancers = process.env.NODE_ENV === 'production' ? compose : composeWithDevTools

const store = createStore(
  rootReducer,
  composeEnhancers()
)

export default store