import types from './types'
import { defaultsUserSelectionsState } from '../../consts'
import store from '../../initializer'

const updateState = (key, value) => {
    const { ui: { maxEnableStep, currentStep } } = store.getState()

    let newMaxEnableStep = maxEnableStep

    if (value !== defaultsUserSelectionsState[key]) {
      newMaxEnableStep = Math.max(maxEnableStep, currentStep + 1)
    }

    if (key === 'selectedName' && maxEnableStep === 2 && !value) {
      newMaxEnableStep = 1
    }

    return ({
      type: types.updateState,
      key,
      value,
      maxEnableStep: newMaxEnableStep,
    })
}

const reset = () => ({
  type: types.reset,
})

export {
  updateState,
  reset,
}