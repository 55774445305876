import styled from 'styled-components/macro'
import device from '../../lib/devices'

const LangContainer = styled.div(({ isCrestBuilderHidden }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  position: 'relative',
  width: '100%',
  maxWidth: '50rem',
  zIndex: 3,
  [isCrestBuilderHidden && `@media ${device.mobileL}`]: {
    marginInlineEnd: '10rem',
  }
}))

const LangBtn = styled.button({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  borderBottom: '1px solid #232e83',
  color: '#232e83',
  fontSize: '16rem',
  fontWeight: 800,
  padding: 0,
  margin: '0 5rem',
  outline: 'none',
})

const Arrow = styled.div({
  width: 0,
  height: 0,
  borderLeft: '5px solid transparent',
  borderRight: '5px solid transparent',
  borderTop: '5px solid #232e83',
})

const Dropdown = styled.div({
  position: 'absolute',
  fontSize: '16rem',
  width: '70rem',
  top: '30px',
  left: '-33px',
  textAlign: 'center',
  boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
  cursor: 'pointer',
})

const DropdownItem = styled.div({
  background: 'white',
  border: '1px solid #232e83',
  padding: '5rem',
  '&:hover': {
    background: '#232e83',
    color: 'white',
  }
})

export {
  LangContainer,
  LangBtn,
  Arrow,
  Dropdown,
  DropdownItem,
}