import React from 'react'
import { Container, SelectedSvg } from '../styles'

const twoTabletsShield = () => (
  <Container>
    <svg width="54" height="59" viewBox="0 0 54 59">
      <path fill="none" fillRule="evenodd" stroke="#BF311A" strokeWidth="3.42" d="M40.255 1.71c-4.357 0-8.172 2.315-10.286 5.78-.56.919-.993 1.906-1.29 2.933-.41-.998-.934-1.949-1.562-2.83-2.538-3.56-6.702-5.883-11.408-5.883-3.866 0-7.365 1.567-9.899 4.1-2.533 2.534-4.1 6.033-4.1 9.9v40.752c0 .322-.025.466.037.59.125.063.27.038.591.038h49.324c.322 0 .466.025.59-.037.063-.125.038-.27.038-.591V13.745c0-3.323-1.347-6.332-3.525-8.51s-5.187-3.525-8.51-3.525z"/>
    </svg>
    <SelectedSvg id="selected" width="54" height="60" viewBox="0 0 54 60">
      <path fill="#FFF" fillRule="evenodd" stroke="#414141" strokeWidth="3.42" d="M40.255 2.51c-4.357 0-8.172 2.315-10.286 5.78-.56.919-.993 1.906-1.29 2.933-.41-.998-.934-1.949-1.562-2.83-2.538-3.56-6.702-5.883-11.408-5.883-3.866 0-7.365 1.567-9.899 4.1-2.533 2.534-4.1 6.033-4.1 9.9v40.752c0 .322-.025.466.037.59.125.063.27.038.591.038h49.324c.322 0 .466.025.59-.037.063-.125.038-.27.038-.591V14.545c0-3.323-1.347-6.332-3.525-8.51s-5.187-3.525-8.51-3.525z"/>
    </SelectedSvg>
  </Container>
)

export default twoTabletsShield
