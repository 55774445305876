import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Translate, withLocalize } from 'react-localize-redux'
import {
  Container,
  Navigation,
  Content,
} from './DirectionArrows.styles'
import Back from '../../assets/back'
import Next from '../../assets/next'
import {
  setIsFinalCrestDisplay,
  setCurrentStep,
  setIsError,
} from '../../store/ui/actions'
import {
  shieldsId,
  occupationsId,
  attributesId,
  featuresId,
  mottosId,
  continentsId,
} from '../../consts'
import steps from '../../lib/steps'
import { setLogoBase64 } from '../../lib/finalLogoBase64'

function DirectionArrows(props) {
  const {
    dispatch, userSelections, maxEnableStep,
    currentStep, activeLanguage,
  } = props
  const {
    selectedName,
    selectedShield,
    selectedOccupation,
    selectedAttribute,
    selectedFeatures,
    selectedMotto,
    selectedContinent,
  } = userSelections
  const [isDone, setIsDone] = useState(false)
  const currLang = activeLanguage ? activeLanguage.code : 'en'

  useEffect(() => {
    if (selectedName && selectedShield !== shieldsId.default
      && selectedOccupation !== occupationsId.default
      && selectedAttribute !== attributesId.default
      && selectedFeatures[0] !== featuresId.defaultLeft
      && selectedFeatures[1] !== featuresId.defaultRight
      && selectedMotto !== mottosId.default
      && selectedContinent !== continentsId.default) {
        return setIsDone(true)
      }
      setIsDone(false)
  }, [selectedName,
    selectedShield,
    selectedOccupation,
    selectedAttribute,
    selectedFeatures,
    selectedMotto,
    selectedContinent])

  async function handleClick(direction) {
    if (isDone && direction !== 'back') {
      try {
        await setLogoBase64()
        dispatch(setIsFinalCrestDisplay(true))
      } catch (e) {
        console.log(e)
        dispatch(setIsError(true))
      }
    }

    let step = 1
    if (direction === 'back') {
      step = currentStep > 1 ? currentStep - 1 : 1
    } else {
      step = currentStep < steps.length ? currentStep + 1 : steps.length
    }
    dispatch(setCurrentStep(step))
  }

  return (
    <Container currLang={currLang}>
      <Navigation
        onClick={() => handleClick('next')}
        disabled={maxEnableStep <= currentStep}
        currLang={currLang}
      >
        {isDone ?
        <Content>
          <Translate id="navigation.finish" />
        </Content> :
        <Content>
          <Translate id="navigation.next" />
        </Content>}
        <Next />
      </Navigation>
      <Navigation
        onClick={() => handleClick('back')}
        disabled={currentStep === 1}
        currLang={currLang}
      >
        <Back />
        <Content>
          <Translate id="navigation.back" />
        </Content>
      </Navigation>
    </Container>
  );
}

const mapStateToProps = ({ userSelections, ui: { maxEnableStep, currentStep } }) => ({
  userSelections,
  maxEnableStep,
  currentStep,
})

export default connect(mapStateToProps)(withLocalize(DirectionArrows))
