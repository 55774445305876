import React from 'react'
import { Container, SelectedSvg } from '../styles'

const fig = () => (
  <Container>
    <svg width="53" height="50" viewBox="0 0 53 50">
      <g fill="none" fillRule="evenodd" stroke="#47C3D2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.28" transform="translate(-9.6)">
        <path d="M45.178 11.172c0 4.733 3.675 3.087 3.675 8.03"/>
        <path fill="#FFF" d="M41.762 41.753c.533 0 2.242.507 2.764.469 9.963-.74 18.32-8.62 16.616-17.77-1.967-10.572-11.084-8.93-14.77-15.662-.474-.868-2.723-6.787-4.61-6.787-1.887 0 .879 4.377-1.55 6.787-9.437 9.367-14.421 6.903-16 16.907-1.579 10.004 5.85 16.134 16 16.525.318.012 1.226-.47 1.55-.47z"/>
        <path d="M51.427 16.37s12.215 7.522 3.05 19.157M27.34 22.528c-3.08 6.424-.584 14.764 6.384 16.746M43.234 8.386c1.032 6.285-5.543 6.674-9.195 10.3M43.038 40.292s4.027.058 4.532-4.078"/>
        <path fill="#FFF" d="M35.396 44.233c.462-.267 2.195-.681 2.628-.976 8.259-5.621 11.555-16.625 5.505-23.697-6.99-8.172-14.063-2.192-20.622-6.18-.845-.513-5.752-4.515-7.386-3.572-1.634.944 3.054 2.926 1.97 6.82-3.564 12.804-8.956 13.022-5.322 22.475 3.635 9.453 13.134 11.047 22.12 6.31.28-.147.827-1.018 1.107-1.18z"/>
        <ellipse cx="28.46" cy="31.184" fill="#FFF" rx="13.6" ry="11.631" transform="rotate(-34 28.46 31.184)"/>
        <circle cx="30.8" cy="22.528" r="1" fill="#47C3D2"/>
        <circle cx="32.84" cy="28.001" r="1" fill="#47C3D2"/>
        <circle cx="30.8" cy="37.58" r="1" fill="#47C3D2"/>
        <circle cx="37.6" cy="30.054" r="1" fill="#47C3D2"/>
        <circle cx="19.92" cy="33.475" r="1" fill="#47C3D2"/>
        <circle cx="22.64" cy="26.633" r="1" fill="#47C3D2"/>
        <circle cx="26.72" cy="32.106" r="1" fill="#47C3D2"/>
        <circle cx="24.68" cy="37.58" r="1" fill="#47C3D2"/>
      </g>
    </svg>
    <SelectedSvg id="selected" width="53" height="49" viewBox="0 0 53 49">
      <g fill="none" fillRule="evenodd" stroke="#414141" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.28" transform="translate(-9.6 -.2)">
        <path d="M45.178 11.172c0 4.733 3.675 3.087 3.675 8.03"/>
        <path fill="#FFF" d="M41.762 41.753c.533 0 2.242.507 2.764.469 9.963-.74 18.32-8.62 16.616-17.77-1.967-10.572-11.084-8.93-14.77-15.662-.474-.868-2.723-6.787-4.61-6.787-1.887 0 .879 4.377-1.55 6.787-9.437 9.367-14.421 6.903-16 16.907-1.579 10.004 5.85 16.134 16 16.525.318.012 1.226-.47 1.55-.47z"/>
        <path d="M51.427 16.37s12.215 7.522 3.05 19.157M27.34 22.528c-3.08 6.424-.584 14.764 6.384 16.746M43.234 8.386c1.032 6.285-5.543 6.674-9.195 10.3M43.038 40.292s4.027.058 4.532-4.078"/>
        <path fill="#FFF" d="M35.396 44.233c.462-.267 2.195-.681 2.628-.976 8.259-5.621 11.555-16.625 5.505-23.697-6.99-8.172-14.063-2.192-20.622-6.18-.845-.513-5.752-4.515-7.386-3.572-1.634.944 3.054 2.926 1.97 6.82-3.564 12.804-8.956 13.022-5.322 22.475 3.635 9.453 13.134 11.047 22.12 6.31.28-.147.827-1.018 1.107-1.18z"/>
        <ellipse cx="28.46" cy="31.184" fill="#FFF" rx="13.6" ry="11.631" transform="rotate(-34 28.46 31.184)"/>
        <circle cx="30.8" cy="22.528" r="1" fill="#47C3D2"/>
        <circle cx="32.84" cy="28.001" r="1" fill="#47C3D2"/>
        <circle cx="30.8" cy="37.58" r="1" fill="#47C3D2"/>
        <circle cx="37.6" cy="30.054" r="1" fill="#47C3D2"/>
        <circle cx="19.92" cy="33.475" r="1" fill="#47C3D2"/>
        <circle cx="22.64" cy="26.633" r="1" fill="#47C3D2"/>
        <circle cx="26.72" cy="32.106" r="1" fill="#47C3D2"/>
        <circle cx="24.68" cy="37.58" r="1" fill="#47C3D2"/>
      </g>
    </SelectedSvg>
  </Container>
)

export default fig
