import React from 'react'
import { Container, SelectedSvg } from '../styles'

const northAmerica = () => (
  <Container>
    <svg width="119" height="86" viewBox="0 0 119 86">
      <g fill="none" fillRule="evenodd" stroke="#BF311A" strokeWidth="3.6">
        <path d="M82.176 1.024c0 4.49-9.932 8.212-8.342 11.452.94 1.918 4.508-.61 5.788.695 2.198 2.24 2.122 6.5 3.916 8.328.781.797 2.857-.422 3.575 1.042 1.666 3.396-2.834 5.613 3.405 11.973.59.601 1.894-2.474 2.043-2.777.83-1.693 2.901-4.428 4.426-5.205 3.007-1.532 8.712-3.327 10.726-5.38 2.413-2.46.512-8.678 1.872-11.452.683-1.39 5.323-4.42 4.257-6.594-1.026-2.09-6.097-.642-8.342-1.214C98.622.139 89.023-.88 82.176 1.024h0z" transform="translate(2.6 2.2)"/>
        <path d="M68.628.85c1.365 0 4.492-1.656 5.79-.345.43.436-.626 1.265-.681 1.377-1.35 2.728-3.762 5.54-5.11 8.263-.604 1.223-3.235.893-3.235 1.893 0 .347.031.75.17 1.033 2.004 4.05 7.293 5.822 10.388 8.95.223.226-.082 1.199-.17 1.378-1.742 3.523-.432 8.077.851 10.672.945 1.91 4.398 3.068 5.96 4.648.824.832-.305 3.134.511 3.959.342.345 2.401 1.686 1.873 2.754-.16.326-.5.344-.851.344-4.161 0-2.54-1.348-3.746-3.787-.333-.673-4.94.173-5.28.517-1.546 1.563 4.174 2.58 3.066 4.82-1.106 2.235-5.006-1.412-6.47 1.549-1.441 2.911-2.785 6.945-5.11 9.295-.564.57-1.931-.114-2.554.516-1.3 1.314 1.497 5.717.17 7.058-.363.367-.92-.587-1.021-.689-1.756-1.775-1.227-2.6-3.917-3.96-.35-.176-2.245 1.331-2.895 1.55-3.267 1.101-5.62-.017-5.62 3.96 0 1.511.322 4.465 1.704 5.163 2.386 1.206 2.377-3.847 5.108-2.926 2.402.81.5 3.937 1.363 5.68.91 1.84 2.405-.323 3.576.86.846.857-1.266 2.165-.341 3.1.771.78 5.606 1.906 4.768 2.753-1.734 1.753-7.178-3.37-7.663-3.614-1.025-.519-12.163-5.066-12.26-5.164-2.159-2.182-2.13-6.285-4.598-8.78-.096-.096-1.46-1.967-1.703-1.72-.515.52 1.307 3.553.681 4.82-.12.241-5.729-3.32-6.3-4.476-2.531-5.116-3.493-12.998-6.642-16.18-.935-.946 1.857-2.427 1.022-3.271-.656-.663-2.624-.071-3.235-.689-4.538-4.586-4.864-8.33-11.41-9.983-4.084-1.033-8.193 4.637-9.195 4.13-1.234-.623-4.61-4.164-5.109-5.68-.522-1.583 2.17-1.636 1.533-2.926-.62-1.252-2.644-3.95-1.873-5.508.31-.628.382-3.292 1.021-3.615 6.3-3.184 14.852 3.735 21.457 2.066.842-.213 8.892-8.645 10.217-9.984.839-.848 5.101.644 5.96.86 4.07 1.03 10.719 3.453 13.963.173 1.297-1.311-2.213-7.556-2.213-9.123 3.956-1.09 10.37 1.213 13.793-.517.852-.43 3.373-1.205 4.257-1.205zm-9.379 26.468c-1.213.41-2.249 2.619-2.868 3.246-.952.963-3.662.29-4.556 1.195-.04.04 0 .114 0 .171 0 1.942 7.65 2.79 9.28 4.44 1.502 1.521 1.607 5.426 3.713 6.492 1.215.614.389-3.18.675-3.758.475-.962 2.37-4.077 2.024-5.124-.248-.754-.018-.18-.337-.342-3.399-1.72-4.93-3.958-7.93-6.32z" transform="translate(2.6 2.2)"/>
      </g>
    </svg>
    <SelectedSvg id="selected" width="118" height="86" viewBox="0 0 118 86">
      <g fill="#FFF" fillRule="evenodd" stroke="#414141" strokeWidth="3.6">
        <path d="M82.176 1.024c0 4.49-9.932 8.212-8.342 11.452.94 1.918 4.508-.61 5.788.695 2.198 2.24 2.122 6.5 3.916 8.328.781.797 2.857-.422 3.575 1.042 1.666 3.396-2.834 5.613 3.405 11.973.59.601 1.894-2.474 2.043-2.777.83-1.693 2.901-4.428 4.426-5.205 3.007-1.532 8.712-3.327 10.726-5.38 2.413-2.46.512-8.678 1.872-11.452.683-1.39 5.323-4.42 4.257-6.594-1.026-2.09-6.097-.642-8.342-1.214C98.622.139 89.023-.88 82.176 1.024h0z" transform="translate(2.2 1.8)"/>
        <path d="M68.628.85c1.365 0 4.492-1.656 5.79-.345.43.436-.626 1.265-.681 1.377-1.35 2.728-3.762 5.54-5.11 8.263-.604 1.223-3.235.893-3.235 1.893 0 .347.031.75.17 1.033 2.004 4.05 7.293 5.822 10.388 8.95.223.226-.082 1.199-.17 1.378-1.742 3.523-.432 8.077.851 10.672.945 1.91 4.398 3.068 5.96 4.648.824.832-.305 3.134.511 3.959.342.345 2.401 1.686 1.873 2.754-.16.326-.5.344-.851.344-4.161 0-2.54-1.348-3.746-3.787-.333-.673-4.94.173-5.28.517-1.546 1.563 4.174 2.58 3.066 4.82-1.106 2.235-5.006-1.412-6.47 1.549-1.441 2.911-2.785 6.945-5.11 9.295-.564.57-1.931-.114-2.554.516-1.3 1.314 1.497 5.717.17 7.058-.363.367-.92-.587-1.021-.689-1.756-1.775-1.227-2.6-3.917-3.96-.35-.176-2.245 1.331-2.895 1.55-3.267 1.101-5.62-.017-5.62 3.96 0 1.511.322 4.465 1.704 5.163 2.386 1.206 2.377-3.847 5.108-2.926 2.402.81.5 3.937 1.363 5.68.91 1.84 2.405-.323 3.576.86.846.857-1.266 2.165-.341 3.1.771.78 5.606 1.906 4.768 2.753-1.734 1.753-7.178-3.37-7.663-3.614-1.025-.519-12.163-5.066-12.26-5.164-2.159-2.182-2.13-6.285-4.598-8.78-.096-.096-1.46-1.967-1.703-1.72-.515.52 1.307 3.553.681 4.82-.12.241-5.729-3.32-6.3-4.476-2.531-5.116-3.493-12.998-6.642-16.18-.935-.946 1.857-2.427 1.022-3.271-.656-.663-2.624-.071-3.235-.689-4.538-4.586-4.864-8.33-11.41-9.983-4.084-1.033-8.193 4.637-9.195 4.13-1.234-.623-4.61-4.164-5.109-5.68-.522-1.583 2.17-1.636 1.533-2.926-.62-1.252-2.644-3.95-1.873-5.508.31-.628.382-3.292 1.021-3.615 6.3-3.184 14.852 3.735 21.457 2.066.842-.213 8.892-8.645 10.217-9.984.839-.848 5.101.644 5.96.86 4.07 1.03 10.719 3.453 13.963.173 1.297-1.311-2.213-7.556-2.213-9.123 3.956-1.09 10.37 1.213 13.793-.517.852-.43 3.373-1.205 4.257-1.205zm-9.379 26.468c-1.213.41-2.249 2.619-2.868 3.246-.952.963-3.662.29-4.556 1.195-.04.04 0 .114 0 .171 0 1.942 7.65 2.79 9.28 4.44 1.502 1.521 1.607 5.426 3.713 6.492 1.215.614.389-3.18.675-3.758.475-.962 2.37-4.077 2.024-5.124-.248-.754-.018-.18-.337-.342-3.399-1.72-4.93-3.958-7.93-6.32z" transform="translate(2.2 1.8)"/>
      </g>
    </SelectedSvg>
  </Container>
)

export default northAmerica
