import React from 'react'
import {
  Container,
  LoaderSvg,
} from './Loader.styles'

function Loader() {
  return (
    <Container>
      <LoaderSvg width="200px" height="auto" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <path d="M36 50A14 14 0 0 0 64 50A14 15.9 0 0 1 36 50" fill="#1d3f72" stroke="none" transform="rotate(85.2239 50 50.95)">
          <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50.95;360 50 50.95"></animateTransform>
        </path>
      </LoaderSvg>
    </Container>
  );
}

export default Loader
