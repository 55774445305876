import styled from 'styled-components/macro'
import device from '../lib/devices'

const InputTag = styled.input(({ direction = 'ltr' }) => ({
  boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.15)',
  border: 'solid 2.4px #47c3d2',
  background: '#ffffff',
  width: 'calc(100% - 40rem - 45rem)',
  padding: '10rem',
  outline: 'none',
  marginInlineStart: '20rem',
  marginBottom: '20rem',
  direction: direction,
  '&:placeholder': {
    color: '#414141',
    lineHeight: 1.13,
    fontSize: '16rem',
  },
  [`@media ${device.tablet}`]: {
    marginInlineStart: 'unset',
    width: '80%',
    fontSize: '16rem',
  },
}))

export {
  InputTag,
}