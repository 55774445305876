import React from 'react'
import { Container, SelectedSvg } from '../styles'

const scrollShield = () => (
  <Container>
    <svg width="77" height="79" viewBox="0 0 77 79">
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M0 5.4L73.2 5.4 73.2 67.046 72.124 71.28 67.131 71.28 67.131 67.046 6.246 67.046 6.246 70.446 1.394 71.28 0 67.046z" transform="rotate(-180 37.6 38.7)"/>
        <path fill="#FBB034" stroke="#FBB034" strokeWidth="2.4" d="M69.781 4.32H3.42C1.502 4.32 0 5.561 0 7.15v61.3c0 1.589 1.502 2.83 3.419 2.83 1.916 0 3.418-1.241 3.418-2.83v-1.885h59.526v1.886c0 1.588 1.502 2.829 3.418 2.829 1.917 0 3.419-1.241 3.419-2.83V7.15c0-1.589-1.502-2.83-3.419-2.83zM6.033 68.45c0 1.025-1.197 1.887-2.614 1.887-1.417 0-2.615-.862-2.615-1.886V8.973c.623.612 1.55 1.006 2.615 1.006 1.065 0 1.992-.394 2.614-1.006v59.478zM3.419 9.036C2.002 9.035.804 8.173.804 7.15S2.002 5.263 3.42 5.263c1.417 0 2.614.862 2.614 1.886S4.836 9.035 3.419 9.035zM66.363 7.15v58.472H6.837V7.15c0-.733-.333-1.389-.867-1.886h61.26c-.534.497-.868 1.153-.868 1.886h0zm6.033 61.302c0 1.024-1.198 1.886-2.615 1.886-1.417 0-2.614-.862-2.614-1.886V8.973c.622.615 1.55 1.006 2.614 1.006 1.065 0 1.992-.394 2.615-1.006v59.478zM69.78 9.035c-1.417 0-2.614-.862-2.614-1.886s1.197-1.886 2.614-1.886c1.417 0 2.615.862 2.615 1.886s-1.198 1.886-2.615 1.886z" transform="rotate(-180 37.6 38.7)"/>
        <path fill="#FBB034" stroke="#FBB034" strokeWidth="2.4" d="M69.97 0c.595 0 1.077.482 1.077 1.076V8.64h0-2.153V1.076c0-.594.482-1.076 1.077-1.076zM69.97 75.6c-.594 0-1.076-.232-1.076-.518V71.28h2.153v3.802c0 .286-.482.518-1.076.518zM3.23 0c.594 0 1.076.482 1.076 1.076V8.64h0-2.153V1.076C2.153.482 2.635 0 3.229 0zM3.23 75.6c-.595 0-1.077-.232-1.077-.518V71.28h2.153v3.802c0 .286-.482.518-1.077.518z" transform="rotate(-180 37.6 38.7)"/>
      </g>
    </svg>
    <SelectedSvg id="selected" width="77" height="79" viewBox="0 0 77 79">
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M0 5.4L73.2 5.4 73.2 67.046 72.124 71.28 67.131 71.28 67.131 67.046 6.246 67.046 6.246 70.446 1.394 71.28 0 67.046z" transform="rotate(-180 37.6 38.6)"/>
        <path fill="#414141" stroke="#414141" strokeWidth="2.4" d="M69.781 4.32H3.42C1.502 4.32 0 5.561 0 7.15v61.3c0 1.589 1.502 2.83 3.419 2.83 1.916 0 3.418-1.241 3.418-2.83v-1.885h59.526v1.886c0 1.588 1.502 2.829 3.418 2.829 1.917 0 3.419-1.241 3.419-2.83V7.15c0-1.589-1.502-2.83-3.419-2.83zM6.033 68.45c0 1.025-1.197 1.887-2.614 1.887-1.417 0-2.615-.862-2.615-1.886V8.973c.623.612 1.55 1.006 2.615 1.006 1.065 0 1.992-.394 2.614-1.006v59.478zM3.419 9.036C2.002 9.035.804 8.173.804 7.15S2.002 5.263 3.42 5.263c1.417 0 2.614.862 2.614 1.886S4.836 9.035 3.419 9.035zM66.363 7.15v58.472H6.837V7.15c0-.733-.333-1.389-.867-1.886h61.26c-.534.497-.868 1.153-.868 1.886h0zm6.033 61.302c0 1.024-1.198 1.886-2.615 1.886-1.417 0-2.614-.862-2.614-1.886V8.973c.622.615 1.55 1.006 2.614 1.006 1.065 0 1.992-.394 2.615-1.006v59.478zM69.78 9.035c-1.417 0-2.614-.862-2.614-1.886s1.197-1.886 2.614-1.886c1.417 0 2.615.862 2.615 1.886s-1.198 1.886-2.615 1.886z" transform="rotate(-180 37.6 38.6)"/>
        <path fill="#414141" stroke="#414141" strokeWidth="2.4" d="M69.97 0c.595 0 1.077.482 1.077 1.076V8.64h0-2.153V1.076c0-.594.482-1.076 1.077-1.076zM69.97 75.6c-.594 0-1.076-.232-1.076-.518V71.28h2.153v3.802c0 .286-.482.518-1.076.518zM3.23 0c.594 0 1.076.482 1.076 1.076V8.64h0-2.153V1.076C2.153.482 2.635 0 3.229 0zM3.23 75.6c-.595 0-1.077-.232-1.077-.518V71.28h2.153v3.802c0 .286-.482.518-1.077.518z" transform="rotate(-180 37.6 38.6)"/>
      </g>
    </SelectedSvg>
  </Container>
)

export default scrollShield

