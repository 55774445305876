import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'
import { renderToStaticMarkup } from 'react-dom/server'
import { withLocalize } from 'react-localize-redux'
import Header from './components/Header/Header'
import styled from 'styled-components/macro'
import Introduction from './components/Introduction/Introduction'
import CrestBuilder from './components/CrestBuilder/CrestBuilder'
import FinalCrest from './components/FinalCrest/FinalCrest'
import Loader from './components/Loader/Loader'
import ReceiveMail from './components/ReceiveMail/ReceiveMail'
import { en, he } from './locales'
import PrintMode from './components/PrintMode/PrintMode'
import Error from './components/Error/Error'
import device from './lib/devices'
import { setCurrentStep, setIsCrestBuilderHidden } from './store/ui/actions'

const Container = styled.div(({ isCrestBuilderHidden }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: 'calc(var(--vh, 1vh) * 100)',
  [isCrestBuilderHidden && `@media ${device.mobileL}`]: {
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      '& > img': {
        display: 'none',
      },
      '& > div:last-child': {
        marginInlineEnd: '10rem',
      },
    },
  }
}))

const BodyWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  width: '100%',
  overflow: 'auto',
})

const QueryParams = function () {
  var qsObj = {};
  var queryStrings = window.location.search.substring(1);

  if (queryStrings) {
    var qsArray = queryStrings.split('&').map(function (qs) {
      return qs.split('=');
    });
    qsArray.forEach(function (qs) {
      qsObj[qs[0]] = qs[1];
    });
  }

  return qsObj;
}();

function App(props) {
  const {
    isFinalCrestDisplay,
    isLoaderDisplay,
    isReceiveMailDisplay,
    isPrintModeDisplay,
    initialize,
    addTranslationForLanguage,
    isError,
    isCrestBuilderHidden,
    dispatch,
  } = props

  useEffect(() => {
    // Clicking the back button in the browser, returns to the previous page in the app
    window.onpopstate  = (e) => (onBackButtonClick(e))
    let defaultLanguage = _get(QueryParams, 'lng', 'en')
    initialize({
      languages: [
        { name: 'En', code: 'en' },
        { name: 'עב', code: 'he' }
      ],
      options: {
        renderToStaticMarkup,
        defaultLanguage,
      }
    });
    addTranslationForLanguage(en, 'en')
    addTranslationForLanguage(he, 'he')

    if(_get(QueryParams, 'skip_intro', 'false') === 'true') {
      dispatch(setIsCrestBuilderHidden(false))
    }

    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/#whoa-there-one-more-little-detail
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [])

  const onBackButtonClick = (e) => {
    const { state: { currStep } } = e
    e.preventDefault()
    if (currStep === 1) {
      dispatch(setCurrentStep(1))
    } else {
      dispatch(setCurrentStep(currStep - 1))
    }
  }

  function renderBody() {
    if (isCrestBuilderHidden) {
      return <Introduction />
    } 
    if (isFinalCrestDisplay) {
      return <FinalCrest />
    }
    if(isReceiveMailDisplay) {
      return <ReceiveMail />
    }
    // if(isPrintModeDisplay) {
    //   return <PrintMode />
    // }
    return <CrestBuilder />
  }

  return (
    <Container id='app-container'>
      {!isPrintModeDisplay && <Header isCrestBuilderHidden={isCrestBuilderHidden} />}
      <BodyWrapper>
        {renderBody()}
      </BodyWrapper>
      {isLoaderDisplay && <Loader />}
      {isError && <Error />}
    </Container>
  );
}

const mapStateToProps = ({ ui : {
  isFinalCrestDisplay,
  isLoaderDisplay,
  isReceiveMailDisplay,
  isPrintModeDisplay,
  isError,
  isCrestBuilderHidden,
 } }) => ({
   isFinalCrestDisplay,
   isLoaderDisplay,
   isReceiveMailDisplay,
   isPrintModeDisplay,
   isError,
   isCrestBuilderHidden,
  })

export default connect(mapStateToProps)(withLocalize(App))
