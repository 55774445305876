import styled from 'styled-components/macro'

const Svg = styled.svg({
  position: 'absolute',
  fontSize: '16rem',
  textTransform: 'uppercase',
})

export {
  Svg,
}