const types = {
  setIsFinalCrestDisplay: 'SET_IS_FINAL_CREST_DISPLAY',
  setIsLoaderDisplay: 'SET_IS_LOADER_DISPLAY',
  setIsReceiveMailDisplay: 'SET_IS_RECEIVE_MAIL_DISPLAY',
  // setIsPrintModeDisplay: 'SET_IS_PRINT_MODE_DISPLAY',
  setIsCrestBuilderHidden: 'SET_IS_CREST_BUILDER_HIDDEN',
  startPrintMode: 'START_PRINT_MODE',
  setCurrentStep: 'SET_CURRENT_STEP',
  setIsError: 'SET_IS_ERROR',
  setShareFinalCrestImageId: 'SET_SHARE_FINAL_CREST_IMAGE_ID'
}

export default types