import ky from 'ky'
import { setIsLoaderDisplay } from '../store/ui/actions'
import store from '../initializer'

export default ky.extend({
  timeout: 60000,
  hooks: {
    beforeRequest: [() => store.dispatch(setIsLoaderDisplay(true))],
    afterResponse: [() => store.dispatch(setIsLoaderDisplay(false))],
  }
})