import React from 'react'
import { Translate } from 'react-localize-redux'
import {
  Container,
  StartOverText,
} from './StartOverBtn.styles'
import StartOverIcon from '../../assets/startOver'

function StartOverBtn({ onClick }) {
  return (
    <Container onClick={onClick}>
      <StartOverIcon />
      <StartOverText>
        <Translate id="navigation.startOver" />
      </StartOverText>
    </Container>
  )
}

export default StartOverBtn
