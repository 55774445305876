import styled from 'styled-components/macro'

const Btn = styled.button({
    border: '2rem solid #232e83',
    background: 'white',
    padding: '10rem 12rem',
    fontWeight: 'bold',
    color: '#232e83',
    cursor: 'pointer',
    boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.15)',
    outline: 'none',
    margin: '0 4rem',
    '&:hover': {
      background: '#232e83',
      color: 'white',
    }
})

export {
  Btn,
}