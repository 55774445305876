import styled from 'styled-components/macro'
import {
  shieldsId,
  occupationsId,
  attributesId,
  mottosId,
  continentsId,
  featuresId,
} from '../../consts'
import device from '../../lib/devices'

const Container = styled.div(({
  userSelections: {
    selectedShield,
    selectedOccupation,
    selectedAttribute,
    selectedMotto,
    selectedContinent,
    selectedFeatures,
  }}) => ({
  position: 'relative',
  [`@media ${device.tablet}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '300rem',
    width: '100%',
    height: 'auto',
  },
  '& svg': {
    height: 'auto',
    width: '100%',
    minWidth: '300rem',
    direction: 'ltr',
    [`@media ${device.tablet}`]: {
      maxWidth: '280rem',
      minWidth: 'unset',
    },

    [`& #${shieldsId.container} > *,
    & #${occupationsId.container} > *,
    & #${attributesId.container} > *,
    & #${mottosId.container} > *,
    & #${continentsId.container} > *,
    & #${featuresId.containerLeft} > *,
    & #${featuresId.containerRight} > *`]: {
      display: 'none',
    },

    [`& #${shieldsId.container} #${selectedShield}`]: {
      display: 'block'
    }, 

    [`& #${occupationsId.container} #${selectedOccupation}`]: {
      display: 'block'
    },

    [`& #${attributesId.container} #${selectedAttribute}`]: {
      display: 'block'
    },

    [`& #${mottosId.container} #${selectedMotto}`]: {
      display: 'block'
    },

    [`& #${continentsId.container} #${selectedContinent}`]: {
      display: 'block'
    },

    [`& #${featuresId.container} #${featuresId.containerLeft} #${selectedFeatures[0]},
    & #${featuresId.container} #${featuresId.containerRight} #${selectedFeatures[1]}`]: {
      display: 'block'
    },
  }
}))

const Text = styled.div({
  position: 'absolute',
  fontSize: '16rem',
  textAlign: 'center',
  top: '15%',
  left: '30%',
  width: '39%',
  zIndex: 1,
  height: '4%',
})

export {
  Container,
  Text,
}