import styled from 'styled-components/macro'
import device from '../../lib/devices'

const Info = styled.div({
  fontSize: '20rem',
  color: '#232e83',
  margin: '0 20rem',
  cursor: 'pointer',
  '& + div > div': {
      maxWidth: '60%',
      whiteSpace: 'pre-wrap',
    [`@media ${device.mobileL}`]: {
        maxWidth: '80%',
        fontSize: '9rem',
        '& #modal-title': {
          fontSize: '24rem',
        },
        '& #modal-content': {
          margin: '10rem 0',
        },
    }
  }
})

export {
  Info,
}