import React from 'react'
import { Container, SelectedSvg } from '../styles'

const finance = () => (
  <Container>
    <svg width="82" height="75" viewBox="0 0 82 75">
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M16.124 2.252h46.875c3.365 0 6.094 2.729 6.094 6.094v62.602l-52.077-.982c-1.314-.025-2.372-1.087-2.392-2.4l-.938-62.84c-.02-1.346 1.055-2.453 2.401-2.473h.037z" transform="translate(1.4 1)"/>
        <g fill="#47C3D2" stroke="#47C3D2" strokeWidth="1.316">
          <path d="M77.93 57.036H71.3l-.003-48.495c0-4.73-4.144-8.541-9.283-8.541H9.286C4.144 0 0 3.812 0 8.541V23.79c0 .915.662 1.524 1.657 1.524h13.595v39.345c0 4.727 4.144 8.541 9.286 8.541h45.928c5.139 0 9.286-3.812 9.286-8.541V58.56c-.165-.915-.995-1.524-1.822-1.524h0zm-9.948 0H32c-.83 0-1.657.763-1.657 1.524v6.099c0 3.051-2.652 5.49-5.97 5.49-3.317 0-5.969-2.439-5.969-5.49V8.541c0-2.136-.83-3.966-2.154-5.49h45.763c3.317 0 5.969 2.439 5.969 5.49v48.495z" transform="translate(1.4 1)"/>
          <path d="M39.061 29.28L41.957 29.28 41.957 22.112 39.061 22.112zM27.824 29.432L30.719 29.432 30.719 17.843 27.824 17.843zM33.442 29.434L36.338 29.434 36.338 19.978 33.442 19.978zM44.68 29.432L47.575 29.432 47.575 15.707 44.68 15.707zM22.205 29.28L25.1 29.28 25.1 23.181 22.205 23.181zM22.205 36.297L42.467 36.297 42.467 33.703 22.205 33.703zM22.205 41.481L42.467 41.481 42.467 38.888 22.205 38.888zM22.205 46.665L42.467 46.665 42.467 44.072 22.205 44.072zM22.205 51.852L42.467 51.852 42.467 49.258 22.205 49.258z" transform="translate(1.4 1)"/>
          <path d="M24.759 18.909l4.766-4.726 6.471 2.593c.511.152 1.191.152 1.702-.152l8.003-4.88c.853-.458 1.022-1.373.511-2.137-.51-.763-1.705-.915-2.385-.457l-7.15 4.423-6.641-2.593c-.853-.306-1.532-.152-2.043.306l-5.619 5.49c-.68.609-.51 1.524.17 2.136.172.303 1.02.76 2.215-.003h0zM63.579 16.318H53.194c-1.022 0-1.702.61-1.702 1.524 0 .915.68 1.525 1.702 1.525h10.385c1.022 0 1.702-.61 1.702-1.524 0-.915-.68-1.525-1.702-1.525zM63.579 21.503H53.194c-1.022 0-1.702.609-1.702 1.524s.68 1.524 1.702 1.524h10.385c1.022 0 1.702-.61 1.702-1.524 0-.761-.68-1.524-1.702-1.524zM63.579 26.841H53.194c-1.022 0-1.702.609-1.702 1.524s.68 1.524 1.702 1.524h10.385c1.022 0 1.702-.609 1.702-1.524s-.68-1.524-1.702-1.524z" transform="translate(1.4 1)"/>
        </g>
        <path stroke="#47C3D2" strokeWidth="3.656" d="M62.741 34.487H48.306V51.1h14.435V34.487z" transform="translate(1.4 1)"/>
        <path fill="#FFF" d="M35.22 60.662h39.02c.727 0 1.317.59 1.317 1.316v3.001c0 2.384-1.933 4.317-4.317 4.317H35.22c-.727 0-1.316-.59-1.316-1.316v-6.002c0-.727.589-1.316 1.316-1.316zM8.751 4.306c2.693 0 4.877 2.183 4.877 4.876v11.314H3.875V9.182c0-2.693 2.183-4.876 4.876-4.876z" transform="translate(1.4 1)"/>
      </g>
    </svg>
    <SelectedSvg id="selected" width="82" height="75" viewBox="0 0 82 75">
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M18.037 2.252H63c3.365 0 6.094 2.729 6.094 6.094v62.602l-50.198-.98c-1.314-.026-2.37-1.089-2.39-2.402L15.6 4.725c-.02-1.346 1.056-2.453 2.402-2.472h.035z" transform="translate(1 1)"/>
        <g fill="#414141" stroke="#414141" strokeWidth="1.316">
          <path d="M77.93 57.036H71.3l-.003-48.495c0-4.73-4.144-8.541-9.283-8.541H9.286C4.144 0 0 3.812 0 8.541V23.79c0 .915.662 1.524 1.657 1.524h13.595v39.345c0 4.727 4.144 8.541 9.286 8.541h45.928c5.139 0 9.286-3.812 9.286-8.541V58.56c-.165-.915-.995-1.524-1.822-1.524h0zm-9.948 0H32c-.83 0-1.657.763-1.657 1.524v6.099c0 3.051-2.652 5.49-5.97 5.49-3.317 0-5.969-2.439-5.969-5.49V8.541c0-2.136-.83-3.966-2.154-5.49h45.763c3.317 0 5.969 2.439 5.969 5.49v48.495z" transform="translate(1 1)"/>
          <path d="M39.061 29.28L41.957 29.28 41.957 22.112 39.061 22.112zM27.824 29.432L30.719 29.432 30.719 17.843 27.824 17.843zM33.442 29.434L36.338 29.434 36.338 19.978 33.442 19.978zM44.68 29.432L47.575 29.432 47.575 15.707 44.68 15.707zM22.205 29.28L25.1 29.28 25.1 23.181 22.205 23.181zM22.205 36.297L42.467 36.297 42.467 33.703 22.205 33.703zM22.205 41.481L42.467 41.481 42.467 38.888 22.205 38.888zM22.205 46.665L42.467 46.665 42.467 44.072 22.205 44.072zM22.205 51.852L42.467 51.852 42.467 49.258 22.205 49.258z" transform="translate(1 1)"/>
          <path d="M24.759 18.909l4.766-4.726 6.471 2.593c.511.152 1.191.152 1.702-.152l8.003-4.88c.853-.458 1.022-1.373.511-2.137-.51-.763-1.705-.915-2.385-.457l-7.15 4.423-6.641-2.593c-.853-.306-1.532-.152-2.043.306l-5.619 5.49c-.68.609-.51 1.524.17 2.136.172.303 1.02.76 2.215-.003h0zM63.579 16.318H53.194c-1.022 0-1.702.61-1.702 1.524 0 .915.68 1.525 1.702 1.525h10.385c1.022 0 1.702-.61 1.702-1.524 0-.915-.68-1.525-1.702-1.525zM63.579 21.503H53.194c-1.022 0-1.702.609-1.702 1.524s.68 1.524 1.702 1.524h10.385c1.022 0 1.702-.61 1.702-1.524 0-.761-.68-1.524-1.702-1.524zM63.579 26.841H53.194c-1.022 0-1.702.609-1.702 1.524s.68 1.524 1.702 1.524h10.385c1.022 0 1.702-.609 1.702-1.524s-.68-1.524-1.702-1.524z" transform="translate(1 1)"/>
        </g>
        <path stroke="#414141" strokeWidth="3.656" d="M62.741 34.487H48.306V51.1h14.435V34.487z" transform="translate(1 1)"/>
        <path fill="#FFF" d="M35.22 60.662h39.02c.727 0 1.317.59 1.317 1.316v3.001c0 2.384-1.933 4.317-4.317 4.317H35.22c-.727 0-1.316-.59-1.316-1.316v-6.002c0-.727.589-1.316 1.316-1.316zM8.751 4.306c2.693 0 4.877 2.183 4.877 4.876v11.314H3.875V9.182c0-2.693 2.183-4.876 4.876-4.876z" transform="translate(1 1)"/>
      </g>
    </SelectedSvg>
  </Container>
)

export default finance
