import styled from 'styled-components/macro'
import device from '../../lib/devices'

const BtnWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  '& > button': {
    width: '100%',
  },
  [`@media ${device.mobileL}`]: {
    width: '80%',
    flexDirection: 'column',
    '& button': {
      margin: '0 0 10rem 0',
    }
  }
})

export {
  BtnWrapper,
}