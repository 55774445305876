import React from 'react'
import { Container, SelectedSvg } from '../styles'

const scholar = () => (
  <Container>
    <svg width="60" height="76" viewBox="0 0 60 76">
      <g fill="#47C3D2" fillRule="evenodd" stroke="#47C3D2" strokeWidth="1.782">
        <path d="M8.86 13.76c-3.194 0-5.79-2.559-5.79-5.704 0-3.145 2.596-5.703 5.786-5.703l44.67.132c.661 0 1.197-.525 1.197-1.172.003-.65-.53-1.178-1.19-1.181L8.86 0C4.35 0 .68 3.613.68 8.057l.004 48.298c.612 3.937 4.035 6.988 8.179 6.988h3.726V72c0 .141.077.27.198.34.06.037.13.056.198.056.07 0 .137-.019.2-.054l5.025-2.878 5.021 2.878c.125.073.275.073.399 0 .124-.072.198-.198.198-.339v-8.657h33.097c.66 0 1.193-.528 1.193-1.175V14.938c0-.65-.533-1.179-1.193-1.179H8.859zm.796 2.353h7.452c-.143 4.094-3.362 7.428-7.452 7.814v-7.814zm46.075 0v6.865c-3.598-.518-6.444-3.32-6.97-6.865h6.97zM3.069 55.289V13.737c1.305 1.292 3.053 2.15 4.996 2.335v13.266c0 .434.357.786.797.786.44 0 .798-.352.798-.786v-3.843c4.967-.393 8.897-4.427 9.044-9.382h28.455c.546 4.411 4.1 7.912 8.579 8.45v27.983c-4.476.537-8.033 4.038-8.579 8.45H18.63c-.561-4.54-4.313-8.117-8.977-8.488l.003-18.074c0-.433-.357-.785-.797-.785-.44 0-.798.352-.798.785v26.501C5.248 60.55 3.07 58.167 3.07 55.29h0zm6.588-1.216c3.786.358 6.823 3.246 7.366 6.92H9.657v-6.92zm39.107 6.92c.527-3.545 3.372-6.348 6.967-6.863v6.862h-6.967z" transform="translate(.6 1.8)"/>
        <path d="M8.862 4.978H8.86c-.437 0-.794.348-.797.782 0 .434.354.786.794.786l45.804.088h.003c.437 0 .794-.35.797-.783 0-.433-.354-.785-.794-.785L8.862 4.978zM8.86 9.565c-.441 0-.795.352-.798.782 0 .434.354.786.794.786l46.936.044c.44 0 .795-.352.798-.782 0-.434-.354-.786-.795-.786L8.86 9.565zM24.43 45.119c.363-.242.459-.73.213-1.088-1.11-1.621-1.697-3.516-1.697-5.48 0-5.404 4.463-9.8 9.95-9.8 1.493 0 2.929.317 4.266.942.395.186.87.022 1.062-.37.188-.39.02-.858-.376-1.047-1.55-.726-3.216-1.093-4.948-1.093-6.364 0-11.542 5.1-11.542 11.368 0 2.278.683 4.478 1.971 6.357.147.226.4.345.654.345.206-.029.355-.074.447-.134zM32.893 48.355c-1.11 0-2.198-.18-3.238-.531-.418-.142-.868.078-1.011.487-.144.408.08.854.494.996 1.206.408 2.47.616 3.755.616 6.364 0 11.542-5.1 11.542-11.369 0-2.712-.986-5.335-2.772-7.39-.287-.33-.788-.368-1.123-.085-.335.283-.373.776-.086 1.106 1.54 1.772 2.39 4.034 2.39 6.372 0 5.398-4.464 9.798-9.951 9.798h0z" transform="translate(.6 1.8)"/>
      </g>
    </svg>
    <SelectedSvg id="selected" width="60" height="77" viewBox="0 0 60 77">
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M7.672 0h46.352l3.283 15.548-.685 45.723H6.573c-2.427 0-4.395-1.968-4.395-4.396V5.495C2.178 2.46 4.638 0 7.672 0z" transform="translate(.6 1.6)"/>
        <path fill="#414141" stroke="#414141" strokeWidth="1.813" d="M8.86 13.985c-3.194 0-5.79-2.6-5.79-5.797 0-3.197 2.596-5.796 5.786-5.796l44.67.134c.661 0 1.197-.533 1.197-1.191.003-.661-.53-1.198-1.19-1.201L8.86 0C4.35 0 .68 3.672.68 8.189l.004 49.09c.612 4.001 4.035 7.102 8.179 7.102h3.726v8.8c0 .143.077.274.198.344.06.038.13.058.198.058.07 0 .137-.02.2-.055l5.025-2.925 5.021 2.925c.125.074.275.074.399 0 .124-.073.198-.2.198-.345v-8.798h33.097c.66 0 1.193-.537 1.193-1.195V15.183c0-.662-.533-1.198-1.193-1.198H8.859zm.796 2.392h7.452c-.143 4.161-3.362 7.55-7.452 7.942v-7.942zm46.075 0v6.978c-3.598-.527-6.444-3.376-6.97-6.978h6.97zM3.069 56.196V13.963c1.305 1.312 3.053 2.184 4.996 2.372V29.82c0 .44.357.798.797.798.44 0 .798-.357.798-.798v-3.906c4.967-.4 8.897-4.5 9.044-9.536h28.455c.546 4.484 4.1 8.041 8.579 8.588v28.443c-4.476.546-8.033 4.103-8.579 8.587H18.63c-.561-4.614-4.313-8.249-8.977-8.626L9.656 35c0-.44-.357-.799-.797-.799-.44 0-.798.358-.798.799v26.935c-2.813-.393-4.992-2.813-4.992-5.739h0zm6.588-1.236c3.786.364 6.823 3.299 7.366 7.032H9.657V54.96zm39.107 7.032c.527-3.602 3.372-6.451 6.967-6.975v6.975h-6.967z" transform="translate(.6 1.6)"/>
        <path fill="#414141" stroke="#414141" strokeWidth="1.813" d="M8.862 5.06H8.86c-.437 0-.794.354-.797.794 0 .441.354.799.794.799l45.804.09h.003c.437 0 .794-.355.797-.796 0-.44-.354-.798-.794-.798l-45.804-.09zM8.86 9.722c-.441 0-.795.358-.798.795 0 .44.354.799.794.799l46.936.044c.44 0 .795-.357.798-.795 0-.44-.354-.798-.795-.798L8.86 9.722zM24.43 45.858c.363-.246.459-.74.213-1.105-1.11-1.648-1.697-3.574-1.697-5.57 0-5.493 4.463-9.961 9.95-9.961 1.493 0 2.929.323 4.266.958.395.189.87.022 1.062-.377.188-.396.02-.872-.376-1.063-1.55-.738-3.216-1.112-4.948-1.112-6.364 0-11.542 5.184-11.542 11.555 0 2.316.683 4.551 1.971 6.461.147.23.4.351.654.351.206-.03.355-.075.447-.137zM32.893 49.147c-1.11 0-2.198-.182-3.238-.54-.418-.143-.868.08-1.011.496-.144.415.08.868.494 1.012 1.206.415 2.47.626 3.755.626 6.364 0 11.542-5.183 11.542-11.555 0-2.756-.986-5.423-2.772-7.511-.287-.336-.788-.374-1.123-.087-.335.288-.373.79-.086 1.125 1.54 1.8 2.39 4.1 2.39 6.476 0 5.487-4.464 9.958-9.951 9.958h0z" transform="translate(.6 1.6)"/>
      </g>
    </SelectedSvg>
  </Container>
)

export default scholar
