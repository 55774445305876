import React from 'react'
import { Container, SelectedSvg } from '../styles'

const africa = () => (
  <Container>
    <svg width="79" height="83" viewBox="0 0 79 83">
      <g fill="none" fillRule="evenodd" stroke="#FBB034" strokeWidth="3.6">
        <path d="M75.155 28.128c-.277-.637-.922-1.003-1.66-1.003h-.367l-5.071 1.095h-.094c-.644 0-1.015 0-1.199-.092-1.016-1.003-1.567-1.458-1.937-1.64-.094 0-.094-.092-.094-.092L55.88 10.284c-.461-2.368-1.66-3.549-3.872-3.549h-.738c-.367 0-1.477-.363-2.305-.729-1.66-.636-3.781-1.365-5.625-1.365-1.383 0-2.399.362-3.227 1.184-.645.636-1.477.91-2.672.91-2.95 0-6.087-1.913-6.915-3.641C29.612 1.092 27.397 0 24.264 0c-5.348 0-13.923 3.279-19.36 9.377-.184.274-.462.456-.645.73C2.23 12.381.016 14.75.016 18.391c-.184 6.828 1.2 11.56 4.243 14.658 1.289 1.273 2.765 2.184 4.516 2.824 1.75.636 3.871.91 6.27.91.922 0 1.937 0 3.043-.092h.277c2.211 0 6.087 1.365 7.008 2.457 0 .181-.093.729-.183 1.003-.184.91-.461 2.183-.094 3.549.645 2.094 2.121 3.278 3.504 4.37 2.122 1.64 3.782 3.005 2.583 7.283-1.75 6.735 5.809 18.57 7.375 20.938.828 1.273 2.211 2.002 3.872 2.002 5.809 0 15.395-8.74 15.395-15.657 0-1.184.555-1.728 1.844-2.824 1.844-1.639 4.516-3.915 2.95-10.013-1.383-5.555.554-7.01 3.687-9.285 2.399-1.728 5.349-3.823 6.821-8.286.094-.181.645-.818.922-1.091.829-1.003 1.567-1.914 1.106-3.009v-.001zM70.834 53.71c-.46 0-.828.182-1.105.456-.094.092-.278.273-.368.455-.46.547-1.199 1.547-2.672 2.183-1.105.455-1.844.818-2.121 1.64-.277.817.094 1.457.277 1.82.184.274.278.637.461 1.184 0 .182.094.274.094.363l-.094.092c-.46.363-1.199 1.003-1.566 2.276-.278.91-.094 1.913.554 2.731.922 1.273 2.766 1.821 4.055 1.821.829 0 1.477-.181 1.844-.636.278-.274 1.016-1.003 2.95-8.83 0-.093.093-.274.093-.363 0-.636-.093-1.64-.183-2.457-.375-1.003-.649-2.735-2.219-2.735h0z" transform="translate(1.8 2.4)"/>
      </g>
    </svg>
    <SelectedSvg id="selected" width="80" height="83" viewBox="0 0 80 83">
      <g fill="#FFF" fillRule="evenodd" stroke="#414141" strokeWidth="3.6">
        <path d="M75.155 28.128c-.277-.637-.922-1.003-1.66-1.003h-.367l-5.071 1.095h-.094c-.644 0-1.015 0-1.199-.092-1.016-1.003-1.567-1.458-1.937-1.64-.094 0-.094-.092-.094-.092L55.88 10.284c-.461-2.368-1.66-3.549-3.872-3.549h-.738c-.367 0-1.477-.363-2.305-.729-1.66-.636-3.781-1.365-5.625-1.365-1.383 0-2.399.362-3.227 1.184-.645.636-1.477.91-2.672.91-2.95 0-6.087-1.913-6.915-3.641C29.612 1.092 27.397 0 24.264 0c-5.348 0-13.923 3.279-19.36 9.377-.184.274-.462.456-.645.73C2.23 12.381.016 14.75.016 18.391c-.184 6.828 1.2 11.56 4.243 14.658 1.289 1.273 2.765 2.184 4.516 2.824 1.75.636 3.871.91 6.27.91.922 0 1.937 0 3.043-.092h.277c2.211 0 6.087 1.365 7.008 2.457 0 .181-.093.729-.183 1.003-.184.91-.461 2.183-.094 3.549.645 2.094 2.121 3.278 3.504 4.37 2.122 1.64 3.782 3.005 2.583 7.283-1.75 6.735 5.809 18.57 7.375 20.938.828 1.273 2.211 2.002 3.872 2.002 5.809 0 15.395-8.74 15.395-15.657 0-1.184.555-1.728 1.844-2.824 1.844-1.639 4.516-3.915 2.95-10.013-1.383-5.555.554-7.01 3.687-9.285 2.399-1.728 5.349-3.823 6.821-8.286.094-.181.645-.818.922-1.091.829-1.003 1.567-1.914 1.106-3.009v-.001zM70.834 53.71c-.46 0-.828.182-1.105.456-.094.092-.278.273-.368.455-.46.547-1.199 1.547-2.672 2.183-1.105.455-1.844.818-2.121 1.64-.277.817.094 1.457.277 1.82.184.274.278.637.461 1.184 0 .182.094.274.094.363l-.094.092c-.46.363-1.199 1.003-1.566 2.276-.278.91-.094 1.913.554 2.731.922 1.273 2.766 1.821 4.055 1.821.829 0 1.477-.181 1.844-.636.278-.274 1.016-1.003 2.95-8.83 0-.093.093-.274.093-.363 0-.636-.093-1.64-.183-2.457-.375-1.003-.649-2.735-2.219-2.735h0z" transform="translate(2.4 2.4)"/>
      </g>
    </SelectedSvg>
  </Container>
)

export default africa
