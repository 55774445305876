import styled from 'styled-components/macro'
import device from '../../lib/devices'

const Container = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: '20px',
    flexGrow: 1,
    '& > div > div:last-child': {
      width: '100%',
      [`@media ${device.mobileL}`]: {
        width: '80%',
      }
    }
})

const MailPng = styled.img({
  width: '100%',
  maxWidth: '100rem',
  margin: '10rem 0 15rem 0',
})

export {
  Container,
  MailPng,
}