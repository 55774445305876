import React from 'react'

const ModalDecoRight = () => (
  <svg width="23" height="46" viewBox="0 0 23 46" id='modal-deco-right'>
    <g fill="none" fillRule="evenodd">
      <path fill="#D98376" d="M0.813 42.85L6.25 39.813 6.813 44.51 4.421 45.813z" transform="rotate(-71 3.813 42.813)"/>
      <path fill="#AB3226" d="M18.096 0L23 13.323 15.423 20 11 12.402z"/>
      <path fill="#F0A93F" d="M6 35.591L21.404 28 23 39.743 16.222 43z"/>
      <path fill="#4EBBC3" d="M11 30L8.875 18 0 19.576 1.835 27.385z"/>
    </g>
  </svg>
)

export default ModalDecoRight
