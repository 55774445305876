import React from 'react'
import { Container, SelectedSvg } from '../styles'

const date = () => (
  <Container>
    <svg width="42" height="51" viewBox="0 0 42 51">
        <g fill="none" fillRule="evenodd" stroke="#FBB034" strokeLinecap="round" strokeWidth="1.4">
        <path d="M41.65 17.306c-13.003.645-22.552 4.843-28.645 12.592" transform="rotate(-25 12.63 23.598)"/>
        <g transform="rotate(-25 12.63 23.598) rotate(126 15.192 15.8)">
            <path fill="#FFF" d="M4.89 20.288c2.702 0 4.892-4.542 4.892-10.144S8.846 0 4.89 0C.935 0 0 4.542 0 10.144s2.19 10.144 4.89 10.144z"/>
            <path d="M2.671 4.897c-.767 2.798-.767 5.597 0 8.395"/>
            <ellipse cx="4.891" cy="1.049" fill="#FFF" rx="1.397" ry="1.049"/>
        </g>
        <g transform="rotate(-25 12.63 23.598) rotate(102 5.71 17.528)">
            <path fill="#FFF" d="M4.89 20.288c2.702 0 4.892-4.542 4.892-10.144S8.846 0 4.89 0C.935 0 0 4.542 0 10.144s2.19 10.144 4.89 10.144z"/>
            <path d="M2.671 4.897c-.767 2.798-.767 5.597 0 8.395"/>
            <ellipse cx="4.891" cy="1.049" fill="#FFF" rx="1.397" ry="1.049"/>
        </g>
        <g transform="rotate(-25 12.63 23.598) rotate(22 -70.028 35.965)">
            <path fill="#FFF" d="M4.89 20.288c2.702 0 4.892-4.542 4.892-10.144S8.846 0 4.89 0C.935 0 0 4.542 0 10.144s2.19 10.144 4.89 10.144z"/>
            <path d="M2.671 4.897c-.767 2.798-.767 5.597 0 8.395"/>
            <ellipse cx="4.891" cy="1.049" fill="#FFF" rx="1.397" ry="1.049"/>
        </g>
        <g transform="rotate(-25 12.63 23.598) rotate(16 -67.584 83.293)">
            <path fill="#FFF" d="M4.89 20.288c2.702 0 4.892-4.542 4.892-10.144S8.846 0 4.89 0C.935 0 0 4.542 0 10.144s2.19 10.144 4.89 10.144z"/>
            <path d="M2.671 4.897c-.767 2.798-.767 5.597 0 8.395"/>
            <ellipse cx="4.891" cy="1.049" fill="#FFF" rx="1.397" ry="1.049"/>
        </g>
        <g transform="rotate(-25 12.63 23.598) rotate(5 -194.96 358.372)">
            <path fill="#FFF" d="M4.89 20.288c2.702 0 4.892-4.542 4.892-10.144S8.846 0 4.89 0C.935 0 0 4.542 0 10.144s2.19 10.144 4.89 10.144z"/>
            <path d="M2.671 4.897c-.767 2.798-.767 5.597 0 8.395"/>
            <ellipse cx="4.891" cy="1.049" fill="#FFF" rx="1.397" ry="1.049"/>
        </g>
        </g>
    </svg>
    <SelectedSvg id="selected" width="42" height="51" viewBox="0 0 42 51">
      <g fill="none" fillRule="evenodd" stroke="#414141" strokeLinecap="round" strokeWidth="1.4">
        <path d="M41.65 17.306c-13.003.645-22.552 4.843-28.645 12.592" transform="rotate(-25 12.63 23.598)"/>
        <g transform="rotate(-25 12.63 23.598) rotate(126 15.192 15.8)">
          <path fill="#FFF" d="M4.89 20.288c2.702 0 4.892-4.542 4.892-10.144S8.846 0 4.89 0C.935 0 0 4.542 0 10.144s2.19 10.144 4.89 10.144z"/>
          <path d="M2.671 4.897c-.767 2.798-.767 5.597 0 8.395"/>
          <ellipse cx="4.891" cy="1.049" fill="#FFF" rx="1.397" ry="1.049"/>
        </g>
        <g transform="rotate(-25 12.63 23.598) rotate(102 5.71 17.528)">
          <path fill="#FFF" d="M4.89 20.288c2.702 0 4.892-4.542 4.892-10.144S8.846 0 4.89 0C.935 0 0 4.542 0 10.144s2.19 10.144 4.89 10.144z"/>
          <path d="M2.671 4.897c-.767 2.798-.767 5.597 0 8.395"/>
          <ellipse cx="4.891" cy="1.049" fill="#FFF" rx="1.397" ry="1.049"/>
        </g>
        <g transform="rotate(-25 12.63 23.598) rotate(22 -70.028 35.965)">
          <path fill="#FFF" d="M4.89 20.288c2.702 0 4.892-4.542 4.892-10.144S8.846 0 4.89 0C.935 0 0 4.542 0 10.144s2.19 10.144 4.89 10.144z"/>
          <path d="M2.671 4.897c-.767 2.798-.767 5.597 0 8.395"/>
          <ellipse cx="4.891" cy="1.049" fill="#FFF" rx="1.397" ry="1.049"/>
        </g>
        <g transform="rotate(-25 12.63 23.598) rotate(16 -67.584 83.293)">
          <path fill="#FFF" d="M4.89 20.288c2.702 0 4.892-4.542 4.892-10.144S8.846 0 4.89 0C.935 0 0 4.542 0 10.144s2.19 10.144 4.89 10.144z"/>
          <path d="M2.671 4.897c-.767 2.798-.767 5.597 0 8.395"/>
          <ellipse cx="4.891" cy="1.049" fill="#FFF" rx="1.397" ry="1.049"/>
        </g>
        <g transform="rotate(-25 12.63 23.598) rotate(5 -194.96 358.372)">
          <path fill="#FFF" d="M4.89 20.288c2.702 0 4.892-4.542 4.892-10.144S8.846 0 4.89 0C.935 0 0 4.542 0 10.144s2.19 10.144 4.89 10.144z"/>
          <path d="M2.671 4.897c-.767 2.798-.767 5.597 0 8.395"/>
          <ellipse cx="4.891" cy="1.049" fill="#FFF" rx="1.397" ry="1.049"/>
        </g>
      </g>
    </SelectedSvg>
  </Container>
)

export default date
