import styled from 'styled-components/macro'
import device from '../../lib/devices'

const Container = styled.div(({ direction }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexGrow: 1,
  maxWidth: '75%',
  direction: direction,
  [`@media ${device.tablet}`]: {
    maxWidth: '100%',
    width: '100%',
  }
}))

const Content = styled.div({
  display: 'flex',
  width: '100%',
  flexGrow: 1,
  height: '100%',
  positoin: 'relative',
  [`@media ${device.tablet}`]: {
    height: 'unset',
    paddingBottom: '38rem',
  }
})

const MobileWrapper = styled.div({
  display: 'flex',
  [`@media ${device.tablet}`]: {
    flexDirection: 'column-reverse',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 1,
    paddingInlineEnd: '15rem',
  }
})

export {
  Container,
  Content,
  MobileWrapper,
}