import styled from 'styled-components/macro'
import device from '../../lib/devices'

const Container = styled.div({
    display: 'flex',
    flexDirection: 'column',
    color: '#242424',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '70rem',
    userSelect: 'none',
    width: '32rem',
    flexShrink: 0,
    [`@media ${device.tablet}`]: {
      marginInlineStart: '20rem',
    },
    [`@media ${device.mobileL}`]: {
      marginInlineStart: '5rem',
    }
})

const Step = styled.button(({ isChosen, disabled }) => ({
  opacity: isChosen ? 1 : 0.3,
  textAlign: 'center',
  color: isChosen ? '#414141' : '',
  fontSize: isChosen ? '30rem' : '',
  border: 'none',
  background: 'transparent',
  outline: 'none',
  position: 'relative',
  margin: isChosen ? 0 : '10rem 0',
  cursor: 'pointer',
  pointerEvents: disabled ? 'none' : 'auto',
  padding: 0,
  width: '100%',
  '&:after': {
    content: isChosen ? '""' : '',
    position: 'absolute',
    bottom: -3,
    zIndex: 1,
    width: '50%',
    background: '#fbb034',
    height: '3rem',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
}))

export {
  Container,
  Step,
}