import styled from 'styled-components/macro'
import device from '../../lib/devices'

const Container = styled.div(({ isMailBtnChosen }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: '20rem 5rem 0 5rem',
  flexGrow: 1,
  [isMailBtnChosen && '& input']: {
    marginInlineStart: 0,
    width: '80%',
  },
  [isMailBtnChosen && '& button']: {
    maxWidth: '250rem',
    marginBottom: '20rem',
  },
  [isMailBtnChosen && `@media ${device.tablet}`]: {
    padding: '0 10rem',
    '& > div > input': {
      boxSizing: 'border-box',
    }
  }
}))

const InnerContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '450rem',
  width: '100%',
  alignItems: 'center',
  '& > svg': {
    width: '100%',
    maxWidth: '300rem',
  }
})

const Image = styled.img({
  width: '100%',
})

const ImageWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
})

const Content = styled.div(({ direction }) => ({
  lineHeight: 1.4,
  textAlign: 'center',
  color: '#5a5959',
  marginBottom: '20rem',
  fontSize: '16rem',
  fontFamily: 'Helvetica',
  whiteSpace: 'pre-wrap',
  direction: direction,
}))

const BtnWrapper = styled.div(({ isMailBtnChosen }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  flexShrink: 0,
  [isMailBtnChosen && `@media ${device.tablet}`]: {
    width: '80%',
    '& > button:first-child': {
      width: '100%',
      maxWidth: '100%',
      margin: 0,
    }
  },
  [`@media ${device.mobileL}`]: {
    flexDirection: 'column',
    alignItems: 'center',
    '& button': {
      width: '80%',
      margin: '0 0 10rem 0',
    }
  }
}))

const CongratPng = styled.img(({ lang }) => ({
  width: '100%',
  maxWidth: lang === 'en' ? '350rem' : '200rem',
  margin: '10rem 0 15rem 0',
}))

const SocialMedia = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20rem 0',
  flexShrink: 0,
  '& > button:not(:last-child)': {
    marginRight: '10rem',
  },
})

const ShareBtnWrapper = styled.div({
  position: 'relative',
  marginInlineEnd: '10rem',
  '&:hover': {
    '& > #share-tooltip': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
})

const ShareTooltip = styled.div(({ direction }) => ({
  position: 'absolute',
  display: 'none',
  top: -45,
  left: -25,
  background: '#232e83',
  color: 'white',
  fontSize: '12rem',
  padding: '7rem 10rem',
  width: '80rem',
  borderRadius: '4rem',
  fontWeight: 'bold',
  direction: direction || 'ltr',
  '&:after': {
    content: '""',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '10px solid #232e83',
    position: 'absolute',
    bottom: -10,
    left: '40%',
  },
}))

export {
  Container,
  Image,
  Content,
  InnerContainer,
  BtnWrapper,
  ImageWrapper,
  CongratPng,
  SocialMedia,
  ShareBtnWrapper,
  ShareTooltip,
}