import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Svg } from './SvgText.styles'
import CrestPic from '../../assets/crest'

const BANNER_X_START = 180
const BANNER_WIDTH = 220

const SvgText = ({ selectedName, activeLanguage }) => { 
    const [xValue, setXValue] = useState(0)
    const textEl = useRef(null)
    const viewBox = CrestPic.match(new RegExp('viewBox="([^"]*)"'))[1]

    useEffect(() => {
      setXValue(BANNER_X_START + (BANNER_WIDTH / 2) - (textEl.current.getBBox().width / 2))
    }, [selectedName])

    return (
      <Svg viewBox={viewBox}>
        <text id="crestName" x={xValue} y="185" fill="#414141" fontSize="25px" ref={textEl} style={{fontFamily: `'Open Sans', sans-serif`}}>
          {selectedName.toUpperCase()}
        </text>
      </Svg>
    )
}

const mapStateToProps = ({ userSelections: { selectedName } }) => ({ selectedName })

export default connect(mapStateToProps)(SvgText)
